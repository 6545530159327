import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Button, IconButton, Box, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';
import Swal from 'sweetalert2';
import CreateWarhouseModal from './CreateWarhouseModal';

const AllWarhouseOwner = ({tab}) => {
    const { WarhouseOwnner,fetchWarhouseOwnnerList} = useAuth(); 

    const [uomModalOpen, setUomModalOpen] = useState(false);
    const [selectedUom, setSelectedUom] = useState(null); 

    useEffect(() => {
      if(tab == 'WarehouseOwner'){
        fetchWarhouseOwnnerList();
      }
      }, [tab]);


      const handleDelete = async (id) => {
        try {
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          if (result.isConfirmed) {
            await DataService.deleteWarhouseOwnner(id); // API call to delete the UOM
            fetchWarhouseOwnnerList(); // Refresh the UOM list after deletion
          }
        } catch (error) {
          console.error('Failed to delete WarhouseOwnner', error);
        }
      };
    
      const handleEdit = (uom) => {
        setSelectedUom(uom);
        setUomModalOpen(true);
      };
    
      const handleAddUomClick = () => {
        setSelectedUom(null); // Clear the selected UOM for a fresh create modal
        setUomModalOpen(true);
      };
    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Warhouse Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                <IconButton aria-label="edit" onClick={() => handleEdit(row)}>
                        <Edit style={{ color: 'green' }} />
                    </IconButton>
                    <IconButton aria-label="delete"
                        onClick={() => handleDelete(row.id)}
                    >
                        <Delete style={{ color: '#C81D20' }} />
                    </IconButton>
                </>
            ),
        },
    ];
  return (
    <>
    <div className="px-4">
                <Box
                    className="mb-2"
                    p={2}
                    borderRadius={2}
                    border="1px solid #e0e0e0"
                    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                    width="100%"
                    height="fit-content"
                    bgcolor="white"
                >
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl">Warehouse Owner</h1>
                        <Button variant="contained" color="primary" 
                        onClick={handleAddUomClick}
                        >
                            Create
                        </Button>
                    </div>
                </Box>
                <div className="bg-white p-6 shadow rounded-lg">
                    {/* <h2 className="text-xl mb-2">Warhouse Impact Directory</h2>
                    <div className="flex mb-2">
                        <TextField label="Search or browse MSDS"
                            variant="outlined" fullWidth />
                    </div> */}
                    <div style={{ height: "200px", overflow: "auto" }}>
                        <DataTable
                            columns={columns}
                            data={WarhouseOwnner}
                            pagination
                            responsive
                            striped
                            highlightOnHover
                            pointerOnHover
                            noHeader
                        />
                    </div>
                </div>
            </div>

            <CreateWarhouseModal
        isOpen={uomModalOpen}
        toggle={() => setUomModalOpen(!uomModalOpen)}
        fetchgetUomList={fetchWarhouseOwnnerList}
        selectedUom={selectedUom} // Pass selected UOM for editing
      />
    </>
  )
}

export default AllWarhouseOwner