import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton, Select, MenuItem,TextField } from '@mui/material';
import QRCode from 'react-qr-code'; 
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { useAuth } from '../../contexts/AuthConext';

const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};


const SupplierChainEntity = ({ supplyChainEntity ,productData}) => {
  const qrRef = useRef();

  
  const {saasId
  } = useAuth(); 


  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };

  const [products, setProducts] = useState([
    { name: "Product Name", quantity: 5 },
    { name: "Product Name", quantity: 10 },
    { name: "Product Name", quantity: 14 },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEditProduct = (index) => {
    // Implement your edit logic here.
    console.log("Edit product at index:", index);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


// download pdf 
const cardRef = useRef(); // Add a reference for the card to download as PDF
const downloadPDF = () => {  
  const input = cardRef.current;  

  // Ensure the image is fully loaded before capturing the canvas  
  const images = input.querySelectorAll('img');  
  const imagePromises = Array.from(images).map((img) => {  
    return new Promise((resolve) => {  
      if (img.complete) {  
        resolve();  
      } else {  
        img.onload = resolve;  
        img.onerror = resolve;  
      }  
    });  
  });  

  Promise.all(imagePromises).then(() => {  
    // After all images are loaded, render the canvas and generate the PDF  
    html2canvas(input, {  
      useCORS: true, // Enable CORS to allow cross-origin images  
      allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
    })  
      .then((canvas) => {  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF();  
        const imgProps = pdf.getImageProperties(imgData);  
        const pdfWidth = pdf.internal.pageSize.getWidth();  
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
        pdf.save('retailer-details.pdf');  
      })  
      .catch((error) => {  
        console.error('Failed to generate PDF:', error);  
      });  
  });  
};








// update api 

 // Modal state for edit functionality
 const [modalOpen, setModalOpen] = useState(false);
 const [selectedOption, setSelectedOption] = useState('');

  const handleEditClick = () => {
    setModalOpen(true); // Open the modal on edit button click
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };



 

  const [formData, setFormData] = useState({
    supplier_name: "supplier 1",
    location: "",
    progress_bar: "90",
    transport_method: [],
    transport_rout: [],
    transport_rout_start: "",
    transport_rout_end: "",
    warehouse_name: "",
    warehouse_license_no: "",
    warehouse_manager: "",
    warehouse_gst_no: "",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: ""
    },
    phone: "",
    phone2: "",
    email: "",
    email2: ""
  });
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Handle nested address fields
    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [addressField]: value,
        },
      }));
    } else {
      // Handle other fields
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleArrayInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.split(",") // Assuming user inputs comma-separated values
    });
  };
    

  const handleupdateSMSave = async () => {
    try {
  
        const response = await DataService.updatelogisticklink(productData?.id,  formData); // Use selectedOption for retailerId
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Logistics updated successfully!',
          });
          handleCloseModal();
        }
   
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };
  
  return (
    <>
    <Container maxWidth="lg" className="scrollable-container" style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} ref={cardRef}>
          <Card className="p-10">
            <Grid container spacing={2}>
        
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography className='p-2' variant="body2" color="textSecondary">
                Warehouse mobile no.
                </Typography>
                <Typography className='p-2' variant="body1">
                  (+91) {supplyChainEntity?.contactInfo?.phone} (Primary)
                </Typography>
                <Typography className='p-2' variant="body1">
                  (+91) {supplyChainEntity?.contactInfo?.phone2} (Secondary)
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography className='p-2' variant="body2" color="textSecondary">
                Warehouse E-mail
                </Typography>
                <Typography className='p-2' variant="body1">
                  {supplyChainEntity?.contactInfo?.email} (Primary)
                </Typography>
                <Typography className='p-2' variant="body1">
                  {supplyChainEntity?.contactInfo?.email1} (Secondary)
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12}>
                <Typography variant="body2" color="textSecondary">
                Warehouse Address
                </Typography>
                <Typography variant="body1">
                  {supplyChainEntity?.address?.flatNo}, {supplyChainEntity?.address?.cityArea}, 
                  {supplyChainEntity?.address?.district}, {supplyChainEntity?.address?.state}, 
                  {supplyChainEntity?.address?.country}, {supplyChainEntity?.address?.pincode}
                </Typography>
              </Grid>
              {/* Transport Details */}
              <Grid className='p-4' item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Transport Details
                </Typography>
                <Typography variant="body2">Transport Method: {supplyChainEntity?.transportDetails?.transportMethod}</Typography>
                <Typography variant="body2">Transport Route: {supplyChainEntity?.transportDetails?.transportRout}</Typography>
                <Typography variant="body2">Route Start: {supplyChainEntity?.transportDetails?.transportRoutStart}</Typography>
                <Typography variant="body2">Route End: {supplyChainEntity?.transportDetails?.transportRoutEnd}</Typography>
              </Grid>
              {/* Warehouse Details */}
              <Grid className='p-4' item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Warehouse Details
                </Typography>
                <Typography variant="body2">Warehouse Name: {supplyChainEntity?.warehouseDetails?.warehouseName}</Typography>
                <Typography variant="body2">License No: {supplyChainEntity?.warehouseDetails?.warehouseLicensNo}</Typography>
                <Typography variant="body2">Manager: {supplyChainEntity?.warehouseDetails?.warehouseManager}</Typography>
                <Typography variant="body2">GST No: {supplyChainEntity?.warehouseDetails?.warehouseGstNo}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="mb-1">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditClick} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center" marginTop={2}>
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={downloadPDF} aria-label="download">
                    <DownloadIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className="p-3 mt-2">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value="https://example.com" style={{ height: "100px" }} />
            </div>
            <div className="flex justify-center ">
              <Button variant="contained" color="primary" 
                className="h-[35px] w-[235px]"
                style={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '12px',
                  background: '#ef613d',
                  border: 'none',
                  borderRadius: '20px',
                  '&:hover': { background: '#ef613d' },
                  width: 235,
                  height: 35,
                }}
                onClick={downloadQRCode}
              >
                Download QRCode Button
              </Button>
            </div>
            <div className="flex justify-center ">
              <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>

             {/* Modal for editing */}
             <Modal isOpen={modalOpen} toggle={handleCloseModal}>
  <ModalHeader toggle={handleCloseModal}>Edit Retailer Details</ModalHeader>
  <ModalBody>
  {/* Supplier Name */}
  {/* <Box mt={2}>
    <TextField
      fullWidth
      name="supplier_name"
      value={formData.supplier_name}
      onChange={handleInputChange}
      label="Supplier Name"
      variant="outlined"
    />
  </Box> */}

  {/* Location */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="location"
      value={formData.location}
      onChange={handleInputChange}
      label="Location"
      variant="outlined"
    />
  </Box>




  {/* Transport Method */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="transport_method"
      value={formData.transport_method.join(",")}
      onChange={handleArrayInputChange}
      label="Transport Method (comma separated)"
      variant="outlined"
    />
  </Box>

  {/* Transport Route */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="transport_rout"
      value={formData.transport_rout.join(",")}
      onChange={handleArrayInputChange}
      label="Transport Route (comma separated)"
      variant="outlined"
    />
  </Box>

  {/* Transport Route Start */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="transport_rout_start"
      value={formData.transport_rout_start}
      onChange={handleInputChange}
      label="Transport Route Start"
      type="datetime-local"
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  </Box>

  {/* Transport Route End */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="transport_rout_end"
      value={formData.transport_rout_end}
      onChange={handleInputChange}
      label="Transport Route End"
      type="datetime-local"
      InputLabelProps={{ shrink: true }}
      variant="outlined"
    />
  </Box>

  {/* Warehouse Name */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="warehouse_name"
      value={formData.warehouse_name}
      onChange={handleInputChange}
      label="Warehouse Name"
      variant="outlined"
    />
  </Box>

  {/* Warehouse License No */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="warehouse_license_no"
      value={formData.warehouse_license_no}
      onChange={handleInputChange}
      label="Warehouse License No"
      variant="outlined"
    />
  </Box>

  {/* Warehouse Manager */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="warehouse_manager"
      value={formData.warehouse_manager}
      onChange={handleInputChange}
      label="Warehouse Manager"
      variant="outlined"
    />
  </Box>

  {/* Warehouse GST No */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="warehouse_gst_no"
      value={formData.warehouse_gst_no}
      onChange={handleInputChange}
      label="Warehouse GST No"
      variant="outlined"
    />
  </Box>

  {/* Address - Flat No */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.flatNo"
      value={formData.address.flatNo}
      onChange={handleInputChange}
      label="Flat No"
      variant="outlined"
    />
  </Box>

  {/* Address - City Area */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.cityArea"
      value={formData.address.cityArea}
      onChange={handleInputChange}
      label="City Area"
      variant="outlined"
    />
  </Box>

  {/* Address - Country */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.country"
      value={formData.address.country}
      onChange={handleInputChange}
      label="Country"
      variant="outlined"
    />
  </Box>

  {/* Address - State */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.state"
      value={formData.address.state}
      onChange={handleInputChange}
      label="State"
      variant="outlined"
    />
  </Box>

  {/* Address - District */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.district"
      value={formData.address.district}
      onChange={handleInputChange}
      label="District"
      variant="outlined"
    />
  </Box>

  {/* Address - Pincode */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="address.pincode"
      value={formData.address.pincode}
      onChange={handleInputChange}
      label="Pincode"
      variant="outlined"
    />
  </Box>

  {/* Phone */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="phone"
      value={formData.phone}
      onChange={handleInputChange}
      label="Phone"
      variant="outlined"
    />
  </Box>

  {/* Phone2 */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="phone2"
      value={formData.phone2}
      onChange={handleInputChange}
      label="Phone 2"
      variant="outlined"
    />
  </Box>

  {/* Email */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="email"
      value={formData.email}
      onChange={handleInputChange}
      label="Email"
      variant="outlined"
    />
  </Box>

  {/* Email2 */}
  <Box mt={2}>
    <TextField
      fullWidth
      name="email2"
      value={formData.email2}
      onChange={handleInputChange}
      label="Email 2"
      variant="outlined"
    />
  </Box>
</ModalBody>


  <ModalFooter className="flex justify-end gap-3">
    <Button
      style={{
        textTransform: 'none',
        color: '#fff',
        fontSize: '12px',
        background: '#2D059C',
        border: 'none',
        borderRadius: '20px',
        '&:hover': { background: '#2D059C' },
        width: 225,
        height: 45,
      }}
      onClick={handleupdateSMSave}
    >
      Save
    </Button>
    <Button
      style={{
        borderRadius: '20px',
        padding: '10px',
        width: '200px',
      }}
      variant="outlined"
      color="secondary"
      onClick={handleCloseModal}
    >
      Cancel
    </Button>
  </ModalFooter>
</Modal>
    </>
  );
};

export default SupplierChainEntity;
