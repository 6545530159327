import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Chip,
  OutlinedInput,
  Checkbox
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAuth } from "../contexts/AuthConext";
import PassportSummary from "./PassportSummary/PassportSummary";

const EndProductStepOne = ({ handleNext }) => {
    const { brands,wumList ,PLM,MSDS,FUPS,Productuselist,
      fetchBrands,fetchgetUomList,fetchgetPlmList,fetchMSDSList,fetchFUPSList,GetProductUserPeriod,
      categoryList,
      fetchCategoryList,
      unitMessure,
      fetchgetUnitOfmessureList,
      saveProgress ,saasId
 } = useAuth(); 


const handleDropdownOpen = () => {
  fetchBrands();
};

const handleUnitmessureDropdownOpen = () => {
  fetchgetUnitOfmessureList();
};
const handleDropCategorydownOpen = () => {
  fetchCategoryList()

};
// Handle dropdown open event for UOM list
const handleUomDropdownOpen = () => {
  fetchgetUomList();
};

// Handle dropdown open event for PLM list
const handlePlmDropdownOpen = () => {
  fetchgetPlmList();
};

// Handle dropdown open event for MSDS list
const handleMsdsDropdownOpen = () => {
  fetchMSDSList();
};

// Handle dropdown open event for FUPS list
const handleFupsDropdownOpen = () => {
  fetchFUPSList();
};
const handleProductUserPeriodDropdownOpen = () => {
  GetProductUserPeriod();
};

  const navigate = useNavigate();
  const [weightOption, setWeightOption] = useState("enterWeight");
  const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState({
    productName: "",
    description: "",
    weight: "",
    weightUnit: "",
    quantity: "",
    quantityUnit: "",
    length: "",
    width: "",
    height: "",
    usePeriod: "",
    msds: [],
    nextUseApplication:[],
    endOfUseTreatment: [],
    // code: "PROD-CODE-456",
    serialNumber: "",
    // hsCode: "HS-CODE-123",
    // eanNumber: "EAN-789",
    category: "",
    // sku: "",
    brand: "",
    manufacturingDate: "",
    expirationDate: ""
  });

  const handleWeightOptionChange = (event) => {
    setWeightOption(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };



// close dropdown
const [openMsds, setOpenMsds] = useState(false); // MSDS dropdown state
const [openFups, setOpenFups] = useState(false); // End of Use Treatment dropdown state
const [openPlm, setOpenPlm] = useState(false); // Product End-of-Life Management dropdown state

// MSDS dropdown handlers
const handleMsdssDropdownOpen = () => setOpenMsds(true);
const handleMsdsDropdownClose = () => setOpenMsds(false);

// End of Use Treatment dropdown handlers
const handleFupssDropdownOpen = () => setOpenFups(true);
const handleFupsDropdownClose = () => setOpenFups(false);

// Product End-of-Life Management dropdown handlers
const handlePlmsDropdownOpen = () => setOpenPlm(true);
const handlePlmDropdownClose = () => setOpenPlm(false);



  const handleMultipleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
        ...prevValues,
        [name]: typeof value === 'string' ? value.split(',') : value,
    }));

      // Close the respective dropdown after selection
  if (event.target.name === 'msds') {
    setOpenMsds(false);
  } else if (event.target.name === 'nextUseApplication') {
    setOpenFups(false);
  } else if (event.target.name === 'endOfUseTreatment') {
    setOpenPlm(false);
  }
};






const handleChipDelete = (valueToDelete) => {
  handleMsdsDropdownClose()
  // Remove the selected item from the formValues.msds array
  const updatedMsds = formValues.msds.filter((selected) => selected !== valueToDelete);
  
  // Update the form values
  setFormValues((prevValues) => ({
    ...prevValues,
    msds: updatedMsds,
  }));
};


// Chip delete handlers  
const handleFupsChipDelete = (valueToDelete) => {  
  handleFupsDropdownClose()
  const updatedFups = formValues.nextUseApplication.filter((selected) => selected !== valueToDelete);  
  setFormValues((prevValues) => ({  
      ...prevValues,  
      nextUseApplication: updatedFups,  
  }));  
};  

const handlePlmChipDelete = (valueToDelete) => { 
  handlePlmDropdownClose() 
  const updatedPlm = formValues.endOfUseTreatment.filter((selected) => selected !== valueToDelete);  
  setFormValues((prevValues) => ({  
      ...prevValues,  
      endOfUseTreatment: updatedPlm,  
  }));  
};  



  // Required fields list
  const requiredFields = [
    'productName', 'description', 'weight', 'weightUnit', 'quantity', 'quantityUnit',
    'usePeriod', 'msds', 'nextUseApplication', 'endOfUseTreatment', 'category', 
    'brand', 'manufacturingDate', 'expirationDate', 'file'
  ];

  // Calculate progress based on filled fields
  const calculateProgress = () => {
    const totalFields = requiredFields.length;
    const filledFields = requiredFields.filter((field) => {
      if (field === 'file') {
        return selectedFile !== null; // Check if file is selected
      }
      const fieldValue = formValues[field];
      // Check if the field is filled for single value fields, or has items for arrays
      return Array.isArray(fieldValue) ? fieldValue.length > 0 : fieldValue !== "";
    }).length;

    return (filledFields / totalFields) * 100;
  };

  useEffect(() => {
    const progress = calculateProgress();
    saveProgress(progress); // Save progress in context whenever it changes
  }, [formValues, selectedFile]);







 

  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      saasId:saasId,
      name: formValues.productName,
      description: formValues.description,
      category: formValues.category,
      // sku: formValues.sku,
      brand: formValues.brand,
      manufacturingDate: formValues.manufacturingDate,
      expirationDate: formValues.expirationDate,
      code: formValues.code,
      serialNumber: formValues.serialNumber,
      hsCode: formValues.hsCode,
      eanNumber: formValues.eanNumber,
      basicInfo: {
        weight: formValues.weight,
        weightUnit: formValues.weightUnit,
        quantity: formValues.quantity,
        quantityUnit: formValues.quantityUnit,
        length: formValues.length,
        width: formValues.width,
        height: formValues.height,
        usePeriod: formValues.usePeriod,

        msds: formValues.msds,
        useApplication: formValues.nextUseApplication,
        usedTreatment: formValues.endOfUseTreatment
      }
    };
    try {
      const response = await DataService.addProductbasiInfo(payload);
      console.log('Product added successfully:', response.data);
      const productId = response.data.data.productId;

      if (response.data.status) {
        // Save productId to local storage
        localStorage.setItem('productId', productId);
        console.log('Product ID saved to local storage:', productId);
        await uploadProductImage(productId);

 // Show success Swal alert
 await Swal.fire({
  icon: 'success',
  title: 'Product Added Successfully',
  text: 'The product has been added successfully!',
});

handleNext(); 
     // Call the uploadProductImage function to upload the image

}else{
  const errorMessage = response.data.message;
 // Show error Swal alert with the extracted message
  await Swal.fire({
    icon: 'error',
    title: 'Product Addition Failed',
    text: errorMessage,
  });
  
}
    } catch (error) {
      console.error('Error adding product:', error);
      const errorMessage = error.response.data.message;
      // Show error Swal alert with the extracted message
       await Swal.fire({
         icon: 'error',
         title: 'Product Addition Failed',
         text: errorMessage,
       });
    }
  };

  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
  const progress = calculateProgress();



  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadProductImage = async (productId) => {
    if (!selectedFile) return;
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      // Log the FormData object to check if the file is appended correctly
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      const imageResponse = await DataService.uploadProductImage(formData, productId);
  
      console.log('Image uploaded successfully:', imageResponse.data);
  
      Swal.fire({
        icon: 'success',
        title: 'Image Uploaded Successfully',
        text: 'The product image has been uploaded successfully!',
      });
  
    } catch (imageError) {
      console.error('Error uploading image:', imageError);
  
      Swal.fire({
        icon: 'error',
        title: 'Image Upload Failed',
        text: 'There was an error uploading the product image.',
      });
    }
  };
  



  // category
  
  const [selectedCategory, setSelectedCategory] = useState('');





  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    // You can trigger any additional logic when a category is selected if needed
  };


  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription((prev) => !prev);
  };






  const stepsProgress = [
    { name: 'Basic Information', stepNumber: 1, totalSteps: 6, progress: progress }, // Initial progress for step 1
  ];
  return (
    <>
      <div style={containerWrapperStyle}>
      <Grid
        container
        spacing={2}
        className="scrollable-container p-3"
        style={scrollableContainerStyle}
      >
        <Grid md={8} container alignItems="center" spacing={2}>
          <form onSubmit={handleSubmit}>
            <Box p={3}>
              <Typography
                style={{ color: "GrayText" }}
                variant="h6"
                component="h6"
                mb={2}
              >
                Step 1/6
              </Typography>
              <Typography className="fw-bold" variant="h6">
                Basic Information
              </Typography>
              <Typography mb={3}>
                Tell us more about your products by filling in basic information.
              </Typography>
              <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                      Name of End Product *
                    </Typography>
                    <TextField
                     // error={formValues.productName.length === 0}
                      required
                      fullWidth
                      label="Name of End Product"
                      variant="outlined"
                      name="productName"
                      value={formValues.productName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                      Description *
                    </Typography>
                    <TextField
                      // error={formValues?.description.length === 0}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      label="Description"
                      variant="outlined"
                      name="description"
                      value={formValues.description}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
            <Typography variant="body1" mb={1}>
              Upload Product Image  *
            </Typography>
            <TextField
              required
              fullWidth
              variant="outlined"
              type="file"
              name="productImage"
              onChange={handleFileChange} 
              inputProps={{
                accept: "image/*", // Allow only image files
              }}
            />
          </Grid>

                </Grid>
              </Box>
              <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
                <Typography variant="h6" className='fw-bold' style={{ fontFamily:"'Poppins', 'Georgia', 'serif'"}} mb={2}>Basic Information</Typography>
                {/* <RadioGroup
                  value={weightOption}
                  onChange={handleWeightOptionChange}
                >
                  <FormControlLabel
                    value="enterWeight"
                    control={<Radio />}
                    label="Enter weight here"
                  />
                  <FormControlLabel
                    value="useLowerAssembly"
                    control={<Radio />}
                    label="Use the weight entered from a lower assembly level (products and/or components)"
                  />
                </RadioGroup> */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Weight *
                    </Typography>
                    <TextField
                    required
                      fullWidth
                      label="Weight"
                      variant="outlined"
                      name="weight"
                      value={formValues.weight}
                      onChange={handleChange}
                      disabled={weightOption !== "enterWeight"}
                       type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Weight Unit
                    </Typography>
                    <TextField
                      fullWidth
                      label="Weight Unit"
                      variant="outlined"
                      name="weightUnit"
                      value={formValues.weightUnit}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      onFocus={handleUomDropdownOpen} 
                    >
                      <option value=""></option>
                      {wumList.map((category) => (
        <option key={category.id} value={category.name}>
          {category.name}
        </option>
          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Quantity *
                    </Typography>
                    <TextField
                    required
                      fullWidth
                      label="Quantity"
                      variant="outlined"
                      name="quantity"
                      value={formValues.quantity}
                      onChange={handleChange}
                       type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Quantity Unit *
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      label="Quantity Unit"
                      variant="outlined"
                      name="quantityUnit"
                      value={formValues.quantityUnit}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      onFocus={handleUnitmessureDropdownOpen} 

                    >
                      <option value=""></option>
                      {unitMessure?.map((category) => (
        <option key={category.id} value={category.measure}>
          {category.measure}
        </option>
          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
  <Typography variant="body1" mb={1}>
    Size (Optional)
  </Typography>
  <TextField
    fullWidth
    label="Select Size"
    variant="outlined"
    name="Size"
    select
    SelectProps={{ native: true }}
  >
    <option value=""></option>

    <option value="XS">XS</option>
    <option value="S">S</option>
    <option value="M">M</option>
    <option value="L">L</option>
    <option value="XL">XL</option>
    <option value="XXL">XXL</option>
  </TextField>
</Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Length (mm)(Optional)
                    </Typography>
                    <TextField
                      fullWidth
                      label="Length (mm)"
                      variant="outlined"
                      name="length"
                      value={formValues.length}
                      onChange={handleChange}
                       type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Width (mm)(Optional)
                    </Typography>
                    <TextField
                      fullWidth
                      label="Width (mm)"
                      variant="outlined"
                      name="width"
                      value={formValues.width}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" mb={1}>
                      Height (mm)(Optional)
                    </Typography>
                    <TextField
                      fullWidth
                      label="Height (mm)"
                      variant="outlined"
                      name="height"
                      value={formValues.height}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                      Use Period *
                    </Typography>
                    <TextField
                     required
                      fullWidth
                      label="Use Period In Year"
                      variant="outlined"
                      name="usePeriod"
                      value={formValues.usePeriod}
                      onChange={handleChange}
                       
                      select
                      SelectProps={{ native: true }}
                      onFocus={handleProductUserPeriodDropdownOpen} 
                      >
                      <option value=""></option>
                      {Productuselist.map((category) => (
        <option key={category.id} value={category.periodName}>
          {category.periodName}
        </option>
          ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
      <Typography variant="body1" mb={1}>
        MSDS *
        <IconButton onClick={toggleDescription} sx={{ ml: 1 }}>
  {showDescription ? <InfoIcon /> : <InfoOutlinedIcon />}
</IconButton>

{showDescription && (
  <Box>
    <Typography variant="body2" color="textSecondary">
      Material Safety Data Sheet. It is a document that provides information on the properties of chemical products, including details on their hazards, safe handling, storage, and emergency measures in case of accidents.
    </Typography>
  </Box>
)}
      </Typography>
      <FormControl fullWidth variant="outlined">
      <InputLabel id="msds-label">MSDS</InputLabel> {/* Add the InputLabel */}
                        <Select
                            required
                            labelId="msds-label"
                            id="msds"
                            multiple
                            name="msds"
                            open={openMsds}
                            onOpen={handleMsdssDropdownOpen}
                            onClose={handleMsdsDropdownClose}
                            value={formValues.msds}
                            input={<OutlinedInput label="MSDS" />}
                            onChange={handleMultipleSelectChange}
                            onFocus={handleMsdsDropdownOpen}
                            renderValue={(selected) => (
                                <div className="flex flex-wrap gap-1">
                                    {selected?.map((value) => (
                                        <Chip style={{zIndex:"99999"}} key={value}
                                        onDelete={() => handleChipDelete(value)}
                                        label={MSDS.find(msds => msds.name === value)?.name} />
                                    ))}
                                </div>
                            )}
                            fullWidth
                        >
                            {MSDS?.map((msds) => (
                                <MenuItem key={msds.id} value={msds.name}>
                                    {msds.name}
                                </MenuItem>
                            ))}
                        </Select>
                   
                        </FormControl>
    </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                    Future Utilization Opportunities *
                    </Typography>
                  <FormControl fullWidth>
              <InputLabel id="end-of-use-treatment-label">End of Use Treatment</InputLabel>
              <Select
                required
                onOpen={handleFupssDropdownOpen}
                 open={openFups}
                 onClose={handleFupsDropdownClose}
                labelId="end-of-use-treatment-label"
                id="end-of-use-treatment"
                multiple
                name="nextUseApplication"
                value={formValues.nextUseApplication}
                onChange={handleMultipleSelectChange}
                onFocus={handleFupsDropdownOpen}
                input={<OutlinedInput label="End of Use Treatment" />}

                renderValue={(selected) => (
                  <div className="flex flex-wrap gap-1">
                    {selected?.map((value) => (
                         <Chip style={{zIndex:"99999"}} key={value}
                         onDelete={() => handleFupsChipDelete (value)}
                         label={FUPS.find(treatment => treatment.utilization === value)?.utilization} />
                    ))}
                  </div>
                )}
                fullWidth
              >
                {FUPS?.map((item) => (
                  <MenuItem key={item.id} value={item.utilization}>
                    {item?.utilization}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" mb={1}>
                    Product End-of-Life Management *
                    </Typography>

                    <FormControl fullWidth>
  <InputLabel id="product-end-of-life-management-label">Product End-of-Life Management</InputLabel>
  <Select
  required
   open={openPlm}
   onClose={handlePlmDropdownClose}
   onOpen={handlePlmsDropdownOpen}
    fullWidth
    labelId="product-end-of-life-management-label"
    label="Product End-of-Life Management"
    variant="outlined"
    name="endOfUseTreatment"
    value={formValues.endOfUseTreatment}
    onChange={handleMultipleSelectChange}
    multiple
    onFocus={handlePlmDropdownOpen}
    input={<OutlinedInput label="Product End-of-Life Management" />}
    renderValue={(selected) => (
      <div className="flex flex-wrap gap-1">
        {selected.map((value) => (
          <Chip style={{zIndex:"99999"}} key={value}
          onDelete={() => handlePlmChipDelete  (value)} label={PLM.find(treatment => treatment.name === value)?.name} />
        ))}
      </div>
    )}
  >
    {PLM.map((item) => (
      <MenuItem key={item.id} value={item.name}>
        {/* <Checkbox checked={formValues.endOfUseTreatment.indexOf(item.name) > -1} /> */}
        {item.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>


                  </Grid>
                </Grid>
              </Box>
              <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
                <Typography variant="h6" className='fw-bold' style={{ fontFamily:"'Poppins', 'Georgia', 'serif'"}} mb={2}>Additional Information</Typography>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
  <Typography variant="body1" mb={1}>
    Category *
  </Typography>
  <FormControl variant="outlined" style={{ width: '315px',}}>
        <InputLabel>All Category</InputLabel>
        <Select
             required
              label="Category"
              name="category"
              value={formValues.category}
              onChange={handleChange}
      onOpen={handleDropCategorydownOpen} 

        >
          <MenuItem value="all">All Category</MenuItem>
          {categoryList.map((category) => (
        <MenuItem key={category.id} value={category.categoryName}>
          {category.categoryName}
        </MenuItem>
          ))}
        </Select>
      </FormControl>
</Grid>
          
                  <Grid item xs={12} sm={6}>
  <Typography variant="body1" mb={1}>
    Brand *
  </Typography>
  <FormControl fullWidth variant="outlined">
    <InputLabel>Brand</InputLabel>
    <Select
      required
      label="Brand"
      name="brand"
      value={formValues.brand}
      onChange={handleChange}
      onOpen={handleDropdownOpen} 
    >
          {brands?.map((category) => (
        <MenuItem key={category.id} value={category.name}>
          {category?.name}
        </MenuItem>
          ))}
    </Select>
  </FormControl>
</Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" mb={1}>
                      Manufacturing Date
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      label="Manufacturing Date"
                      variant="outlined"
                      name="manufacturingDate"
                      value={formValues.manufacturingDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" mb={1}>
                      Expiration Date
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      label="Expiration Date"
                      variant="outlined"
                      name="expirationDate"
                      value={formValues.expirationDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      disabled={!formValues.manufacturingDate} 
                    />
                  </Grid>
                </Grid>
              </Box>
           
              <div className='flex justify-center mt-3 mb-5'>
              <Button  type='submit' style={{ background: "#4F46E5", padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
                Step 2: Materials
              </Button>
              </div>
            </Box>
          </form>
        </Grid>
        <Grid style={{ margin: "3px" }} md={4} container spacing={2}>
        <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />

        </Grid>
      </Grid>
      </div>
    </>
  );
};

export default EndProductStepOne;