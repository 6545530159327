import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';
import { useAuth } from "../../contexts/AuthConext";

const AddRetailModal = ({ open, onHide }) => {
  const {saasId
  } = useAuth(); 
  const initialState = {
    name: "",
    // certifications: "",
    phone: "",
    phone2: null,
    email: "",
    email1: null,
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: ""
    },
    productList: null // Setting productList to null
  };

  const [formData, setFormData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      setFormData({
        ...formData,
        address: { ...formData.address, [addressField]: value }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value === "" ? null : value  // Set to null if the value is an empty string
      });
    }
  };



  const validateForm = () => {
    const requiredFields = [
      "name",  "phone", "email", 
      "address.flatNo", "address.cityArea", "address.country",
      "address.state", "address.district", "address.pincode"
    ];

    for (let field of requiredFields) {
      let value = formData;
      for (let key of field.split(".")) {
        value = value[key];
      }
      if (!value.trim()) {
        return field;
      }
    }
    return null;
  };
  
  const handleSubmit = async () => {


    const missingField = validateForm();

    if (missingField) {
      Swal.fire({
        title: "Missing Information",
        text: `Please fill in the ${missingField.replace("address.", "")} field.`,
        icon: "warning",
      });
      return;
    }

    const dataToSend = {
      saasId:saasId,
      name: formData.name,
      // certifications: formData.certifications.split(","),
      contactInfo:{
      phone: formData.phone,
      phone2: formData.phone2,
      email: formData.email,
      email1: formData.email1,
      },
      address: {
        flatNo: formData.address.flatNo,
        cityArea: formData.address.cityArea,
        country: formData.address.country,
        state: formData.address.state,
        district: formData.address.district,
        pincode: formData.address.pincode
      },
      productList: null // Ensuring productList is sent as null
    };

    try {
      const response = await DataService.addRetailer(dataToSend);
      if (response.data.status) {
        Swal.fire({
          title: "Retailer Created Successfully",
          icon: "success",
          timer: 2000
        });
        onHide(); // Close the modal
      }else {
        // Show error if status is false
        Swal.fire({
          title: "Error Creating Retailer",
          text: response.data.message || "An unknown error occurred",
          icon: "error",
        });
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire({
          title: "Error Creating Retailer",
          text: error.response.data.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error Creating Retailer",
          icon: "error",
          text: error.message || "An unknown error occurred",
        });
      }
    }
  };

  return (
    <div>
      <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold">Create Retailer</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Card>
              <CardBody>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        label="Certifications"
                        value={formData.certifications}
                        onChange={handleInputChange}
                        name="certifications"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Mobile No. (Primary)"
                        value={formData.phone}
                        onChange={handleInputChange}
                        name="phone"
                         type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Mobile No. (Secondary)"
                        value={formData.phone2}
                        onChange={handleInputChange}
                        name="phone2"
                         type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email (Primary)"
                        value={formData.email}
                        onChange={handleInputChange}
                        name="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email (Secondary)"
                        value={formData.email1}
                        onChange={handleInputChange}
                        name="email1"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Address"
                        value={formData.address.flatNo}
                        onChange={handleInputChange}
                        name="address.flatNo"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="City Area"
                        value={formData.address.cityArea}
                        onChange={handleInputChange}
                        name="address.cityArea"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Country"
                        value={formData.address.country}
                        onChange={handleInputChange}
                        name="address.country"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="State"
                        value={formData.address.state}
                        onChange={handleInputChange}
                        name="address.state"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="District"
                        value={formData.address.district}
                        onChange={handleInputChange}
                        name="address.district"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Pincode"
                        value={formData.address.pincode}
                        onChange={handleInputChange}
                        name="address.pincode"
                         type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
            </Card>
            <div className="flex justify-center space-x-8 col-span-full mt-2">
                  <Button
                    style={{
                      borderRadius: '20px',
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="outlined"
                    color="secondary"
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                  <Button
                  onClick={()=>handleSubmit()}
                    type="submit"
                    style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: '#2D059C',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#2D059C' },
                      background: "#4F46E5",
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
          </Container>
        </ModalBody>
   
      </Modal>
    </div>
  );
};

export default AddRetailModal;
