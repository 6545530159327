import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, TextField, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Product from '../Product';
import ManufacturerDetails from './ManufacturerDetails';
import SupplierEntity from './SupplierEntity';
import DataService from '../../services/requestApi';
import SupplierChainEntity from './SupplierChainEntity';
import RetailEntity from './RetailEntity';
import { useAuth } from '../../contexts/AuthConext';

const ProductTabs = () => {
  const {saasId
  } = useAuth(); 

  const location = useLocation();
  const { productId } = location.state || {};
  const [value, setValue] = useState(0);
  const [productData, setProductData] = useState({});
  const [Progresbar, setProgresbar] = useState({});
 const [manufacturerData, setManufacturerData] = useState({});
  const [supplierData, setSupplierData] = useState({});
  const [retailerData, setRetailerData] = useState({});
  const [supplyChainEntity, setSupplyChainEntity] = useState({
    transportDetails: {},
    warehouseDetails: {},
    address: {},
    contactInfo: {},
  });

  useEffect(() => {
    const fetchSupplierChainData = async () => {
      try {
        const response = await DataService.getSupplierChainByProductId(productId);
        const data = response.data.data;
          // Extract transport details, warehouse details, address, and contact info
          const transportDetails = data.transportDetails;
          const warehouseDetails = data.warehouseDetails;
          const address = data.address;
          const contactInfo = data.contactInfo;
          setProgresbar(data?.progressBar)
          // Save to supplyChainEntity state
          setSupplyChainEntity({ transportDetails, warehouseDetails, address, contactInfo });
        // Set product data
        setProductData({
          productId: data.product.productId,
          name: data.product.name,
          description: data.product.description,
          category: data.product.category,
          sku: data.product.sku,
          brand: data.product.brand,
          manufacturingDate: data.product.manufacturingDate,
          expirationDate: data.product.expirationDate,
          code: data.product.code,
          serialNumber: data.product.serialNumber,
          hsCode: data.product.hsCode,
          eanNumber: data.product.eanNumber,
          image: data.product.image,
          basicInfo: data.product.basicInfo,
          materials:data.product.materials.map((material) => material.materialType),
          progressBar:data.progressBar
        });
  
        // Set manufacturer data
        setManufacturerData({
          id: data.manufacturer.id,
          name: data.manufacturer.name,
          certificates: [
            data.manufacturer.certificate1,
            data.manufacturer.certificate2,
            data.manufacturer.certificate3,
            data.manufacturer.certificate4,
            data.manufacturer.certificate5
          ],
          address: data.manufacturer.address,
          certifications: data.manufacturer.certifications,
          contactInfo: data.manufacturer.contactInfo
        });
  
           // Set first supplier data
      const supplier = data.supplier[0]; // Accessing the first supplier
      if (supplier) {
        setSupplierData({
          supplierId: supplier.supplierId,
          name: supplier.name,
          createdAt: supplier.createdAt,
          updatedAt: supplier.updatedAt,
          address: supplier.address,
          sustainabilityPractices: supplier.sustainabilityPractices,
          contactInfo: supplier.contactInfo
        });
      }
         // Set first retailer data
         const retailer = data.retailer[0]; // Accessing the first retailer
         if (retailer) {
           setRetailerData({
             retailerId: retailer.retailerId,
             name: retailer.name,
             address: retailer.address,
             contactInfo: retailer.contactInfo,
             productList: retailer.productList
           });
         }
  
      } catch (error) {
        console.error('Failed to fetch supplier chain data', error);
      }
    };
  
    fetchSupplierChainData();
  }, [productId]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className="px-5 mt-2" sx={{ width: '100%', overflow: 'hidden' }}>
    <Card className="p-2">
      {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button variant="contained" color="primary" sx={{ mr: 2 }}>
          Save to New Product
        </Button>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Box> */}
      <Tabs value={value} onChange={handleChange} aria-label="product tabs">
        <Tab label="Product Entity" />
        <Tab label="Manufacturer Entity" />
        <Tab label="Supplier Entity" />
        <Tab label="Logistics and distribution" />
        {/* <Tab label="Lifecycle Event Entity" /> */}
        <Tab label="Retail Entity" />
      </Tabs>
    </Card>
    <TabPanel value={value} index={0}>
      <Product  productData={productData} />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <ManufacturerDetails manufacturerData={manufacturerData} />
    </TabPanel>
    <TabPanel value={value} index={2}>
      <SupplierEntity supplierData={supplierData} />
    </TabPanel>
    <TabPanel value={value} index={3}>
      <SupplierChainEntity supplyChainEntity={supplyChainEntity}  />
    </TabPanel>
    <TabPanel value={value} index={4}>
      <RetailEntity retailerData={retailerData} />
    </TabPanel>
    {/* Add other TabPanels for remaining tabs */}
  </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ overflow: 'hidden' }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default ProductTabs;
