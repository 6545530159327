import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Box, Card, Select, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';

const Register = () => {
  const { register, handleSubmit, getValues, formState: { errors, isSubmitting } } = useForm();
  const navigate = useNavigate();
  const [signupSuccess, setSignupSuccess] = useState(false);

  const SignUp = async (data) => {
    try {
      const response = await DataService.Signup({
        username: data.fullName,
        password: data.password,
        mobilephone: data.phone,
        email: data.email,  // Include the email field in the payload
        role: "user",
        saasId:data.saasId
      });
  
      if (response?.data?.status) {
        Swal.fire({
          icon: 'success',
          title: 'Account Created',
          text: 'Now you can log in',
        });
        setSignupSuccess(true);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
        setTimeout(() => {
          setSignupSuccess(false);
        }, 6000);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Something Went Wrong',
          text: response?.data?.message || 'Please try again!', // Show API error message if available
        });
      }
    } catch (error) {
      console.error("Error during sign-up:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'An error occurred during sign-up', // Show API error message if available
      });
    }
  };
  

  return (
    <Box className="flex flex-col md:flex-row h-screen bg-background">
      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
          style={{
            backgroundImage: 'url(/regiterleft.png)', // Update with your image path
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        </Grid>
        <Grid className='px-5 ' item xs={12} md={5}>
          <Card className='p-4'>
            <div className='flex justify-center mb-1'>
              <Typography variant="h4" className="font-bold text-black">Create an account</Typography>
            </div>
            <div className='flex justify-center mb-2'>
              <Typography component="label" variant="body1" className="block text-sm font-medium text-zinc-700" htmlFor="full-name">
                To access your account
              </Typography>
            </div>
            <form onSubmit={handleSubmit(SignUp)}>
              <Box className="mb-1">
                <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="full-name">
                  FULL NAME
                </Typography>
                <TextField
                  {...register('fullName', { required: true })}
                  id="full-name"
                  placeholder="Full Name"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.fullName}
                  helperText={errors.fullName ? 'Full name is required' : ''}
                />
              </Box>
              <Box className="mb-1">
                <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="email">
                  Email
                </Typography>
                <TextField
                  {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                  id="email"
                  placeholder="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.email}
                  helperText={errors.email ? 'Valid email is required' : ''}
                />
              </Box>
              <Box className="mb-1">
                <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="phone">
                  Phone no.
                </Typography>
                <TextField
                  {...register('phone', { required: true })}
                  id="phone"
                  placeholder="Phone no."
                  type="tel"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.phone}
                  helperText={errors.phone ? 'Phone number is required' : ''}
                />
              </Box>

                 {/* Add Select for UserType */}
                 <Box className="mb-1">
                <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="userType">
                  SaasId
                </Typography>
                <TextField
    {...register('saasId', { required: true })}
    id="saasId"
    placeholder="Enter SaasId"
    variant="outlined"
    fullWidth
    required
    error={!!errors.saasId}
    helperText={errors.saasId ? 'SaasId is required' : ''}
  />
              </Box>
              <Box className="mb-1">
                <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="password">
                  Password
                </Typography>
                <TextField
                  {...register('password', { required: true })}
                  id="password"
                  placeholder="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.password}
                  helperText={errors.password ? 'Password is required' : ''}
                />
              </Box>
              <Box className="mb-2">
                <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="confirm-password">
                  Confirm Password
                </Typography>
                <TextField
                  {...register('confirmPassword', {
                    required: true,
                    validate: (value) => value === getValues('password') || 'Passwords must match'
                  })}
                  id="confirm-password"
                  placeholder="Confirm Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword ? 'Passwords must match' : ''}
                />
              </Box>
              <div className='flex justify-center'>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className="text-primary-foreground hover:bg-primary/80 w-full py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                  style={{
                    textTransform: 'none',
                    color: '#fff',
                    fontSize: '12',
                    background: '#2D059C',
                    border: 'none',
                    borderRadius: '20px',
                    '&:hover': { background: '#2D059C' },
                    width: 225,
                    height: 45,
                  }}
                >
                  Create an account
                </Button>
              </div>
            </form>
            <Typography variant="body2" className="mt-3 text-muted-foreground text-center">
              I have Already an account? <a href="/login" className="text-accent hover:underline">Login</a>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Register;
