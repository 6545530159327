import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton, Pagination } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { Box } from '@mui/system';
import DataService from '../../services/requestApi';
import { useNavigate } from 'react-router-dom';
import AddRetailModal from '../Modal/AddRetailModal';
import UpdateRetailerModal from '../Modal/UpdateRetailerModal';
import { useAuth } from '../../contexts/AuthConext';

const RetailerList = () => {
  const [retailers, setRetailers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const {saasId
  } = useAuth(); 
  const fetchRetailers = async (page, search = '') => {
    try {
      let response;
      if (search) {
        response = await DataService.searchRetailerByName(saasId,search);
      } else {
        response = await DataService.getRetailerList(saasId,page);
      }
      setRetailers(response.data.data);
      setTotalPages(response.data.totalPage);
    } catch (error) {
      console.error('Failed to fetch retailers', error);
    }
  };

  useEffect(() => {
    fetchRetailers(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEditClick = (row) => {
    setSelected(row);
    setUpdateModalOpen(true);
  };

  const handleDeleteClick = (retailerId) => {
    // Implement delete logic
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchRetailers(1, event.target.value); // Search from page 1
  };

  const columns = [
    {
      name: 'Retailer ID',
      selector: (row) => row.retailerId,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'City',
      selector: (row) => row.address?.cityArea,
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row) => row.address?.country,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.contactInfo?.email,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <IconButton aria-label="edit" className="mr-2" onClick={() => handleEditClick(row)}>
            <Edit style={{ color: "green" }} />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDeleteClick(row.retailerId)}>
            <Delete style={{ color: "#C81D20" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create New Retailer</h1>
            <Button variant="contained" color="primary" onClick={() => setSupplierModalOpen(true)}>Create</Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4">Retailer Directory</h2>
          <div className="flex mb-2">
            <TextField
              label="Search or browse retailers"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div style={{ height: "300px", overflow: "auto" }}>
            <DataTable
              columns={columns}
              data={retailers}
              pagination
              paginationServer
              paginationTotalRows={totalPages}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    minHeight: '72px', // override the row height
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <UpdateRetailerModal fetchRetailers={fetchRetailers} open={updateModalOpen} onHide={() => setUpdateModalOpen(false)} row={selected} />
      <AddRetailModal open={supplierModalOpen} onHide={() => setSupplierModalOpen(false)} />
    </>
  );
};

export default RetailerList;
