import React, { useEffect, useState } from 'react';
import { Button, TextField, IconButton, Pagination } from '@mui/material';
import DataTable from 'react-data-table-component';
import { Box } from '@mui/system';
import DataService from '../services/requestApi';
import { Delete, Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthConext';
import UpdateUserModal from './UpdateUserModal';
import RegisterModal from '../LoginRegister/Register';

const AllCustomer = () => {
    const navigate =useNavigate()
    const [UpdaetMoadal, setUpdaetMoadal] = useState(false);
    const [selected, setSelected] = useState({});
    const {saasId
 } = useAuth(); 

  const [customers, setCustomers] = useState([
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showRegisterModal, setShowRegisterModal] = useState(false); // State f
  // AllFUPS


  const GetAllUser = async (search = '') => {
    try {
      let response;
      if (search) {
        response = await DataService.searchUserByName(saasId,search);
  
      } else {
       response = await DataService.AllUser(saasId);
       }
      setCustomers(response?.data?.data || []);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };

useEffect(() => {
  GetAllUser()
}, [])

const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
  GetAllUser( event.target.value); // Search from page 1
};


// Delete user
  // Define columns for react-data-table-component
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await DataService.deleteUserById(id);
        Swal.fire('Deleted!', 'User has been deleted.', 'success');
        GetAllUser(); // Refresh the list after deletion
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to delete User', 'error');
    }
  };


  const columns = [
    // {
    //   name: 'Customer ID',
    //   selector: (row) => row.id,
    //   sortable: true,
    // },
    {
      name: 'Name',
      selector: (row) => row?.username,
      sortable: true,
    },
    {
      name: 'Mobile No.',
      selector: (row) => row?.mobilephone,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: 'UserRole',
      selector: (row) => row?.role,
      sortable: true,
    },
  
    {
      name: 'Actions',
      cell: (row) => (
        <>
           <IconButton aria-label="edit" onClick={() => { setSelected(row); setUpdaetMoadal(true); }}>
            <Edit style={{ color: 'green' }} />
          </IconButton>
           <IconButton aria-label="delete"  onClick={() => handleDelete(row?.id)}>
            <Delete style={{ color: '#C81D20' }} />
          </IconButton>
        <div>
        <Button
  variant="contained"
  size="small"
  className="bg-green-600"
  style={{ background: "#48ad48", textTransform: "none" }}
  onClick={() => navigate(`/PermissionManagement/${row?.id}`)}
>
  Page permission
</Button>

        </div>
        </>
      ),
      width: '250px', 
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl"> User Management</h1>
            <Button variant="contained" color="primary" 
            onClick={()=>navigate("/apipermission")}>
            API Permission
            </Button>
          </div>
        </Box>
        <div className= " bg-white p-6 shadow rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-xl mb-4">User Directory</h2> 
          <Button className="text-xl mb-4" variant="contained" color="primary" 
            onClick={() => setShowRegisterModal(true)}>
         Create New User
            </Button>
            </div>
          <div className="flex mb-2">
            <TextField
              label="Search or browse User"
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ height: '300px', overflow: 'auto' }}>
            <DataTable
              columns={columns}
              data={customers && customers
              }
              pagination
              paginationServer
              customStyles={{
                rows: {
                  style: {
                    minHeight: '72px', // override the row height
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>



     {/* Render the update modal */}
  
                <UpdateUserModal
                    isOpen={UpdaetMoadal}
                    toggle={() => setUpdaetMoadal(false)}
                    selectedUser={selected} // Pass the selected user data
                    GetAllUser={GetAllUser}
                />
       
    {/* Render the register modal */}
    <RegisterModal
        isOpen={showRegisterModal}
        onClose={() => setShowRegisterModal(false)}
        onSignUpSuccess={GetAllUser} // Refresh the list after successful registration
      />
    </>
  );
};

export default AllCustomer;
