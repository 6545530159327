import React, { useEffect, useState } from 'react';  
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';  
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TextField, Box, FormLabel,Button } from '@mui/material'; // Assuming you are using MUI for TextField and other components  
import Swal from 'sweetalert2'; // Ensure you have sweetalert2 installed  
import DataService from '../../../services/requestApi'; // Adjust the path as per your project structure  

function UpdateImpactModal({ open, onHide,impactEntity ,fetchSupplierChainData}) {  
    useEffect(() => {
    console.log("first impactEntity",impactEntity)
    }, [])
    
  //  new impact screen
  const [categories, setCategories] = useState([
    {
      name: "Carbon Footprint",
      isOpen: true,
      metrics: [
        { label: "Raw Material Emissions", value: "", unit: "CO2" },
        { label: "Production Emissions", value: "", unit: "CO2" },
        { label: "Transportation Emissions", value: "", unit: "CO2" },
        { label: "Use Emissions", value: "", unit: "CO2" },
        { label: "Disposal Emissions", value: "", unit: "CO2" },
      ],
    },
    {
      name: "Water Usage",
      isOpen: false,
      metrics: [
        { label: "Water Used in Production", value: "", unit: "Liters" },
        { label: "Water Used in Packaging", value: "", unit: "Liters" },
        { label: "Water Used in Transportation", value: "", unit: "Liters" },
      ],
    },
    {
      name: "Recyclability",
      isOpen: false,
      metrics: [
        { label: "Recyclable Material Weight", value: "", unit: "grams" },
        { label: "Total Material Weight", value: "", unit: "grams" },
      ],
    },
    {
      name: "Sustainable Materials",
      isOpen: false,
      metrics: [
        { label: "Weight of Sustainable Materials", value: "", unit: "grams" },
        { label: "Total Weight of Product", value: "", unit: "grams" },
      ],
    },
    {
      name: "Energy Efficiency",
      isOpen: false,
      metrics: [
        { label: "Output Energy", value: "", unit: "kWh" },
        { label: "Input Energy", value: "", unit: "kWh" },
      ],
    },
    {
      name: "Ethical Production",
      isOpen: false,
      metrics: [
        { label: "Number of Ethical Practices", value: "", unit: "Num" },
        { label: "Total Practices", value: "", unit: "Num" },
      ],
    },
    {
      name: "Chemical Safety",
      isOpen: false,
      metrics: [
        { label: "Number of Safe Chemicals", value: "", unit: "Num" },
        { label: "Total Chemicals Used", value: "", unit: "Num" },
      ],
    },
    {
      name: "Transportation Impact",
      isOpen: false,
      metrics: [
        { label: "Distance", value: "", unit: "km" },
        { label: "Emission Factor", value: "", unit: "kgCO2/km" },
      ],
    },
    {
      name: "Certifications",
      isOpen: false,
      metrics: [
        { label: "Number of Certifications", value: "", unit: "Num" },
        { label: "Total Possible Certifications", value: "", unit: "Num" },
      ],
    },
    {
      name: "Durability/Lifespan",
      isOpen: false,
      metrics: [
        { label: "Expected Lifespan", value: "", unit: "years" },
        { label: "Actual Lifespan", value: "", unit: "years" },
      ],
    },
  ])

  const toggleCategory = (index) => {
    const newCategories = [...categories];
    newCategories[index].isOpen = !newCategories[index].isOpen;
    setCategories(newCategories);
  };

  const handleInputChange = (categoryIndex, metricIndex, value) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].metrics[metricIndex].value = value;
    setCategories(newCategories);
  };


  const handleSubmit = async () => {
    const impactData = {
      carbonFootprint: {
        rawMaterialEmissions: parseFloat(categories[0].metrics[0].value) || 0,
        productionEmissions: parseFloat(categories[0].metrics[1].value) || 0,
        transportationEmissions: parseFloat(categories[0].metrics[2].value) || 0,
        useEmissions: parseFloat(categories[0].metrics[3].value) || 0,
        disposalEmissions: parseFloat(categories[0].metrics[4].value) || 0,
      },
      waterUsage: {
        waterUsedInProduction: parseFloat(categories[1].metrics[0].value) || 0,
        waterUsedInPackaging: parseFloat(categories[1].metrics[1].value) || 0,
        waterUsedInTransportation: parseFloat(categories[1].metrics[2].value) || 0,
      },
      recyclability: {
        recyclableMaterialWeight: parseFloat(categories[2].metrics[0].value) || 0,
        totalMaterialWeight: parseFloat(categories[2].metrics[1].value) || 0,
      },
      sustainableMaterials: {
        sustainableMaterialWeight: parseFloat(categories[3].metrics[0].value) || 0,
        totalProductWeight: parseFloat(categories[3].metrics[1].value) || 0,
      },
      energyEfficiency: {
        outputEnergy: parseFloat(categories[4].metrics[0].value) || 0,
        inputEnergy: parseFloat(categories[4].metrics[1].value) || 0,
      },
      ethicalProduction: {
        ethicalPractices: parseFloat(categories[5].metrics[0].value) || 0,
        totalPractices: parseFloat(categories[5].metrics[1].value) || 0,
      },
      chemicalSafety: {
        safeChemicals: parseFloat(categories[6].metrics[0].value) || 0,
        totalChemicalsUsed: parseFloat(categories[6].metrics[1].value) || 0,
      },
      transportationImpact: {
        distance: parseFloat(categories[7].metrics[0].value) || 0,
        emissionFactor: parseFloat(categories[7].metrics[1].value) || 0,
      },
      certifications: {
        certifications: parseFloat(categories[8].metrics[0].value) || 0,
        totalPossibleCertifications: parseFloat(categories[8].metrics[1].value) || 0,
      },
      durability: {
        expectedLifespan: parseFloat(categories[9].metrics[0].value) || 0,
        actualLifespan: parseFloat(categories[9].metrics[1].value) || 0,
      }
    };

    try {
      const response = await DataService.updateimpact(impactEntity.id, impactData);
      if (response.data.status){
        fetchSupplierChainData()
        onHide()
       }
      console.log("Impact Data Submitted:", response.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';


      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.log(error)
    
    }
  };
  

  return (  
    <Modal isOpen={open} toggle={onHide}>  
      <ModalHeader className='fw-bold' toggle={onHide}>Update Impact</ModalHeader>  
      <ModalBody>  
        {categories.map((category, categoryIndex) => (  
          <Box key={category.name} className="border rounded-lg overflow-hidden mb-3">  
            <button  
              className="flex justify-between items-center w-full px-4 py-3 text-left font-medium text-gray-900 bg-gray-50"  
              onClick={() => toggleCategory(categoryIndex)}  
              aria-expanded={category.isOpen}  
              aria-controls={`category-${categoryIndex}-content`}  
            >  
              {category.name}  
              {category.isOpen ? <FaChevronUp className="h-5 w-5" /> : <FaChevronDown className="h-5 w-5" />}  
            </button>  
            {category.isOpen && (  
              <Box id={`category-${categoryIndex}-content`} className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">  
                {category.metrics.map((metric, metricIndex) => (  
                  <Box key={metric.label} className="space-y-2">  
                    <FormLabel htmlFor={`${category.name}-${metric.label}`} className="text-sm font-medium text-gray-700">  
                      {metric.label}  
                    </FormLabel>  
                    <Box className="flex items-center space-x-2">  
                      <TextField  
                        id={`${category.name}-${metric.label}`}  
                        variant="outlined"  
                        placeholder="Enter Value"  
                        value={metric.value}  
                        onChange={(e) => handleInputChange(categoryIndex, metricIndex, e.target.value)}  
                        className="flex-grow"  
                        InputProps={{  
                          endAdornment: (  
                            <span className="text-sm text-gray-500">{metric.unit}</span>  
                          ),  
                        }}  
                      />  
                    </Box>  
                  </Box>  
                ))}  
              </Box>  
            )}  
          </Box>  
        ))}  
      </ModalBody>  
      <ModalFooter>  
        <Button     style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary" onClick={handleSubmit} className="mx-4">Submit</Button>  
       <Button    style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary" onClick={onHide} className="btn btn-secondary">Cancel</Button>  
      </ModalFooter>  
    </Modal>  
  );  
}  

export default UpdateImpactModal;