import React from 'react';  
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';  
import CarbonFootprintpng from '../../../assets/CO2.png';  
import WaterUsagepng from '../../../assets/Water.png';  
import Recyclabilitypng from '../../../assets/Recycle.png';  
import SustainableMaterialspng from '../../../assets/Construction Materials.png';  
import EnergyEfficiencypng from '../../../assets/Renewable Energy.png';  
import EthicalProductionpng from '../../../assets/Production Machine.png';  
import ChemicalSafetypng from '../../../assets/Warning Shield.png';  
import TransportationImpactpng from '../../../assets/Transportation.png';  
import Certificationspng from '../../../assets/Diploma.png';  
import Durabilitypng from '../../../assets/Time.png';
// Define styles for the PDF  
const styles = StyleSheet.create({  
  page: {  
    flexDirection: 'column',  
    padding: 10,  
    backgroundColor: '#ffffff',  
  },  
  section: {  
    marginVertical: 10,  
    borderRadius: 8,  
    padding: 15,  
    backgroundColor: '#f8f8f8',  
  },  
  title: {  
    fontSize: 18,  
    fontWeight: 'bold',  
    marginBottom: 10,  
    textDecoration: 'underline',  
    color: '#333',  
  },  
  productCard: {  
    marginBottom: 10,  
    padding: 10,  
    borderRadius: 8,  
    backgroundColor: '#ffffff',  
    border: '1px solid #e1e1e1',  
  },  
  imageContainer: {  
    width: '40%',  
    marginRight: 10,  
  },  
  textContainer: {  
    width: '60%',  
    display: 'flex',  
    flexDirection: 'column',  
  },  
  progressCircle: {  
    borderRadius: "100%",  
    width: 50,  
    height: 50,  
    border: "2px solid #4F46E5",  
    alignItems: 'center',  
    justifyContent: 'center',  
    marginRight: 10,  
  },  
  button: {  
    fontSize: 12,  
    backgroundColor: '#4F46E5',  
    color: '#fff',  
    padding: 5,  
    textAlign: 'center',  
    borderRadius: 4,  
    marginVertical: 5,  
  }, 
  blankcolor:{   backgroundColor: '#fff',     color: '#fff',  }, 
  normalText: {  
    fontSize: 12,  
    color: '#555',  
    lineHeight: 1.5,  
  },  
  image: {  
    width: '100%',  
    height: 'auto',  
    borderRadius: 5,  
  },  
  sectionHeading: {  
    fontSize: 16,  
    fontWeight: 'bold',  
    marginVertical: 10,  
  },  
  contactInfo: {  
    marginBottom: 10,  
  },  
  transportDetails: {  
    marginBottom: 10,  
  },  
  subSection: {
    marginBottom: 12, // Space between each subsection
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
});  

const PdfDocument = ({  
  productData,  
  impactEntity,  
  supplyChainEntity,  
  retailerData,  
  unifiedSupplierManufacturerData,  
}) => {
  const metrics = [
    { icon: CarbonFootprintpng, label: "Carbon Footprint", value: `${impactEntity?.carbonFootprint?.totalCarbonFootprint} kg CO2` },
    { icon: WaterUsagepng, label: "Water Usage", value: `${impactEntity?.waterUsage?.totalWaterUsage} L` },
    { icon: Recyclabilitypng, label: "Recyclability", value: `${impactEntity?.recyclability?.totalRecyclability}%` },
    { icon: SustainableMaterialspng, label: "Sustainable Materials", value: `${impactEntity?.sustainableMaterials?.totalSustainable}%` },
    { icon: EnergyEfficiencypng, label: "Energy Efficiency", value: `${impactEntity?.energyEfficiency?.totalEnergyEfficiency}%` },
    { icon: EthicalProductionpng, label: "Ethical Production", value: `${impactEntity?.ethicalProduction?.totalEthicalProduction}%` },
    { icon: ChemicalSafetypng, label: "Chemical Safety", value: `${impactEntity?.chemicalSafety?.totalChemicalSafety}%` },
    { icon: TransportationImpactpng, label: "Transportation Impact", value: `${impactEntity?.transportationImpact?.totalTranspoEmissions} kg CO2` },
    { icon: Certificationspng, label: "Certifications", value: `${impactEntity?.certifications?.totalCertificationScore}%` },
    { icon: Durabilitypng, label: "Durability", value: `${impactEntity?.durability?.totalDurabilityScore}%` },
  ];
   
  return (  
    <Document>  
      <Page size="A4"  style={styles.page}>  

        {/* Product Data Section */}  
        <View style={styles.section}>  
          <Text style={styles.title}>Product Details</Text>  

          <View style={styles.productCard}>  
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>  
              <View style={styles.imageContainer}>  
                <Image src={productData?.image} alt="Product Image" style={styles.image} />  
              </View>  

              <View style={styles.textContainer}>  
                <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 5 }}>{productData?.name}</Text>  
                <Text style={styles.button}>End product</Text>  
                <Text style={styles.normalText}>{productData?.description}</Text>  
              </View>  
            </View>  
            <View style={{ marginTop: 10 }}>  
              <Text style={styles.normalText}>Defined use period (years): {productData?.basicInfo?.usePeriod}</Text>  
              <Text style={styles.normalText}>Dominant material group(s): {productData?.materials}</Text>  
              <Text style={styles.normalText}>Next use application: {productData?.basicInfo?.useApplication}</Text>  
              <Text style={styles.normalText}>End of use Treatment: {productData?.basicInfo?.usedTreatment}</Text>  
              <Text style={styles.normalText}>Weight: {productData?.basicInfo?.weight} {productData?.basicInfo?.weightUnit}</Text>  
              <Text style={styles.normalText}>Quantity: {productData?.basicInfo?.quantity} Items</Text>  
              <Text style={styles.normalText}>MSDS: {productData?.basicInfo?.msds}</Text>  
            </View>  
          </View>  

          <View style={styles.productCard}>  
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>  
              <View style={styles.progressCircle}>  
                <Text style={{ fontSize: 12 }}>{productData?.progressBar}%</Text>  
              </View>  
              <View style={styles.textContainer}>  
                <Text style={{ fontWeight: 'bold' }}>  
                  This passport is on the right track. Fulfill extra information to increase your passport score.  
                </Text>  
              </View>  
            </View>  
          </View>  
        </View>  

        {/* Impact Entity Section */}  
        <View style={styles.section}>  
        <Text style={styles.title}>Impact Entity</Text>  
        <View style={styles.productCard}>  
       
        {metrics.map((metric, index) => (
  <View key={index} style={styles.row}>
    <Image src={metric.icon} style={{ width: 24, height: 24, marginRight: 10 }} />
    <Text style={styles.normalText}>{metric.label}: {metric.value}</Text>
  </View>
))}
  </View>
  </View>  
     
 

        {/* Supply Chain Entity Section */}  
        <View style={styles.section}>  
          <Text style={styles.title}>Supply Chain Information</Text>  
          <View style={styles.productCard}>
          <View style={styles.contactInfo}>  
            <Text style={styles.sectionHeading}>Contact Info:</Text>  
            <Text style={styles.normalText}>Phone (Primary): {supplyChainEntity?.contactInfo?.phone}</Text>  
            <Text style={styles.normalText}>Phone (Secondary): {supplyChainEntity?.contactInfo?.phone2}</Text>  
            <Text style={styles.normalText}>Email (Primary): {supplyChainEntity?.contactInfo?.email}</Text>  
            <Text style={styles.normalText}>Email (Secondary): {supplyChainEntity?.contactInfo?.email1}</Text>  
          </View>  
          <View style={styles.transportDetails}>  
            <Text style={styles.sectionHeading}>Transport Details:</Text>  
            <Text style={styles.normalText}>Method: {supplyChainEntity?.transportDetails?.transportMethod}</Text>  
            <Text style={styles.normalText}>Route: {supplyChainEntity?.transportDetails?.transportRout}</Text>  
            <Text style={styles.normalText}>Route Start: {supplyChainEntity?.transportDetails?.transportRoutStart}</Text>  
            <Text style={styles.normalText}>Route End: {supplyChainEntity?.transportDetails?.transportRoutEnd}</Text>  
          </View>  
          <View>  
            <Text style={styles.sectionHeading}>Warehouse Details:</Text>  
            <Text style={styles.normalText}>Name: {supplyChainEntity?.warehouseDetails?.warehouseName}</Text>  
            <Text style={styles.normalText}>License No: {supplyChainEntity?.warehouseDetails?.warehouseLicensNo}</Text>  
            <Text style={styles.normalText}>Manager: {supplyChainEntity?.warehouseDetails?.warehouseManager}</Text>  
            <Text style={styles.normalText}>GST No: {supplyChainEntity?.warehouseDetails?.warehouseGstNo}</Text>  
          </View>  
        </View> 
        </View>  



        {/* Retailer Data Section */}  
        <View style={styles.section}>
          <Text style={styles.title}>Retailer Information</Text>
          
          <View style={styles.productCard}>
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.sectionHeading}>Retailer ID</Text>
              <Text style={styles.normalText}>{retailerData?.retailerId || 'N/A'}</Text>
            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={styles.sectionHeading}>Retailer's Name</Text>
              <Text style={styles.normalText}>{retailerData?.name || 'N/A'}</Text>
            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={styles.sectionHeading}>Retailer Contact</Text>
              <Text style={styles.normalText}>Mobile (Primary): (+91) {retailerData?.contactInfo?.phone || 'N/A'}</Text>
              <Text style={styles.normalText}>Mobile (Secondary): (+91) {retailerData?.contactInfo?.phone2 || 'N/A'}</Text>
            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={styles.sectionHeading}>Retailer Email</Text>
              <Text style={styles.normalText}>Primary: {retailerData?.contactInfo?.email || 'N/A'}</Text>
              <Text style={styles.normalText}>Secondary: {retailerData?.contactInfo?.email1 || 'N/A'}</Text>
            </View>

            <View style={{ marginBottom: 10 }}>
              <Text style={styles.sectionHeading}>Retailer Address</Text>
              <Text style={styles.normalText}>
                {retailerData?.address?.flatNo || ''}, {retailerData?.address?.cityArea || ''}, {retailerData?.address?.district || ''}, {retailerData?.address?.state || ''}, {retailerData?.address?.country || ''}
              </Text>
            </View>
          </View>
        </View>



        
        {/* Unified Supplier Manufacturer Data Section */}  
        <View style={styles.section}>
  {/* Unified Supplier Manufacturer Data Section */}
  <Text style={styles.title}>Unified Supplier–Manufacture Entity</Text>
  <View style={styles.productCard}>
  {/* Entity Information Section */}
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Entity ID/Code</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierManufactureId || "N/A"}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>SaaS ID</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.saasId || 'N/A'}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Location</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierLocation || 'N/A'}</Text>
  </View>

  {/* Product Origin Details Section */}
  {/* <Text style={styles.title}>Product Origin Details</Text> */}
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Manufacturing Date</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.manufactureDate || 'N/A'}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Batch/Serial Number</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.number || 'N/A'}</Text>
  </View>

  {/* Supply Chain Details Section */}
  {/* <Text style={styles.title}>Supply Chain Details</Text> */}
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Supply Date</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierDate || 'N/A'}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Supply Location</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierLocation || 'N/A'}</Text>
  </View>

  {/* Terms and Agreements Section */}
  {/* <Text style={styles.title}>Terms and Agreements</Text> */}
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Payment Terms</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.paymentTerms || 'N/A'}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Delivery Terms</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.deliveryTerms || 'N/A'}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Warranty</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.warranty || 'N/A'}</Text>
  </View>

  {/* Pricing and Cost Section */}
  {/* <Text style={styles.title}>Pricing and Cost</Text> */}
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Manufacturing Cost</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.manufactureCost || 'N/A'}</Text>
  </View>
  <View style={{ marginBottom: 10 }}>
    <Text style={styles.sectionHeading}>Supplier Price</Text>
    <Text style={styles.normalText}>{unifiedSupplierManufacturerData?.supplierPrice || 'N/A'}</Text>
  </View>
</View>
</View>

      </Page>  
    </Document>  
  );  
};  

export default PdfDocument;
