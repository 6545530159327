import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Box } from '@mui/material';
import './../App.css';
import { useNavigate } from 'react-router-dom';
import logo from './../assets/oneScan.png';
import headphoneImg from './../assets/Productdelivery.png';
import chartbarImg from './../assets/ProductCatlog.png';
import boardImg from './../assets/AddManu.png';
import cartImg from './../assets/Addsuply.png';
import settingsImg from './../assets/Addretail.png';

const data = [
  {
    id: 1,
    image: headphoneImg,
    title: 'Add Product',
    description: 'Create a new product.',
    route: '/CustomStepper',
    buttonText: 'Create Product',  // Button text for ADD Product
  },
  {
    id: 2,
    image: chartbarImg,
    title: 'Product Catalog',
    description: 'Browse or search your products.',
    route: '/Product',
    buttonText: 'Product Catalog',  // Button text for Product List
  },
  {
    id: 3,
    image: boardImg,
    title: 'Add Manufacturer',
    description: 'Register a new manufacturer.',
    route: '/AddManufacture',
    buttonText: 'Add Manufacturer',  // Button text for Add Manufacture
  },
  {
    id: 4,
    image: cartImg,
    title: 'Add Supplier',
    description: 'Add a new supplier.',
    route: '/AddSupplier',
    buttonText: 'Add Supplier',  // Button text for Add Supplier
  },
  {
    id: 5,
    image: settingsImg,
    title: 'Add Retailer',
    description: 'Register a new retailer.',
    route: '/AddRetailer',
    buttonText: 'Add Retailer',  // Button text for Add Retailer
  },
];

const PassportSelection = () => {
  const navigate = useNavigate();

  const handleButtonClick = (route) => {
    navigate(route);
  };

  return (
    <Grid container style={{ fontFamily: "Roboto, Arial, sans-serif" }}>
      <Box sx={{ padding: 1, width: '100%' }}>
        <div className='flex justify-center'>
          <img src={logo} alt="Logo" className="w-24px" />
        </div>
        <div className='flex justify-center'>
          <Typography variant="h4" className="text-blue-600 mb-4">Digital Product Passport</Typography>
        </div>
        <Typography 
          variant="body2" 
          style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} 
          className='container fw-semibold text-center'  
          gutterBottom
        >
         Digital Product Passports (DPP) provide key insights into a product's lifecycle, emphasizing sustainability, environmental impact, and recyclability for informed, eco-friendly decisions. 
        </Typography>
        <Grid container spacing={2}>
          {data.map(item => (
            <Grid key={item.id} item xs={12} sm={6} md={4} lg={2.4}>
              <Card className='MainCard' sx={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: "20px" }}>
                <CardMedia
                  component="img"
                  image={item.image}
                  alt={item.title}
                  sx={{ width: 'auto', margin: '0 auto', paddingTop: 2 }}
                />
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  {/* <Typography variant="h6" component="h2" gutterBottom>
                    {item.title}
                  </Typography> */}
                  <Typography className='text-nowrap text-xs' variant="body" color="textSecondary" paragraph>
                    {item.description}
                  </Typography>
                  <Button 
                    style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: 12,
                      background: '#2D059C',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#2D059C' },
                      padding: '10px',
                      width: '150px',
                    }} 
                    variant="contained" 
                    color="primary" 
                    fullWidth
                    onClick={() => handleButtonClick(item.route)}
                  >
                    {item.buttonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default PassportSelection;
