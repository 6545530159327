import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton, Paper, Select, MenuItem, TextField } from '@mui/material';
import QRCode from 'react-qr-code'; 
import { Link } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import DataService from '../services/requestApi';
import { useAuth } from '../contexts/AuthConext';
import { Box } from '@mui/system';
const UnifiedSupplierManufactureView = ({ unifiedSupplierManufacturerData ,productData,fetchSupplierChainData}) => {


  const {saasId
  } = useAuth(); 



  const scrollableContainerStyle = {
    height: "80vh", // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "5px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };

  const qrRef = useRef();
  
  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };

  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };


  // download pdf 
const cardRef = useRef(); // Add a reference for the card to download as PDF
const downloadPDF = () => {  
  const input = cardRef.current;  

  // Ensure the image is fully loaded before capturing the canvas  
  const images = input.querySelectorAll('img');  
  const imagePromises = Array.from(images).map((img) => {  
    return new Promise((resolve) => {  
      if (img.complete) {  
        resolve();  
      } else {  
        img.onload = resolve;  
        img.onerror = resolve;  
      }  
    });  
  });  

  Promise.all(imagePromises).then(() => {  
    // After all images are loaded, render the canvas and generate the PDF  
    html2canvas(input, {  
      useCORS: true, // Enable CORS to allow cross-origin images  
      allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
    })  
      .then((canvas) => {  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF();  
        const imgProps = pdf.getImageProperties(imgData);  
        const pdfWidth = pdf.internal.pageSize.getWidth();  
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
        pdf.save('retailer-details.pdf');  
      })  
      .catch((error) => {  
        console.error('Failed to generate PDF:', error);  
      });  
  });  
};




 // Modal state for edit functionality
 const [modalOpen, setModalOpen] = useState(false);
 const [selectedOption, setSelectedOption] = useState('');

  const handleEditClick = () => {
    setModalOpen(true); // Open the modal on edit button click
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  // Handle option change (retailer selection)
  const handleOptionChange = (event) => {
    const selectedRetailerId = event.target.value;
    const selectedRetailer = unifieldSm.find((retailer) => retailer.id === selectedRetailerId);

    setSelectedOption(selectedRetailerId); // Set the retailer ID
    setFormData((prevState) => ({
      ...prevState,
      name: selectedRetailer?.name || '', // Update retailer name in formData
    }));
  };


  const [unifieldSm, setUnifieldSm] = useState([]);

  const fetchRetailers = async () => {
    try {

     const response = await DataService.getUnifiedSM(saasId);
     setUnifieldSm(response.data.data);
  
    } catch (error) {
      console.error('Failed to fetch retailers', error);
    }
  };
  useEffect(() => {
    fetchRetailers();
  }, []);

  const [formData, setFormData] = useState({
  
    name:"",
    manufactureDate: "",
    number: "",
    supplierDate: "",
    supplierLocation: "",
    paymentTerms: "",
    deliveryTerms: " ",
    warranty: " ",
    manufactureCost: "",
    supplierPrice: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleupdateSMSave = async () => {
    try {
      if (selectedOption) {
        const response = await DataService.updateSMlink(productData?.id, selectedOption,formData); // Use selectedOption for retailerId
   
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Retailer updated successfully!',
          });
          fetchSupplierChainData()
          handleCloseModal()
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select a retailer before saving.',
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };

  return (
    <>
    <Container maxWidth="lg" style={containerWrapperStyle}>
      <Grid container spacing={4} style={scrollableContainerStyle}>
        <Grid item xs={12} md={8} ref={cardRef}>
          <Paper elevation={3} className="p-6 rounded-lg">
            <Typography variant="h6" className="mb-6">
              Unified Supplier–Manufacture Entity
            </Typography>

            {/* Entity Information Section */}
            <Grid container spacing={2} className="mb-4">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Entity ID/Code
                </Typography>
                <Typography>   {unifiedSupplierManufacturerData?.supplierManufactureId || "N/A"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  SaaS ID
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.saasId || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className="font-semibold">
                  Location
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.supplierLocation || 'N/A'}</Typography>
              </Grid>
            </Grid>

            {/* Product Origin Details Section */}
            <Typography variant="h6" className="mt-6 mb-4 font-semibold">
              Product Origin Details
            </Typography>
            <Grid container spacing={2} className="mb-4">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Manufacturing Date
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.manufactureDate || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Batch/Serial Number
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.number || 'N/A'}</Typography>
              </Grid>
            </Grid>

            {/* Supply Chain Details Section */}
            <Typography variant="h6" className="mt-6 mb-4 font-semibold">
              Supply Chain Details
            </Typography>
            <Grid container spacing={2} className="mb-4">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Supply Date
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.supplierDate || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Supply Location
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.supplierLocation || 'N/A'}</Typography>
              </Grid>
            </Grid>

            {/* Terms and Agreements Section */}
            <Typography variant="h6" className="mt-6 mb-4 font-semibold">
              Terms and Agreements
            </Typography>
            <Grid container spacing={2} className="mb-4">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Payment Terms
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.paymentTerms || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Delivery Terms
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.deliveryTerms || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className="font-semibold">
                  Warranty
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.warranty || 'N/A'}</Typography>
              </Grid>
            </Grid>

            {/* Pricing and Cost Section */}
            <Typography variant="h6" className="mt-6 mb-4 font-semibold">
              Pricing and Cost
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Manufacturing Cost
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.manufactureCost || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className="font-semibold">
                  Supplier Price
                </Typography>
                <Typography>{unifiedSupplierManufacturerData?.supplierPrice || 'N/A'}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Right Side with QR Code */}
        <Grid item xs={12} md={4}>
          <Card className="mb-1">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditClick} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center" marginTop={2}>
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={downloadPDF} aria-label="download">
                    <DownloadIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className="p-3 mt-2">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value="https://example.com" style={{ height: "100px" }} />
            </div>
            <div className="flex justify-center">
              <Button
                variant="contained"
                color="primary"
                className="h-[35px] w-[235px]"
                style={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '12px',
                  background: '#ef613d',
                  border: 'none',
                  borderRadius: '20px',
                  '&:hover': { background: '#ef613d' },
                  width: 235,
                  height: 35,
                }}
                onClick={downloadQRCode}
              >
                Download QRCode Button
              </Button>
            </div>
            <div className="flex justify-center">
              <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>

         {/* Modal for editing */}
         <Modal isOpen={modalOpen} toggle={handleCloseModal}>
  <ModalHeader toggle={handleCloseModal}>Edit Retailer Details</ModalHeader>
  <ModalBody>
    <Typography>Select Retailer Type:</Typography>
    <Select value={selectedOption} onChange={handleOptionChange} fullWidth>
      {unifieldSm.map((retailer) => (
        <MenuItem key={retailer.id} value={retailer.id}>
          {retailer.name}
        </MenuItem>
      ))}
    </Select>

    {/* Add spacing between fields */}
    <Box mt={2}>
      <TextField
        fullWidth
        type="date"
        label="Manufacturing Date"
        variant="outlined"
        name="manufactureDate"
        value={formData.manufactureDate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="number"
        value={formData.number}
        onChange={handleInputChange}
        label="Batch/Serial Number"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        type="date"
        label="Supplier Date"
        variant="outlined"
        name="supplierDate"
        value={formData.supplierDate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="supplierLocation"
        value={formData.supplierLocation}
        onChange={handleInputChange}
        label="Supply Location"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="paymentTerms"
        value={formData.paymentTerms}
        onChange={handleInputChange}
        label="Payment Terms"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="deliveryTerms"
        value={formData.deliveryTerms}
        onChange={handleInputChange}
        label="Delivery Terms"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="warranty"
        value={formData.warranty}
        onChange={handleInputChange}
        label="Warranty Information"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="manufactureCost"
        value={formData.manufactureCost}
        onChange={handleInputChange}
        label="Manufacturing Cost"
        variant="outlined"
      />
    </Box>

    <Box mt={2}>
      <TextField
        fullWidth
        name="supplierPrice"
        value={formData.supplierPrice}
        onChange={handleInputChange}
        label="Supplier Pricing"
        variant="outlined"
      />
    </Box>
  </ModalBody>

  <ModalFooter className="flex justify-end gap-3">
    <Button
      style={{
        textTransform: 'none',
        color: '#fff',
        fontSize: '12px',
        background: '#2D059C',
        border: 'none',
        borderRadius: '20px',
        '&:hover': { background: '#2D059C' },
        width: 225,
        height: 45,
      }}
      onClick={handleupdateSMSave}
    >
      Save
    </Button>
    <Button
      style={{
        borderRadius: '20px',
        padding: '10px',
        width: '200px',
      }}
      variant="outlined"
      color="secondary"
      onClick={handleCloseModal}
    >
      Cancel
    </Button>
  </ModalFooter>
</Modal>


    </>
  );
};

export default UnifiedSupplierManufactureView;
