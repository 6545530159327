import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, Checkbox, FormControl, FormLabel, Box, Typography, Grid, LinearProgress, Select, InputLabel, Chip, MenuItem } from '@mui/material';
import { FormGroup } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import DataService from '../services/requestApi';
import { useAuth } from '../contexts/AuthConext';
import Swal from 'sweetalert2';
import PassportSummary from './PassportSummary/PassportSummary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress'; 
import ImpactCalculator from '../ImpactCalculatorSutainbility/ImpactCalculator';
const questions = [
  "Uses less energy?",
  "Lasts longer?",
  "Can be easily repaired?",
  "Parts can be easily disassembled and put to further use?",
  "Contains fewer substances of concern?",
  "Can be easily recycled?",
  "Contains more recycled content?",
  "Has a lower carbon and environmental footprint over its lifecycle?"
];

const EndProductStepFour = ({ handleNext }) => {
  const { H2Olist,Citrate,energyimpact,Sroi,rawMaterielList,
      GetRawMaterila,fetchCitrateList,fetchgetsetenergyimpactList,fetchgetSroiList,getH2OList,

      Endprogress,step2progress,savestep3Progress,saasId
  } = useAuth(); 

  const [uploadStatus, setUploadStatus] = useState({}); 
  const [loading, setLoading] = useState({});




const handleH2ODropdownOpen = () => {
  getH2OList();
};

const handleSroiDropdownOpen = () => {
  fetchgetSroiList();
};
const handleenergyimpactDropdownOpen = () => {
  fetchgetsetenergyimpactList();
};
const handleCitrateDropdownOpen = () => {
  fetchCitrateList();
};

const handleRawMaterilDropdownOpen = () => {
  GetRawMaterila();
};
  const [selectedSDGs, setSelectedSDGs] = useState([]);
  const [impactSource, setImpactSource] = useState('custom');
  const [SROI, setSROI] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [impactValues, setImpactValues] = useState({
    co2: [],
    rawMaterial: [],
    energy: [],
    h2o: []
  });

  const handleSDGChange = (event) => {
    const value = event.target.value;
    setSelectedSDGs((prev) => 
      prev.includes(value) ? prev.filter((sdg) => sdg !== value) : [...prev, value]
    );
  };
 const [radioValue, setRadioValue] = useState([]); 
// Your existing handleFileChange function
const handleFileChange = async (event, index) => {
  const file = event.target.files[0];
  if (file) {
    // Update selected files state
    setSelectedFiles((prev) => ({ ...prev, [index]: file }));

    // Create a FormData object
    const formData = new FormData();
    formData.append('file', file);

      // Set loading state
      setLoading((prev) => ({ ...prev, [index]: true }));
    try {
      // Use DataService to send the file
      const response = await DataService.addOCR(formData);
      // Show the response message using SweetAlert
      Swal.fire({
        title: response.data.status ? 'Success' : 'Error',
        text: response.data.message,
        icon: response.data.status ? 'success' : 'error',
        confirmButtonText: 'OK'
      });
         // Update upload status
         if (response.data.status) {
          setUploadStatus((prev) => ({ ...prev, [index]: 'success' }));
          setRadioValue((prev) => ({ ...prev, [index]: 'yes' }));
        } else if (response.data.message === "Certificate is expired.") {
          // If certificate is expired, tick "No"
          setUploadStatus((prev) => ({ ...prev, [index]: 'expired' }));
          setRadioValue((prev) => ({ ...prev, [index]: 'no' }));
        } else {
          // For other errors, tick "No"
          setUploadStatus((prev) => ({ ...prev, [index]: 'error' }));
          setRadioValue((prev) => ({ ...prev, [index]: 'no' }));
        }
    } catch (error) {
      // Handle errors
      console.error('Error uploading file', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while uploading the file.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      setUploadStatus((prev) => ({ ...prev, [index]: 'error' }));
      setRadioValue((prev) => ({ ...prev, [index]: 'no' }));
    } finally {
      // Reset loading state
      setLoading((prev) => ({ ...prev, [index]: false }));
    }
  }
};


  // Handle radio button change
  const handleRadioChange = (event, index) => {
    const value = event.target.value;
    setRadioValue((prev) => ({
      ...prev,
      [index]: value
    }));
  };

  
// close select work  


// Manage dropdown open state
const [open, setOpen] = useState(false); // For CO2 Impact
const [open1, setOpen1] = useState(false); // For Raw Material Impact

// Handlers for CO2 dropdown
const handleOpen = () => {
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

// Handlers for Raw Material dropdown
const handleOpen1 = () => {
  setOpen1(true);
};
const handleClose1 = () => {
  setOpen1(false);
};

const [openEnergy, setOpenEnergy] = useState(false); // For Energy Impact dropdown
const [openH2O, setOpenH2O] = useState(false); // For H2O Impact dropdown

// Handlers for Energy dropdown
const handleOpenEnergy = () => {
  setOpenEnergy(true);
};
const handleCloseEnergy = () => {
  setOpenEnergy(false);
};

// Handlers for H2O dropdown
const handleOpenH2O = () => {
  setOpenH2O(true);
};
const handleCloseH2O = () => {
  setOpenH2O(false);
};

// Modify handleImpactChange to close dropdowns
const handleImpactChange = (e) => {
  setImpactValues({ ...impactValues, [e.target.name]: e.target.value });

  // Close the respective dropdown after selection
  if (e.target.name === 'co2') {
    setOpen(false);  // Close CO2 dropdown
  } else if (e.target.name === 'rawMaterial') {
    setOpen1(false);  // Close Raw Material dropdown
  } else if (e.target.name === 'energy') {
    setOpenEnergy(false);  // Close Energy dropdown
  } else if (e.target.name === 'h2o') {
    setOpenH2O(false);  // Close H2O dropdown
  }
};

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('saasId', saasId);

    formData.append('sroiMinutes', SROI);
    formData.append('c02', impactValues.co2);
    formData.append('rawMaterial', impactValues.rawMaterial);
    formData.append('energy', impactValues.energy);
    formData.append('h20', impactValues.h2o);
  
    questions.forEach((question, index) => {
      const selectedAnswer = document.querySelector(`input[name="question-${index}-answer"]:checked`);
      if (selectedAnswer) {
        formData.append(question.split(' ').join(''), selectedAnswer.value === "yes");
      } else {
        console.warn(`No answer selected for question ${index + 1}: ${question}`);
      }
  
      if (selectedFiles[index]) {
        formData.append(`${question.split(' ').join('')}Image`, selectedFiles[index]);
      }
    });
  
    try {
      const response = await DataService.uploadImapcat(formData); // Use the uploadImapcat method from DataService
      console.log('API response:', response.data);
      if (response.data.status) {
        await Swal.fire({
          icon: 'success',
          title: 'Impact Added Successfully',
          text: 'The product has been added successfully!',
        });
        
        handleNext(); 
             // Call the uploadProductImage function to upload the image
        
        }
    // Proceed to the next step if needed
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

 const requiredFields = ['sroiMinutes', 'co2', 'rawMaterial', 'energy', 'h2o'];

// Calculate progress
const calculateProgress = () => {
  const totalFields = requiredFields.length + 1; // Add 1 for the file check
  let filledFields = 0;

  // Check if SROI is filled
  if (SROI && SROI.length > 0) filledFields++;

  // Check impact values fields
  requiredFields.forEach((field) => {
    const fieldValue = impactValues[field];
    if (Array.isArray(fieldValue)) {
      // Count the field as filled if it has values
      if (fieldValue.length > 0) filledFields++;
    } else {
      // Count as filled if it's a non-empty string or a valid value
      if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "") {
        filledFields++;
      }
    }
  });

  // Check if any files are uploaded
  if (Object.keys(selectedFiles).length > 0 && Object.values(selectedFiles).some(file => file)) {
    filledFields++;
  }

  return (filledFields / totalFields) * 100;
};

const progress = calculateProgress();

useEffect(() => {
  const progress = calculateProgress();
  savestep3Progress(progress); // Calculate and update progress when component renders or impactValues changes
}, [impactValues, selectedFiles]);


  const stepsProgress = [
    { name: 'Basic Information', stepNumber: 1, totalSteps: 7, progress: Endprogress },
    { name: 'Materials', stepNumber: 2, totalSteps: 7, progress: step2progress },
    { name: 'Impact', stepNumber: 3, totalSteps: 7, progress: progress }, // Example percentage for Materials

  ];

  const scrollableContainerStyle = {
    height: "80vh",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px",
    boxSizing: "border-box",
  };

  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  };


  // 
  const handleChipDelete = (value, type,dropdownType) => {  
     // Close the relevant dropdown based on the dropdownType  
  if (dropdownType === 'co2') {  
    handleClose();  
  } else if (dropdownType === 'rawMaterial') {  
    handleClose1();  
  } else if (dropdownType === 'energy') {  
    handleCloseEnergy();  
  }  
  else if (dropdownType === 'h2o') {  
    handleCloseH2O();  
  } 
    const newValues = impactValues[type].filter((item) => item !== value);  
    setImpactValues((prev) => ({ ...prev, [type]: newValues }));  
  };  
  return (
    <div style={containerWrapperStyle}>
      <Grid container spacing={2} className="scrollable-container p-3" style={scrollableContainerStyle}>
        <Grid item xs={12} md={7}>
          <Box>
            <Typography style={{ color: "GrayText" }} variant="h6" component="h6" mb={2} gutterBottom>Step 3/7</Typography>
            <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6">Impact</Typography>
            <FormLabel component="legend">The impact of your products can be calculated by the system, or you can fill in your own impact numbers.</FormLabel>

            <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fff' }}>
              <TextField
                error={SROI?.length === 0}
                label="SROI impact * "
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => { setSROI(e.target.value) }}
                // InputProps={{ endAdornment: <Typography>Minutes</Typography> }}
                select
                SelectProps={{ native: true }}
                onFocus={handleSroiDropdownOpen} 

                >
                <option value=""></option>
                {Sroi.map((category) => (
  <option key={category.id} value={category.name}>
    {category.name}
  </option>
    ))}
              </TextField>

        
                <InputLabel  className='my-2' id="co2-label">CO2 impact *</InputLabel>
                <Select
                fullWidth
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                  labelId="co2-label"
                  id="co2"
                  multiple
                  name="co2"
                  value={impactValues.co2 || []}
                  onChange={handleImpactChange}
                  onFocus={handleCitrateDropdownOpen}
                  renderValue={(selected) => (
                    <div className="flex flex-wrap gap-1">
                      {selected.map((value) => (
                        <Chip
                        style={{zIndex:"99999"}} 
                        onDelete={() => handleChipDelete(value, 'co2','co2')} 
                        key={value} label={Citrate.find(category => category.name === value)?.name} />
                      ))}
                    </div>
                  )}
                >
                  {Citrate.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              {/* </FormControl> */}
             
              {/* <FormControl fullWidth margin="normal"> */}
                <InputLabel className='my-2' id="rawMaterial-label">Raw Material Impact *</InputLabel>
                <Select
                fullWidth
                open={open1}
  onClose={handleClose1}
  onOpen={handleOpen1}
                  labelId="rawMaterial-label"
                  id="rawMaterial"
                  multiple
                  name="rawMaterial"
                  value={impactValues.rawMaterial || []}
                  onChange={handleImpactChange}
                  onFocus={handleRawMaterilDropdownOpen}
                  renderValue={(selected) => (
                    <div className="flex flex-wrap gap-1">
                      {selected.map((value) => (
                        <Chip 
                        style={{zIndex:"99999"}} 
                        onDelete={() => handleChipDelete(value, 'rawMaterial','rawMaterial')}
                        key={value} label={rawMaterielList.find(category => category.name === value)?.name} />
                      ))}
                    </div>
                  )}
                >
                  {rawMaterielList.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              {/* </FormControl> */}

              {/* <FormControl fullWidth margin="normal"> */}
                <InputLabel className='my-2' id="energy-label">Energy Impact *</InputLabel>
                <Select
                fullWidth
                open={openEnergy}
                onClose={handleCloseEnergy}
                onOpen={handleOpenEnergy}
                  labelId="energy-label"
                  id="energy"
                  multiple
                  name="energy"
                  value={impactValues.energy || []}
                  onChange={handleImpactChange}
                  onFocus={handleenergyimpactDropdownOpen}
                  renderValue={(selected) => (
                    <div className="flex flex-wrap gap-1">
                      {selected.map((value) => (
                        <Chip 
                        style={{zIndex:"99999"}} 
                        onDelete={() => handleChipDelete(value, 'energy','energy')}
                        key={value} label={energyimpact.find(category => category.name === value)?.name} />
                      ))}
                    </div>
                  )}
                >
                  {energyimpact.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              {/* </FormControl> */}

              {/* <FormControl fullWidth margin="normal"> */}
                <InputLabel className='my-2' id="h2o-label">H2O Impact *</InputLabel>
                <Select
                fullWidth
                open={openH2O}
                onClose={handleCloseH2O}
                onOpen={handleOpenH2O}
                  labelId="h2o-label"
                  id="h2o"
                  multiple
                  name="h2o"
                  value={impactValues.h2o || []}
                  onChange={handleImpactChange}
                  onFocus={handleH2ODropdownOpen}
                  renderValue={(selected) => (
                    <div className="flex flex-wrap gap-1">
                      {selected.map((value) => (
                        <Chip
                        style={{zIndex:"99999"}} 
                        onDelete={() => handleChipDelete(value, 'h2o','h2o')} 
                        key={value} label={H2Olist.find(category => category.name === value)?.name} />
                      ))}
                    </div>
                  )}
                >
                  {H2Olist.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              {/* </FormControl> */}

              <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6" gutterBottom>SDG impact
                <span className='text-xs mx-2'>  (Sustainable Development Goals)</span>
                 </Typography>
              <Typography variant="body2" gutterBottom>
                Please select the SDG goals that are relevant to this passport. No more than 4 goals should be selected.
              </Typography>

              <div className="p-6 mt-2 bg-white rounded-md shadow-md">
                <Typography variant="h6" component="div" className="mb-4">
                Proof of Sustainability:
                </Typography>
                {questions.map((question, index) => (
                  <FormGroup key={index} className="mb-4">
                    <Typography variant="body1" className="mb-2">
                      • {question}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={7}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<CloudUpload />}
                          component="label"
                          className="text-black mb-2 rounded"
                          style={{ background: "#D9D9D9" }}
                        >
                          Upload Document
                          <input
                                  type="file"
                  hidden
                  onChange={(event) => handleFileChange(event, index)}
                          />
                        </Button>
                        {loading[index] ? (
                <div className="flex items-center mt-2">
                  <CircularProgress size={24} style={{ marginRight: '8px' }} />
                  <Typography variant="body2">Uploading...</Typography>
                </div>
              ) : (
                selectedFiles[index] && (
                  <Typography variant="body2" className="mt-2 flex items-center">
                    {uploadStatus[index] === 'success' && (
                      <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                    )}
                    Selected File: {selectedFiles[index].name}
                  </Typography>
                )
              )}
                      </Grid>

                      <Grid item xs={12} md={5}>
  {/* <div className="flex">
    <FormControlLabel
      control={
        <Radio
          name={`question-${index}-answer`}
          value="yes"
          checked={uploadStatus[index] === 'success' && radioValue[index] === 'yes'}
          onChange={(event) => handleRadioChange(event, index)}
        />
      }
      label="Yes"
    />
    <FormControlLabel
      control={
        <Radio
          name={`question-${index}-answer`}
          value="no"
          checked={uploadStatus[index] === 'expired' && radioValue[index] === 'no'}
          onChange={(event) => handleRadioChange(event, index)}
        />
      }
      label="No"
    />
  </div> */}
    {/* Display Certificate Valid/Expired Message */}
    {uploadStatus[index] === 'success' && (
    <Typography  variant="body2" color="green" className="mt-2 fw-bold">
      Certificate is valid
    </Typography>
  )}
  {uploadStatus[index] === 'expired' && (
    <Typography variant="body2" color="red" className="mt-2 fw-bold">
      Certificate is expired
    </Typography>
  )}
</Grid>


                    </Grid>
                  </FormGroup>
                ))}
              </div>
            </Box>

            <div className='flex justify-center mt-3 mb-5'>
              <Button onClick={handleSubmit} style={{ background: "#4F46E5", padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
                Step 4: Manufacturer Entity
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid style={{ margin: "3px" }}  className='mx-3' md={4} spacing={2} >
        <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />

       <ImpactCalculator />
          </Grid>
    
      </Grid>
    </div>
  );
};

export default EndProductStepFour;
