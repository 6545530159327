import React, { useState } from 'react';
import { Button, TextField, IconButton, Pagination } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DataTable from 'react-data-table-component';
import { Box } from '@mui/system';

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const AllCustomer = () => {
    const navigate =useNavigate()
  const [customers, setCustomers] = useState([
    { customerId: '1', name: 'John Doe', city: 'New York', country: 'USA', email: 'john@example.com' },
    { customerId: '2', name: 'Jane Smith', city: 'London', country: 'UK', email: 'jane@example.com' },
    { customerId: '3', name: 'Michael Lee', city: 'Tokyo', country: 'Japan', email: 'michael@example.com' },
    { customerId: '4', name: 'Sara Khan', city: 'Dubai', country: 'UAE', email: 'sara@example.com' },
    // Add more dummy customer data as needed
  ]);



  const columns = [
    {
      name: 'Customer ID',
      selector: (row) => row.customerId,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'City',
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
      <Button
            variant="contained"
            size="small"
            className='bg-green-600'
            style={{background:"#48ad48"}}
            onClick={() => navigate("/PermissionManagement")}
          >
            Grant Permission
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create New User</h1>
            <Button variant="contained" color="primary" >
              Create
            </Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4">User Directory</h2>
          <div className="flex mb-2">
            <TextField
              label="Search or browse User"
            //   value={searchTerm}
            //   onChange={handleSearchChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ height: '300px', overflow: 'auto' }}>
            <DataTable
              columns={columns}
              data={customers.filter((customer) =>
                customer.name.toLowerCase()
              )}
              pagination
              paginationServer
              customStyles={{
                rows: {
                  style: {
                    minHeight: '72px', // override the row height
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default AllCustomer;
