import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import QRCode from 'react-qr-code'; 
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import * as htmlToImage from 'html-to-image';

const scrollableContainerStyle = {
    maxHeight: '350px',
    overflowY: 'auto',
    fontFamily: "Inter"
};

const SupplierChainEntity = ({ supplyChainEntity }) => {
  const qrRef = useRef();
  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };

  const [products, setProducts] = useState([
    { name: "Product Name", quantity: 5 },
    { name: "Product Name", quantity: 10 },
    { name: "Product Name", quantity: 14 },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEditProduct = (index) => {
    // Implement your edit logic here.
    console.log("Edit product at index:", index);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="lg" className="scrollable-container" style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card className="p-10">
            <Grid container spacing={2}>
              {/* <Grid className='p-4' item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Supplier ID
                </Typography>
                <Typography variant="body1">
                  {supplyChainEntity?.supplierId}
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Supplier's name
                </Typography>
                <Typography variant="body1">
                  {supplyChainEntity?.name}
                </Typography>
              </Grid> */}
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography className='p-2' variant="body2" color="textSecondary">
                Warehouse mobile no.
                </Typography>
                <Typography className='p-2' variant="body1">
                  (+91) {supplyChainEntity?.contactInfo?.phone} (Primary)
                </Typography>
                <Typography className='p-2' variant="body1">
                  (+91) {supplyChainEntity?.contactInfo?.phone2} (Secondary)
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography className='p-2' variant="body2" color="textSecondary">
                Warehouse E-mail
                </Typography>
                <Typography className='p-2' variant="body1">
                  {supplyChainEntity?.contactInfo?.email} (Primary)
                </Typography>
                <Typography className='p-2' variant="body1">
                  {supplyChainEntity?.contactInfo?.email1} (Secondary)
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12}>
                <Typography variant="body2" color="textSecondary">
                Warehouse Address
                </Typography>
                <Typography variant="body1">
                  {supplyChainEntity?.address?.flatNo}, {supplyChainEntity?.address?.cityArea}, 
                  {supplyChainEntity?.address?.district}, {supplyChainEntity?.address?.state}, 
                  {supplyChainEntity?.address?.country}, {supplyChainEntity?.address?.pincode}
                </Typography>
              </Grid>
              {/* Transport Details */}
              <Grid className='p-4' item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Transport Details
                </Typography>
                <Typography variant="body2">Transport Method: {supplyChainEntity?.transportDetails?.transportMethod}</Typography>
                <Typography variant="body2">Transport Route: {supplyChainEntity?.transportDetails?.transportRout}</Typography>
                <Typography variant="body2">Route Start: {supplyChainEntity?.transportDetails?.transportRoutStart}</Typography>
                <Typography variant="body2">Route End: {supplyChainEntity?.transportDetails?.transportRoutEnd}</Typography>
              </Grid>
              {/* Warehouse Details */}
              <Grid className='p-4' item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Warehouse Details
                </Typography>
                <Typography variant="body2">Warehouse Name: {supplyChainEntity?.warehouseDetails?.warehouseName}</Typography>
                <Typography variant="body2">License No: {supplyChainEntity?.warehouseDetails?.warehouseLicensNo}</Typography>
                <Typography variant="body2">Manager: {supplyChainEntity?.warehouseDetails?.warehouseManager}</Typography>
                <Typography variant="body2">GST No: {supplyChainEntity?.warehouseDetails?.warehouseGstNo}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="mb-1">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center" marginTop={2}>
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton aria-label="download">
                    <DownloadIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className="p-3 mt-2">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value="https://example.com" style={{ height: "100px" }} />
            </div>
            <div className="flex justify-center ">
              <Button variant="contained" color="primary" 
                className="h-[35px] w-[235px]"
                style={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '12px',
                  background: '#ef613d',
                  border: 'none',
                  borderRadius: '20px',
                  '&:hover': { background: '#ef613d' },
                  width: 235,
                  height: 35,
                }}
                onClick={downloadQRCode}
              >
                Download QRCode Button
              </Button>
            </div>
            <div className="flex justify-center ">
              <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupplierChainEntity;
