import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataService from '../../../services/requestApi';
import { Button, IconButton, Box, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import UpdateBrandModal from './UpdateBrandModal';
import CreateBrandModal from './CreateBrandModal';
import { useAuth } from '../../../contexts/AuthConext';
import Swal from 'sweetalert2';

const Brand = ({tab}) => {
  const { brands, fetchBrands } = useAuth(); 
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedUom, setSelectedUom] = useState(null); 

    useEffect(() => {
        if(tab == 'Brand'){
        fetchBrands();
        }
      }, [tab]);
    

  

    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              });
              if (result.isConfirmed) {
            await DataService.deleteBrand(id);
            fetchBrands();
              }
        } catch (error) {
            console.error('Failed to delete Brand', error);
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Brand Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <IconButton aria-label="edit"
                        onClick={() => { setSelectedBrand(row.id); 
                                  setSelectedUom(row.name);
                                 setUpdateModalOpen(true); }}
                    >
                        <Edit style={{ color: 'green' }} />
                    </IconButton>
                    <IconButton aria-label="delete"
                        onClick={() => handleDelete(row.id)}
                    >
                        <Delete style={{ color: '#C81D20' }} />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="px-4">
                <Box
                    className="mb-2"
                    p={2}
                    borderRadius={2}
                    border="1px solid #e0e0e0"
                    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                    width="100%"
                    height="fit-content"
                    bgcolor="white"
                >
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl">Brand</h1>
                        <Button variant="contained" color="primary" onClick={() => setCreateModalOpen(true)}>
                            Create
                        </Button>
                    </div>
                </Box>
                <div className="bg-white p-6 shadow rounded-lg">
                    {/* <h2 className="text-xl mb-2">Brand Directory</h2> */}
                    {/* <div className="flex mb-2">
                        <TextField label="Search or browse Brand"
                            variant="outlined" fullWidth />
                    </div> */}
                    <div style={{ height: "200px", overflow: "auto" }}>
                        <DataTable
                            columns={columns}
                            data={brands}
                            pagination
                            responsive
                            striped
                            highlightOnHover
                            pointerOnHover
                            noHeader
                        />
                    </div>
                </div>
            </div>
            <UpdateBrandModal
                getBrand={fetchBrands}
                id={selectedBrand}
                open={updateModalOpen}
                onHide={() => setUpdateModalOpen(false)}
                selectedUom={selectedUom}
            />
            <CreateBrandModal
                isOpen={createModalOpen}
                toggle={() => setCreateModalOpen(!createModalOpen)}
                fetchBrands={fetchBrands}
            />
        </>
    );
};

export default Brand;
