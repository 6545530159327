import React, { useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import classNames from 'classnames';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PassportSelection from './PassportSelection';
import EndProductStepOne from './EndProductStepOne';
import EndProductStepThree from './EndProductStepThree';
import EndProductStepFour from './EndProductStepFour';
import EndProductStepFive from './EndProductStepFive';
import EndProductStepSix from './EndProductStepSix';
import EndProductStepSeven from './EndProductStepSeven';
import EndProductStepEith from './EndProductStepEith';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthConext';


const steps = [
  'Basic information',
  'Materials',
  'Impact',
  'Manufacturer',
  'Supplier Entity',
  'Retail Entity',        // Add this
  'Logistics and distribution',       // Add this
];

const CustomStepper = ({handledirct, activeStep, handleNext, handleBack, handleReset }) => {

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate('/login');
  //   }
  // }, [isAuthenticated, navigate]);

  const getStepContent = (step) => {
    switch (step) {
    
      case 0:
        return <EndProductStepOne handleNext={handleNext} />;
      case 1:
        return <EndProductStepThree handleNext={handleNext} />;
      case 2:
        return <EndProductStepFour handleNext={handleNext} />;
      case 3:
        return <EndProductStepFive handleNext={handleNext} />;
      case 4:
        return <EndProductStepSix handleNext={handleNext} />;
      case 5:
        return <EndProductStepEith handleNext={handleNext}/>; // Add this
        
        case 6:
          return <EndProductStepSeven handleNext={handleNext} />;
      default:
        return 'Unknown step';
    }
  };

  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
      marginLeft: 10,
      marginRight: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#22C55E',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#22C55E',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 4,
      borderRadius: 1,
    },
  }));



  return (
    <div className="w-full ">
      <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />} className="w-full">
        {steps.map((label, index) => (
          <Step key={label} className="relative">
            <StepLabel
              StepIconComponent={(props) => (
                <div
                onClick={()=>{handledirct(index)}}
                  className={`cursor-pointer flex items-center justify-center w-12 h-12 rounded-full text-white ${
                    props.active
                      ? 'bg-purple-600'
                      : props.completed
                      ? 'bg-green-500'
                      : 'bg-gray-400'
                  }`}
                >
                  {index + 1}
                </div>
              )}
            >
              {label}
            </StepLabel>
            {index < steps.length - 1 && (
              <div
                className={classNames(
                  'absolute top-6 left-1/2 transform -translate-x-1/2 h-5',
                  {
                    'bg-purple-600 border-w-5': activeStep > index,
                    'bg-gray-300 h-6 border-t-4': activeStep <= index,
                  }
                )}
                style={{
                  height: '5px',
                }}
              />
            )}
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Box sx={{ my: 2 }}>All steps completed - you&apos;re finished</Box>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Box sx={{ my: 2 }}>{getStepContent(activeStep)}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {/* <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button> */}
              <Box sx={{ flex: '1 1 auto' }} />
              {/* <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button> */}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomStepper;
