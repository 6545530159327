import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Chip, OutlinedInput, FormHelperText, Box, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataService from '../services/requestApi'
import { useAuth } from '../contexts/AuthConext';
const MultiSelectComponent = ({selectedKeys,setSelectedKeys}) => {
//   const [selectedKeys, setSelectedKeys] = useState([]);
// console.log(selectedKeys)
  const [open, setOpen] = useState(false);
  // const options = ['Metals', 'Biogenics', 'Plastics', 'Glass', 'Paper'];
  const [options , setOptions] = useState([])
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedKeys(typeof value === 'string' ? value.split(',') : value);
    setOpen(false); // Close the dropdown menu after each selection
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {saasId
  } = useAuth(); 

  const GetTpyeofMaterials=async ()=>{
    try {
      const response = await DataService.GetTypeeofMaterial(saasId)
      console.log(response)
      setOptions(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    GetTpyeofMaterials()
  }, [])
  
  const handleDelete = (valueToDelete) => {
    // console.log(valueToDelete, selectedKeys)
    handleClose()
    setSelectedKeys((prevSelected) =>
      prevSelected?.filter((value) => value !== valueToDelete)
    );
  };
  return (
    <FormControl fullWidth variant="outlined" >
      <InputLabel id="multi-select-label">Dominant material group(s)</InputLabel>
      <Select
      required
        labelId="multi-select-label"
        multiple
        value={selectedKeys}
        onChange={handleChange}
        input={<OutlinedInput label="Dominant material group(s)" />}
        renderValue={(selected) => (
          <Stack direction="row" spacing={1}>
      {selected.map((value) => (<Chip style={{zIndex:"99999"}} key={value} label={value} onClick={()=>handleDelete(value)} onDelete={()=>handleDelete(value)} />))}
    </Stack>
          // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          //   {selected.map((value) => (
          //      <Chip
          //      key={value}
          //      label={value}
          //      onDelete={(e) => {
          //       console.log(e);
          //        e.stopPropagation(); // Prevent the Select dropdown from opening
          //        handleDelete(value); // Call handleDelete without passing event
          //      }}
          //    />
              
          //   ))}
          // </Box>
        )}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option} 
           
          </MenuItem>
        ))}
      </Select>
      {selectedKeys.length === 0 && (
        <FormHelperText>This field is required.</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiSelectComponent;
