import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';

const CreateWum = ({ isOpen, toggle, fetchgetUomList, selectedUom }) => {
  const [uomName, setUomName] = useState('');
  const [loading, setLoading] = useState(false);
  const {saasId
  } = useAuth(); 
  useEffect(() => {
    if (selectedUom) {
      setUomName(selectedUom.measure);
    } else {
      setUomName('');
    }
  }, [selectedUom]);

  const handleUomSave = async () => {
    setLoading(true);
    try {
      if (selectedUom) {
        // Update existing UOM
        const response = await DataService.updateWum(selectedUom.id, { name: uomName });
        if (response.data.status) {
          fetchgetUomList();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Unit of Measure updated successfully!',
          });
        }
      } else {
        // Add new UOM
        const response = await DataService.addWum({saasId:saasId, name: uomName });
        if (response.data.status) {
          fetchgetUomList();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Unit of Measure added successfully!',
          });
        } else {
          // Show error message in Swal if status is false
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message || 'An error occurred',
          });
        }
      }
      toggle();
    } catch (error) {
      const errorMessage = error.response?.data?.message || ' Please try again later.';
    
      // Show error message in Swal
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">
        {selectedUom ? 'Edit Unit of Measure' : ' Add Unit of Measure'}
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Unit of Measure Name"
          value={uomName}
          onChange={(e) => setUomName(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleUomSave}
          disabled={loading}
        >
          {loading ? (selectedUom ? 'Updating...' : 'Adding...') : selectedUom ? 'Update Unit of Measure' : 'Add Unit of Measure'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateWum;
