import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, InputAdornment, IconButton, Button, Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import PassportSummary from '../components/PassportSummary/PassportSummary';
import { useAuth } from '../contexts/AuthConext';
import DataService from '../services/requestApi';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import { Image } from 'antd';
import { FormLabel } from 'react-bootstrap';
import Swal from 'sweetalert2';

const UnifiedSupplierManufacturer = ({ handleNext }) => {
  const {Endprogress,step2progress,step3progress, saasId,  savestep4Progress
  } = useAuth(); 
  const productId = localStorage.getItem('productId');
    
  const [formData, setFormData] = useState({
    saasId:saasId,
    productId:productId,
    supplierManufactureId: '',
    name: '',
    flatNo: '',

    phone: '',
    phone2: '',
    email: '',
    email1: '',
    certifications: [],

    manufactureDate: "",
    number: "",
    supplierDate: "",
    supplierLocation: "",
    paymentTerms: "",
    deliveryTerms: " ",
    warranty: " ",
    manufactureCost: "",
    supplierPrice: ""
  });
  const [manufacturers, setManufacturers] = useState([]);
 
 

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await DataService.getUnifiedSM(saasId);
        setManufacturers(response.data.data);
      } catch (error) {
        console.error('Failed to fetch manufacturers', error);
      }
    };
    fetchManufacturers();
  }, []);


  const handleManufacturerNameChange = (e) => {
    const manufacturerName = e.target.value;
    const selectedManufacturer = manufacturers.find((m) => m.name === manufacturerName);
  
    if (selectedManufacturer) {
      setFormData({
        ...formData,
        supplierManufactureId: selectedManufacturer.id,
        name: selectedManufacturer.name,
        flatNo: selectedManufacturer.location,
        phone: selectedManufacturer.contactInfo.phone,
        phone2: selectedManufacturer.contactInfo.phone2,
        email: selectedManufacturer.contactInfo.email,
        email1: selectedManufacturer.contactInfo.email1,
        certificate1: selectedManufacturer.certificate1,
        certificate2: selectedManufacturer.certificate2,
        certificate3: selectedManufacturer.certificate3,
        certificate4: selectedManufacturer.certificate4,
        certificate5: selectedManufacturer.certificate5,
      });
    } else {
      setFormData({ ...formData, name: manufacturerName });
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };








  const handlelinkmanufactureProduct = async (e) => {
    e.preventDefault();
    try {
      const { supplierManufactureId } = formData;

      if (!productId || !supplierManufactureId) {
        Swal.fire({
          title: 'Error',
          text: 'Product or supplierManufacture ID is missing',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const { phone, phone2 } = formData;

      // Check if 'phone' is numeric
      if (!/^\d+$/.test(phone)) {
        Swal.fire({
          title: 'Error',
          text: 'Phone number must be numeric',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Check if 'phone' is exactly 10 digits
      if (phone.length !== 10) {
        Swal.fire({
          title: 'Error',
          text: 'Phone number must be exactly 10 digits',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Check if 'phone2' is numeric
      if (!/^\d+$/.test(phone2)) {
        Swal.fire({
          title: 'Error',
          text: 'Alternate phone number must be numeric',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Check if 'phone2' is exactly 10 digits
      if (phone2.length !== 10) {
        Swal.fire({
          title: 'Error',
          text: 'Alternate phone number must be exactly 10 digits',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const response = await DataService.UnifiedSupplierManufacturer(formData);
     const id = response.data.data.id
      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Product linked to Manufacturer successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          handleNext();
          localStorage.setItem("UnifiedSM_id",id)
        });
      }
    } catch (error) {
      console.error("API call failed", error);
      const errorMessage = error.response.data.message;
      // Show error Swal alert with the extracted message
       await Swal.fire({
         icon: 'error',
         title: 'Product Addition Failed',
         text: errorMessage,
       });
    }
  };




    const scrollableContainerStyle = {
        height: "80vh",  // Takes 80% of the viewport height
        maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
        overflowY: "auto",
        fontFamily: "Inter",
        padding: "16px", // Optional: Add some padding if needed
        boxSizing: "border-box", // Ensure padding is included in height calculation
      };
      
      // In case you are using flexbox for your layout
      const containerWrapperStyle = {
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Full height of the viewport
      };

      
      const stepsProgress = [
        { name: 'UnifiedSupplierManufacturer', stepNumber: 1, totalSteps: 6, progress: 100 }, // Initial progress for step 1
      ];
  return (
    <>
  <div style={containerWrapperStyle}>
  <Grid
        container
        spacing={2}
        className="scrollable-container p-3"
        style={scrollableContainerStyle}
      >
        <Grid md={8} container alignItems="center" spacing={2}>
    

      <form className="space-y-8">
      <Box p={3}>
      <Typography
                style={{ color: "GrayText" }}
                variant="h6"
                component="h6"
                mb={2}
              >
                Step 4/6
              </Typography>
              <Typography className="fw-bold" variant="h6">
              Unified Supplier Manufacturer
              </Typography>
              <Typography mb={3}>
              Unified Supplier Manufacturer Entity by filling in basic information.
              </Typography>
      <Box
                mb={4}
                p={2}
                borderRadius={2}
                border="1px solid #e0e0e0"
                boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                width="100%"
                height="fit-content"
                bgcolor="white"
              >
      <Typography variant="h5" className="mb-4 font-bold">
        Unified Supplier-Manufacturer Entity
      </Typography>
        {/* Entity Information */}
        <Typography variant="h6" className="font-bold mb-4 mt-3">
          Entity Information
        </Typography>
  
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined" className="mb-4">
  {/* <InputLabel id="manufacturer-select-label">Search Unified Supplier-Manufacturer Name</InputLabel> */}
  <TextField
    label="Search Unified Supplier-Manufacturer Name"
    variant="outlined"
    value={formData.name}
    onChange={handleManufacturerNameChange}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <FaSearch />
        </InputAdornment>
      ),
    }}
    select
  >
    {manufacturers.map((manufacturer) => (
      <MenuItem key={manufacturer.id} value={manufacturer.name}>
        {manufacturer.name}
      </MenuItem>
    ))}
  </TextField>
</FormControl>

          </Grid>
          <Grid item xs={12} md={6}>
            <TextField disabled fullWidth label="Entity ID/Code"   value={formData.supplierManufactureId}
                    onChange={handleInputChange} variant="outlined"  />
          </Grid>
          <Grid item xs={12}>
          <TextField 
          disabled
    fullWidth 
    label="Location" 
    name="flatNo" 
    value={formData.flatNo || ''}  // autofill location
    onChange={handleInputChange}
    variant="outlined" 
  />
          </Grid>
        </Grid>
    
        {/* Contact Information */}
        <Typography variant="h6" className="font-bold mb-4 mt-3">
          Contact Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField disabled fullWidth  value={formData.phone || ''}  // autofill phone
    onChange={handleInputChange}  label="Mobile No. (Primary)" variant="outlined" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField disabled fullWidth 
             value={formData.phone2 || ''}  // autofill phone
             onChange={handleInputChange}  
             label="Mobile No. (Secondary)" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <TextField 
            disabled
             value={formData.email || ''}  // autofill phone
             onChange={handleInputChange} 
            fullWidth label="Email (Primary)" defaultValue="john.doe@gmail.com" variant="outlined" />
          </Grid>

          <Grid item xs={6}>
            <TextField 
            disabled
             value={formData.email1 || ''}  // autofill phone
             onChange={handleInputChange} 
            fullWidth label="Email (Secondary)" defaultValue="john.doe@gmail.com" variant="outlined" />
          </Grid>
        </Grid>

        {/* Product Origin Details */}
        <Typography variant="h6" className="font-bold mb-4 mt-3">
          Product Origin Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
          <TextField
          required
                      fullWidth
                      type="date"
                      label="Manufacturing Date"
                      variant="outlined"
                      name="manufactureDate"
                      value={formData.manufactureDate}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
            required
            name="number"
               value={formData.number}
               onChange={handleInputChange}
            fullWidth label="Batch/Serial Number" defaultValue="ABC123" variant="outlined" />
          </Grid>
        </Grid>

        {/* Supply Chain Details */}
        <Typography variant="h6" className="font-bold mb-4 mt-3">
          Supply Chain Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
          <TextField
          required
                      fullWidth
                      type="date"
                      label="Supplier Date"
                      variant="outlined"
                      name="supplierDate"
                      value={formData.supplierDate}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
            required
            fullWidth 
            name="supplierLocation"
               value={formData.supplierLocation}
               onChange={handleInputChange}
            label="Supply Location" defaultValue="456 Business Avenue, City, Country" variant="outlined" />
          </Grid>
        </Grid>

     

        {/* Terms and Agreements */}
        <Typography variant="h6" className="font-bold mb-4 mt-3">
          Terms and Agreements
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField 
            required
            value={formData.paymentTerms}
            name="paymentTerms"
            onChange={handleInputChange} fullWidth label="Payment Terms" defaultValue="Net 30" variant="outlined" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
            required
                        name="deliveryTerms"

              value={formData.deliveryTerms}
              onChange={handleInputChange} 
            fullWidth label="Delivery Terms" defaultValue="Standard Delivery" variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <TextField

                        required
                        name="warranty"

                value={formData.warranty}
                onChange={handleInputChange} 
            fullWidth label="Warranty Information" defaultValue="1 Year Warranty" variant="outlined" />
          </Grid>
        </Grid>

        {/* Pricing and Cost */}
        <Typography variant="h6" className="font-bold  mb-4 mt-3">
          Pricing and Cost
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField   
                        required
                        name="manufactureCost"

            value={formData.manufactureCost}
                onChange={handleInputChange}  fullWidth label="Manufacturing Cost" defaultValue="$100" variant="outlined" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
                       required
                        name="supplierPrice"

             value={formData.supplierPrice}
             onChange={handleInputChange}  fullWidth label="Supplier Pricing" defaultValue="$120" variant="outlined" />
          </Grid>
        </Grid>
       
        </Box>

        <Box className="p-3 mt-3 bg-white rounded-lg shadow-md">
              <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6">
                Certifications
              </Typography>
              <FormLabel component="legend">List of certifications held by the manufacturer</FormLabel>
              <Grid container spacing={2}>
             
              <Grid className='' item xs={6} sm={4} md={2}>
  {formData?.certificate1 && (
    <Image src={formData.certificate1} alt="label" className="w-14 h-14" />
  )}
</Grid>
<Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate2 && (
    <Image src={formData.certificate2} alt="label" className="w-14 h-14" />
  )}
  </Grid>
  <Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate3 && (
    <Image src={formData.certificate3} alt="label" className="w-14 h-14" />
  )} </Grid>
<Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate4 && (
    <Image src={formData.certificate4} alt="label" className="w-14 h-14" />
  )} </Grid>
<Grid className='' item xs={6} sm={4} md={2}>
    {formData?.certificate5 && (
    <Image src={formData.certificate5} alt="label" className="w-14 h-14" />
  )}
</Grid>
         
              </Grid>
            </Box>
        <div className='flex justify-center mt-3 mb-5'>
              <Button onClick={handlelinkmanufactureProduct} type='submit' style={{ background: "#4F46E5", padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
                Step 5: Retail Entity
              </Button>
              </div>
       
        </Box>
      </form>
      </Grid>
      <Grid style={{ margin: "3px" }} md={4} container spacing={2}>
        <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />

        </Grid>
      </Grid>

    </div>

    </>
  );
};

export default UnifiedSupplierManufacturer;
