import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import PassportSelection from './components/PassportSelection';
// import EndProductStepOne from './components/EndProductStepOne';
import EndProductStepThree from './components/EndProductStepThree';
import EndProductStepFour from './components/EndProductStepFour';
import CustomStepper from './components/CustomStepper';
import EndProductStepFive from './components/EndProductStepFive';
import Product from './components/Product';
import "./index.css"
import ProductTabs from './components/Productpages/ProductTabs';
import Register from './LoginRegister/Register';
import Login from './LoginRegister/Login';
import { AuthProvider } from './contexts/AuthConext';
import { CartProvider } from './contexts/CartContext';
import ProductListPage from './components/Productpages/ProductListPage';
import SuplierList from './components/Master/SuplierList';
import MenufactureList from './components/Master/MenufactureList';
import RetailerList from './components/Master/RetailerList';
import ProtectedRoute from './contexts/ProtectedRoute';
import Profile from './Profile/Profile';
import NotificationList from './Profile/NotificationList';
import Datamanagement from './components/Master/Datamanagement';
import AllProductInfo from './ProductInfo/AllProductInfo';
import AllCustomer from './Customers/AllCustomer';
import PermissionManagement from './Customers/PermissionManagement';

function App() {
  const [isExtend, setIsExtend] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  
  const handledirct = (step) => {
    setActiveStep(step);
  };

  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <Routes>
            {/* Publicly accessible routes */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/productDetails/:productId" element={<AllProductInfo />} />  {/* Public route */}

            <Route 
              path="/*" 
              element={
                <ProtectedRoute>
                  <Layout handleReset={handleReset} isExtend={isExtend} setPageLayout={setIsExtend}>
                    <Routes>
                      <Route path="/" element={<PassportSelection handleNext={handleNext} />} />
                      <Route path="/CustomStepper" element={<CustomStepper handledirct={handledirct} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} handleReset={handleReset} />} />
                      <Route path="/EndProductStepThreeSelect" element={<EndProductStepThree />} />
                      <Route path="/EndProductStepFour" element={<EndProductStepFour />} />
                      <Route path="/EndProductStepFive" element={<EndProductStepFive />} />
                      <Route path="/Product" element={<ProductListPage />} />
                      <Route path="/ProductTabs" element={<ProductTabs />} />
                      <Route path="/AddSupplier" element={<SuplierList />} />
                      <Route path="/AddManufacture" element={<MenufactureList />} />
                      <Route path="/AddRetailer" element={<RetailerList />} />
                      <Route path="/Datamanagement" element={<Datamanagement />} />

                      <Route path="/Profile" element={<Profile />} />
                      <Route path="/NotificationList" element={<NotificationList />} />

                      <Route path="/AllUser" element={<AllCustomer />} />
                      <Route path="/PermissionManagement" element={<PermissionManagement />} />


                      <Route path="*" element={<Navigate to="/" replace />} />  
                    </Routes>
                  </Layout>
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
