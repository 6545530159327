import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';

const UpdateRetailerModal = ({ open, onHide, row, fetchRetailers }) => {
  const initialState = {
    
    name: "",
    phone: "",
    phone2: "",
    email: "",
    email1: "",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: ""
    },
    productList: null // Setting productList to null
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (row) {
      setFormData({
        name: row.name || "",
        phone: row.contactInfo?.phone || "",
        phone2: row.contactInfo?.phone2 || "",
        email: row.contactInfo?.email || "",
        email1: row.contactInfo?.email1 || "",
        address: {
          flatNo: row.address?.flatNo || "",
          cityArea: row.address?.cityArea || "",
          country: row.address?.country || "",
          state: row.address?.state || "",
          district: row.address?.district || "",
          pincode: row.address?.pincode || ""
        },
        productList: null // Ensuring productList is sent as null
      });
    }
  }, [row]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      setFormData({
        ...formData,
        address: { ...formData.address, [addressField]: value }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "name", "phone", "email", 
      "address.flatNo", "address.cityArea", "address.country",
      "address.state", "address.district", "address.pincode"
    ];

    for (let field of requiredFields) {
      let value = formData;
      for (let key of field.split(".")) {
        value = value[key];
      }
      if (!value.trim()) {
        return field;
      }
    }
    return null;
  };

  const handleSubmit = async () => {
    const missingField = validateForm();

    if (missingField) {
      Swal.fire({
        title: "Missing Information",
        text: `Please fill in the ${missingField.replace("address.", "")} field.`,
        icon: "warning",
      });
      return;
    }

    const dataToSend = {
      name: formData.name,
      contactInfo: {
        phone: formData.phone,
        phone2: formData.phone2,
        email: formData.email,
        email1: formData.email1,
      },
      address: {
        flatNo: formData.address.flatNo,
        cityArea: formData.address.cityArea,
        country: formData.address.country,
        state: formData.address.state,
        district: formData.address.district,
        pincode: formData.address.pincode,
      },
      productList: null
    };

    try {
      await DataService.updateRetailer(row.retailerId, dataToSend);
      fetchRetailers(1)
      Swal.fire({
        title: "Retailer Updated",
        text: "Retailer details have been successfully updated.",
        icon: "success",
      }).then(() => {
        onHide();
      });
    } catch (error) {
     // Check if the API response contains a message field
     const errorMessage = error?.response?.data?.message || "Failed to update retailer details. Please try again later.";

     Swal.fire({
       title: "Error",
       text: errorMessage, // Displaying the error message from the API
       icon: "error",
     });
    }
  };

  return (
    <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
      <ModalHeader toggle={onHide}>
        <div className="d-flex align-items-center gap-4">
          <BsArrowLeft onClick={onHide} className="mouse-pointer" />
          <p className="fw-bold">Update Retailer</p>
        </div>
      </ModalHeader>
      <ModalBody>
        <Container fluid>
          <Card>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile No. (Primary)"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile No. (Secondary)"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email 2"
                    name="email1"
                    value={formData.email1}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    name="address.flatNo"
                    value={formData.address.flatNo}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City Area"
                    name="address.cityArea"
                    value={formData.address.cityArea}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    name="address.country"
                    value={formData.address.country}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="State"
                    name="address.state"
                    value={formData.address.state}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="District"
                    name="address.district"
                    value={formData.address.district}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Pincode"
                    name="address.pincode"
                    value={formData.address.pincode}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Container>
        <div className="flex justify-center space-x-8 col-span-full mt-2">
          <Button
            style={{
              borderRadius: '20px',
              padding: "10px",
              width: "200px"
            }}
            variant="outlined"
            color="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            style={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '12',
              background: '#2D059C',
              border: 'none',
              borderRadius: '20px',
              '&:hover': { background: '#2D059C' },
              background: "#4F46E5",
              padding: "10px",
              width: "200px"
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateRetailerModal;
