import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton,Select, MenuItem } from '@mui/material';
import QRCode from 'react-qr-code';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import DataService from '../../services/requestApi';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useAuth } from '../../contexts/AuthConext';
import Swal from 'sweetalert2';
const scrollableContainerStyle = {
  height: "80vh",
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px",
  boxSizing: "border-box",
};

const RetailEntity = ({ retailerData,productData,fetchSupplierChainData }) => {
  const qrRef = useRef();
  const cardRef = useRef(); // Add a reference for the card to download as PDF

  const {saasId
  } = useAuth(); 

  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };

  const downloadPDF = () => {
    const input = cardRef.current;
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('retailer-details.pdf');
      })
      .catch((error) => {
        console.error('Failed to generate PDF:', error);
      });
  };

  const [products, setProducts] = useState([
    { name: "Product Name", quantity: 5 },
    { name: "Product Name", quantity: 10 },
    { name: "Product Name", quantity: 14 },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEditProduct = (index) => {
    console.log("Edit product at index:", index);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 // Modal state for edit functionality
 const [modalOpen, setModalOpen] = useState(false);
 const [selectedOption, setSelectedOption] = useState('');

  const handleEditClick = () => {
    setModalOpen(true); // Open the modal on edit button click
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value); // Handle select option change
  };




  const [retailers, setRetailers] = useState([]);

  const fetchRetailers = async ( search = '') => {
    try {

     const response = await DataService.getRetailerList(saasId);
      setRetailers(response.data.data);
  
    } catch (error) {
      console.error('Failed to fetch retailers', error);
    }
  };
  useEffect(() => {
    fetchRetailers();
  }, []);



  const handleupdateRetailerSave = async () => {
    try {
      if (selectedOption) {
        const response = await DataService.updateRetailerlink(productData?.id, selectedOption); // Use selectedOption for retailerId
   
        if (response.data.status) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Retailer updated successfully!',
          });
          fetchSupplierChainData()
          handleCloseModal()
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select a retailer before saving.',
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };


  return (
    <>
    <Container maxWidth="lg" className="scrollable-container" style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} ref={cardRef}>
          <Card className="p-10" > {/* Add ref to this Card */}
            <Grid container spacing={2}>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Retailer ID
                </Typography>
                <Typography variant="body1">
                  {retailerData?.retailerId}
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  Retailer's name
                </Typography>
                <Typography variant="body1">
                  {retailerData?.name}
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography className='p-2' variant="body2" color="textSecondary">
                  Retailer mobile no.
                </Typography>
                <Typography className='p-2' variant="body1">
                  (+91) {retailerData?.contactInfo?.phone} (Primary)
                </Typography>
                <Typography className='p-2' variant="body1">
                  (+91) {retailerData?.contactInfo?.phone2} (Secondary)
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12} sm={6}>
                <Typography className='p-2' variant="body2" color="textSecondary">
                  Retailer E-mail
                </Typography>
                <Typography className='p-2' variant="body1">
                  {retailerData?.contactInfo?.email} (Primary)
                </Typography>
                <Typography className='p-2' variant="body1">
                  {retailerData?.contactInfo?.phone2} (Secondary)
                </Typography>
              </Grid>
              <Grid className='p-4' item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Retailer Address
                </Typography>
                <Typography variant="body1">
                  {retailerData?.address?.flatNo},{retailerData?.address?.cityArea},{retailerData?.address?.district},{retailerData?.address?.state},{retailerData?.address?.country}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="mb-1">
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="body1">Edit Passport</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton onClick={handleEditClick} aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container alignItems="center" marginTop={2}>
                <Grid item xs={10}>
                  <Typography variant="body1">Download PDF</Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton aria-label="download" onClick={downloadPDF}> {/* Add PDF Download */}
                    <DownloadIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className="p-3 mt-2">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value="https://example.com" style={{ height: "100px" }} />
            </div>
            <div className="flex justify-center">
              <Button
                variant="contained"
                color="primary"
                className="h-[35px] w-[235px]"
                style={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '12px',
                  background: '#ef613d',
                  border: 'none',
                  borderRadius: '20px',
                  '&:hover': { background: '#ef613d' },
                  width: 235,
                  height: 35,
                }}
                onClick={downloadQRCode}
              >
                Download QRCode Button
              </Button>
            </div>
            <div className="flex justify-center">
              <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>

     {/* Modal for editing */}
     <Modal isOpen={modalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Edit Retailer Details</ModalHeader>
                <ModalBody>
                  <Typography>Select Retailer Type:</Typography>
                  <Select value={selectedOption} onChange={handleOptionChange} fullWidth>
            {retailers.map((retailer) => (
              <MenuItem key={retailer.retailerId} value={retailer.retailerId}>
                {retailer.name}
              </MenuItem>
            ))}
          </Select>
                </ModalBody>
                <ModalFooter className='flex justify-end gap-3'>
                  <Button  Button     style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary" onClick={handleupdateRetailerSave}>
                    Save
                  </Button>
                  <Button    style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

    </>
  );
};

export default RetailEntity;
