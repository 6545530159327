import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import QRCode from 'react-qr-code'; 
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';

import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Tabs, Tab } from 'react-bootstrap';

import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Chart from './Productpages/Chart';
import ImpactCard from './Productpages/ImpactCard';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import EditProductModal from './Modal/EditProductModal';
import { useAuth } from '../contexts/AuthConext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './Productpages/Pdf/PdfDocument';


const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};

const scrollTable = {
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};
// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};


const Product = ({productData,impactEntity,supplyChainEntity,retailerData,unifiedSupplierManufacturerData,fetchSupplierChainData,}) => {
  const qrRef = useRef();
  const {saasId
  } = useAuth();

  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };
    const [key, setKey] = useState('biogenics');

    const cardRef = useRef(); // Add a reference for the card to download as PDF
    const downloadPDF = () => {  
      const input = cardRef.current;  
    
      // Ensure the image is fully loaded before capturing the canvas  
      const images = input.querySelectorAll('img');  
      const imagePromises = Array.from(images).map((img) => {  
        return new Promise((resolve) => {  
          if (img.complete) {  
            resolve();  
          } else {  
            img.onload = resolve;  
            img.onerror = resolve;  
          }  
        });  
      });  
    
      Promise.all(imagePromises).then(() => {  
        // After all images are loaded, render the canvas and generate the PDF  
        html2canvas(input, {  
          useCORS: true, // Enable CORS to allow cross-origin images  
          allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
        })  
          .then((canvas) => {  
            const imgData = canvas.toDataURL('image/png');  
            const pdf = new jsPDF();  
            const imgProps = pdf.getImageProperties(imgData);  
            const pdfWidth = pdf.internal.pageSize.getWidth();  
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  
    
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
            pdf.save('retailer-details.pdf');  
          })  
          .catch((error) => {  
            console.error('Failed to generate PDF:', error);  
          });  
      });  
    };
    

    // product page
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);


  const handleEditClick = () => {
    setFormValues(productData);
    setModalOpen(true);
  };

  useEffect(() => {
 console.log("productData",productData)
 console.log("saasId",saasId)

  }, [])
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('basicInfo.')) {
      const fieldName = name.replace('basicInfo.', '');
      
      setFormValues({
        ...formValues,
        saasId:saasId || 101,
        basicInfo: {
          ...formValues.basicInfo,
          [fieldName]: value,
        },
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSaveClick = async () => {
    try {
      const response = await DataService.updateProduct(productData?.productId, formValues);
      const productId = response.data.data.productId;
      if (response.data.status) {
        Swal.fire({
          title: 'Success!',
          text: 'Product updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        await uploadProductImage(productId);
        setModalOpen(false);
        fetchSupplierChainData()
      } else {
        console.error('Failed to update product:', response.data.message);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.response?.data?.error || 'Failed to update product.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.error('Failed to update product:', error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const response = await DataService.getCategoryList(saasId);
      if (response.data.status) {
        setCategoryList(response.data.data);
      } else {
        console.error('Failed to fetch category list:', response.data.message);
      }
    } catch (error) {
      console.error('Failed to fetch category list:', error);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);



  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadProductImage = async (productId) => {
    if (!selectedFile) return;
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      // Log the FormData object to check if the file is appended correctly
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      const imageResponse = await DataService.uploadProductImage(formData, productId);
  
      console.log('Image uploaded successfully:', imageResponse.data);
  
      Swal.fire({
        icon: 'success',
        title: 'Image Uploaded Successfully',
        text: 'The product image has been uploaded successfully!',
      });
  
    } catch (imageError) {
      console.error('Error uploading image:', imageError);
  
      Swal.fire({
        icon: 'error',
        title: 'Image Upload Failed',
        text: 'There was an error uploading the product image.',
      });
    }
  };
  
  return (
    <div style={containerWrapperStyle}>

    <Container maxWidth="lg"   className="scrollable-container " style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} >
          <Card className="p-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img src={productData?.image} alt="Wooden Table"  className="w-full" crossOrigin="anonymous" />
              </Grid>
              <Grid item xs={12} sm={8}>
                <CardContent>
                <Grid container spacing={2}>
                  <Typography variant="h5" className="fw-bold">{productData?.name}</Typography>
            <Button  className="h-[35px] w-[125px] mx-3 "
                    style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: '#4F46E5',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#4F46E5' },
                      width: 125,
                      height: 35,
                    }}>End product</Button>
            </Grid >
                  <Typography variant="body1" className="mb-4 mt-4">
                  {productData?.description}
                  </Typography>
          
                </CardContent>
              </Grid>
            </Grid>
            <Grid container spacing={1} className='mt-1'>
                    <Grid item xs={4}><Typography variant="body2">Defined use period (years)</Typography></Grid>
                    <Grid item xs={4}><Typography variant="body2">Dominant material group (s)</Typography></Grid>
                    <Grid item xs={4}><Typography variant="body2">Next use application</Typography></Grid>

                    <Grid className='mt-2' item xs={4}><Typography variant="body1">{productData?.basicInfo?.usePeriod}</Typography></Grid>
                    <Grid className='mt-2' item xs={4}><Typography variant="body1">{productData?.materials}</Typography></Grid>
                    <Grid className='mt-2' item xs={4}><Typography variant="body1">{productData?.basicInfo?.useApplication}</Typography></Grid>

                    <Grid className='mt-4' item xs={3}><Typography variant="body2">End of use Treatment</Typography></Grid>
                    <Grid className='mt-4' item xs={3}><Typography variant="body2">Weight</Typography></Grid>
                    <Grid className='mt-4' item xs={3}><Typography variant="body2">Quantity</Typography></Grid>
                    <Grid className='mt-4' item xs={3}><Typography variant="body2">MSDS</Typography></Grid>

                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.usedTreatment}</Typography></Grid>
                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.weight}</Typography></Grid>
                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.quantity} Items</Typography></Grid>
                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.msds}</Typography></Grid>
           
                  </Grid>
          </Card>
          <Card className="mt-3 p-2">
          <Grid container spacing={1}>
          <Grid className='mt-2' item xs={1.5}>
            <div style={{borderRadius:"100%", width:"65px", border:"2px"}} className="font-bold  border flex justify-center p-3">
            <Typography variant="h6" >{productData?.progressBar}%</Typography>
            </div>

            </Grid>
          <Grid className='mt-2' item xs={7.5}>
            <Typography variant="body1" className="font-bold mb-4">
                This passport is on the right track. Fulfill extra information to increase your passport score.</Typography>
           
            </Grid>
             <Grid className='' item xs={3}>
          <Grid container spacing={1}>

            </Grid>
            </Grid>

            </Grid>
          </Card>
        
        </Grid>
        <Grid item xs={12} md={4}>
        <Card className=" mb-1">
        <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="body1">Edit Passport</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={handleEditClick} aria-label="edit">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" marginTop={2}>
          <Grid item xs={10}>
            <Typography variant="body1">Download PDF</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
  <PDFDownloadLink  
    document={  
      <PdfDocument  
        productData={productData}  
        impactEntity={impactEntity}  
        supplyChainEntity={supplyChainEntity}  
        retailerData={retailerData}  
        unifiedSupplierManufacturerData={unifiedSupplierManufacturerData}  
      />
    }  
    fileName="product-data.pdf"  
    style={{ textDecoration: 'none' }} // Optional: To remove underline from link
  >  
    {({ loading }) => (
      <IconButton aria-label="download">
        <DownloadIcon />
        {/* {loading ? 'Preparing document...' : 'Download PDF'} */}
      </IconButton>
    )}
  </PDFDownloadLink>  
</Grid>

        </Grid>
      </CardContent>
          </Card>
          <Card className="p-14 mt-4">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value={`https://onescan.digital/productDetails/${productData?.productId}`} style={{height:"100px"}}/>
            </div>
            <div className="flex justify-center ">
            <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 235,
               height: 35,
             }}
             onClick={downloadQRCode}
            >Download QRCode Button</Button>
            
            </div>
            <div className="flex justify-center ">
            <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            
            </div>
          </Card>
        </Grid>
      </Grid>

           
    </Container>
    <EditProductModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleSaveClick={handleSaveClick}
        categoryList={categoryList}
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
      />
    </div>
  );
};

export default Product;
