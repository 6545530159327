import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MultiSelectComponent from './MultiSelectComponent';
import MatrialSelecteComonent from './MatrialSelecteComonent';
import DataService from '../services/requestApi';
import { useCart } from '../contexts/CartContext';
import PassportSummary from './PassportSummary/PassportSummary';
import { useAuth } from '../contexts/AuthConext';

const EndProductStepThree = ({ handleNext }) => {
  const { Endprogress ,step2progress,savestep2Progress} = useAuth();

  const [materialGroup, setMaterialGroup] = useState('');
  const [selectedTab, setSelectedTab] = useState([]);
  const [SelectedMatarial, setSelectedMatrial] = useState([]);
  const [addedMaterial, setAddedmaterial] = useState([]);
  const [tabFields, setTabFields] = useState({});
  const productId = localStorage.getItem("productId");
  const navigate = useNavigate();
  const { showAlert } = useCart();

  const handleChange = (event) => {
    const value = event.target.value;
    setMaterialGroup(value);

    // Set the selected tab based on the selected material group
    if (value === 10) {
      setSelectedTab(['Biogenics Metals']);
    } else if (value === 20) {
      setSelectedTab(['Wood']);
    }
  };

  const handleTabFieldChange = (tab, field, value) => {
    setTabFields((prevFields) => ({
      ...prevFields,
      [tab]: {
        ...prevFields[tab],
        [field]: value
      }
    }));

    // Mark tab as 100% complete if the field is filled
    if (field === 'percentage' && value !== '') {
      setTabFields(prev => ({
        ...prev,
        [tab]: {
          ...prev[tab],
          percentage: value,
          isComplete: true // Mark as complete
        }
      }));
    }
  };

  const AddMaterail = (selectedType, selectedmaterial, percentage) => {
    const checkAdded = addedMaterial.filter((el) => el.materialName === selectedmaterial && el.materialType === selectedType);
    if (checkAdded.length > 0) {
      setSelectedMatrial([]);
      showAlert("Material already added!", "error");
      return;
    }
    const data = {
      "materialType": selectedType,
      "materialName": selectedmaterial,
      "persentOfProduct": percentage
    };
    setAddedmaterial(prev => Array.isArray(prev) ? prev.concat(data) : [data]);
    setSelectedMatrial([]);
    showAlert(`${selectedmaterial} added successfully`, "success");
  };
  
  const deleteAddmaterial = (item) => {
    const updatedAddedMaterial = addedMaterial.filter(
      (el) => !(el.materialName === item.materialName && el.materialType === item.materialType)
    );
    setAddedmaterial(updatedAddedMaterial);
  };


  const calculateProgress = () => {
    let totalFields = 0;
    let filledFields = 0;

    selectedTab.forEach((tab) => {
      if (tabFields[tab]) {
        totalFields += 2; // assuming each tab has 2 fields
        filledFields += (tabFields[tab].isComplete ? 2 : Object.values(tabFields[tab]).filter((value) => value !== '').length);
      }
    });

    return totalFields === 0 ? 0 : (filledFields / totalFields) * 100;
  };

  const scrollableContainerStyle = {
    height: "80vh",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px",
    boxSizing: "border-box",
  };

  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  };

  const progress = calculateProgress();

  const stepsProgress = [
    { name: 'Basic Information', stepNumber: 1, totalSteps: 7, progress: Endprogress },
    { name: 'Materials', stepNumber: 2, totalSteps: 7, progress: progress }, // Example percentage for Materials
  ];


  useEffect(() => {
    savestep2Progress(progress); // Update the progress in AuthContext
  }, [progress, step2progress]);

  const AddMaterial = async (e) => {
    e.preventDefault();
    try {
      const response = await DataService.AddMaterialDetail(productId, addedMaterial);
      if (response.status) {
        handleNext();
      }
    } catch (error) {
      console.log(error);
      showAlert("Api Fail Something is Wrong", "error");
    }
  };

  return (
    <>
      <div style={containerWrapperStyle}>
        <Grid container spacing={2} className="scrollable-container p-3" style={scrollableContainerStyle}>
          <Grid md={8} container alignItems="center" spacing={2}>
            <form action="" onSubmit={AddMaterial}>
              <Box p={3}>
                <Typography style={{ color: "GrayText" }} variant="h6" component="h6" mb={2} gutterBottom>Step 2/7</Typography>
                <Typography className='fw-bold' style={{ fontFamily: "'Poppins', 'Georgia', 'serif'" }} variant="h6">Materials</Typography>
                <Typography>Lay out the composition of your item</Typography>
                <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fff' }}>
                  <FormControl component="fieldset" sx={{ margin: 2 }}>
                    <Typography variant="h6">Select material source</Typography>
                    <RadioGroup
                      aria-label="material-source"
                      defaultValue="choose-materials-here"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value="choose-materials-here" control={<Radio />} label="Choose materials here" />
                      <FormControlLabel value="lower-assembly-level" control={<Radio />} label="Use the materials selected from a lower assembly level (products and/or components)" />
                    </RadioGroup>
                  </FormControl>

                  <MultiSelectComponent setSelectedKeys={setSelectedTab} selectedKeys={selectedTab} />
                  {selectedTab.length > 0 &&
                    <Tabs className='fw-bold mt-2' defaultActiveKey={selectedTab[0]} id="uncontrolled-tab-example">
                      {selectedTab.map((el) => (
                        <Tab eventKey={el} title={el} key={el} onClick={() => handleTabFieldChange(el, 'percentage', '100')}>
                          <Paper square>
                            <Box p={2}>
                              <Typography variant="subtitle1" gutterBottom>
                                {el}
                              </Typography>
                              <Box alignItems="center" mb={2}>
                                <MatrialSelecteComonent deleteAddmaterial={deleteAddmaterial} addedMaterial={addedMaterial} AddMaterail={AddMaterail} setAddedmaterial={setAddedmaterial} setSelectedKeys={setSelectedMatrial} selectedKeys={SelectedMatarial} item={el} />
                                {/* <TextField  
                                  label="Percentage of product"
                                  variant="outlined"
                                  type="number"
                                  fullWidth
                                  margin="dense"
                                  value={tabFields[el]?.percentage || ''}
                                  onChange={(e) => handleTabFieldChange(el, 'percentage', e.target.value)}
                                /> */}
                              </Box>
                            </Box>
                          </Paper>
                        </Tab>
                      ))}
                    </Tabs>
                  }
                </Box>

                <div className='flex justify-center mt-3 mb-5'>
                  <Button type='submit' style={{ background: "#4F46E5", padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
                    Step 3: Impact
                  </Button>
                </div>
              </Box>
            </form>
          </Grid>

          <Grid style={{ margin: "3px" }} md={4} container spacing={2}>
            <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EndProductStepThree;
