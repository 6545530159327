import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import { FaCloud, FaTint, FaRecycle, FaBox, FaBolt, FaBullseye,FaIndustry  , FaExclamationTriangle, FaTruck, FaCheckCircle, FaClock,FaBiohazard  } from 'react-icons/fa';
import * as htmlToImage from 'html-to-image';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import QRCode from 'react-qr-code'; 
import { Link } from 'react-router-dom';
import UpdateImpactModal from './UpdateSupplyChain.js/UpdateImpactmodal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SpaIcon from '@mui/icons-material/Spa';
import FactoryIcon from '@mui/icons-material/Factory';

const questions = [
  "Uses less energy.",
  "Lasts longer.",
  "Can be easily repaired.",
  "Parts can be easily disassembled and put to further use.",
  "Can be easily recycled.",
  "Contains more recycled content.",
  "Has a lower carbon and environmental footprint over its lifecycle.",
];

const ImpactCard = ({ impactEntity,productData ,fetchSupplierChainData}) => {
  const [modal, setModal] = useState(false);
  // const [selectedQuestion, setSelectedQuestion] = useState(null);

  // const toggleModal = (index) => {
  //   setSelectedQuestion(index);
  //   setModal(!modal);
  // };

  // Define vibrant colors for each icon
  const metrics = [
    { icon: <SpaIcon style={{color:"#007BFF"}} size={24} color="#007BFF" />, label: "Carbon Footprint", value: `${impactEntity?.carbonFootprint?.totalCarbonFootprint} kg CO2` },
    { icon: <FaTint size={24} color="#17A2B8" />, label: "Water Usage", value: `${impactEntity?.waterUsage?.totalWaterUsage} L` },
    { icon: <FaRecycle size={24} color="#28A745" />, label: "Recyclability", value: `${impactEntity?.recyclability?.totalRecyclability}%` },
    { icon: <FaBox  style={{color:"#FFC107"}} size={24} color="#FFC107" />, label: "Sustainable Materials", value: `${impactEntity?.sustainableMaterials?.totalSustainable}%` },
    { icon: <FaBolt size={24} color="#FD7E14" />, label: "Energy Efficiency", value: `${impactEntity?.energyEfficiency?.totalEnergyEfficiency}%` },
    { icon: <FactoryIcon style={{color:"#6610F2"}}  size={24} color="#6610F2" />, label: "Ethical Production", value: `${impactEntity?.ethicalProduction?.totalEthicalProduction}%` },
    { icon: <FaBiohazard  size={24} color="#DC3545" />, label: "Chemical Safety", value: `${impactEntity?.chemicalSafety?.totalChemicalSafety}%` },
    { icon: <FaTruck size={24} color="#6C757D" />, label: "Transportation Impact", value: `${impactEntity?.transportationImpact?.totalTranspoEmissions} kg CO2` },
    { icon: <FaCheckCircle size={24} color="#28A745" />, label: "Certifications", value: `${impactEntity?.certifications?.totalCertificationScore}%` },
    { icon: <FaClock size={24} color="#6F42C1" />, label: "Durability", value: `${impactEntity?.durability?.totalDurabilityScore}%` },
  ];



const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "6px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};

const scrollTable = {
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};
// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};

const qrRef = useRef();
const downloadQRCode = () => {
  if (qrRef.current) {
    htmlToImage.toPng(qrRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qrcode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Failed to download QR code:', error);
      });
  }
};





// download pdf 
const cardRef = useRef(); // Add a reference for the card to download as PDF
const downloadPDF = () => {  
  const input = cardRef.current;  

  // Ensure the image is fully loaded before capturing the canvas  
  const images = input.querySelectorAll('img');  
  const imagePromises = Array.from(images).map((img) => {  
    return new Promise((resolve) => {  
      if (img.complete) {  
        resolve();  
      } else {  
        img.onload = resolve;  
        img.onerror = resolve;  
      }  
    });  
  });  

  Promise.all(imagePromises).then(() => {  
    // After all images are loaded, render the canvas and generate the PDF  
    html2canvas(input, {  
      useCORS: true, // Enable CORS to allow cross-origin images  
      allowTaint: true, // Prevents tainted canvas issues (fingers crossed)  
    })  
      .then((canvas) => {  
        const imgData = canvas.toDataURL('image/png');  
        const pdf = new jsPDF();  
        const imgProps = pdf.getImageProperties(imgData);  
        const pdfWidth = pdf.internal.pageSize.getWidth();  
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);  
        pdf.save('retailer-details.pdf');  
      })  
      .catch((error) => {  
        console.error('Failed to generate PDF:', error);  
      });  
  });  
};

  return (
    <>
       <div style={containerWrapperStyle}>

<Container maxWidth="lg"   className="scrollable-container " style={scrollableContainerStyle}>
  <Grid container spacing={2}>
    <Grid item xs={12} md={8}  ref={cardRef} >
      {/* Metrics Section */}
      <Card className="p-4" style={{maxHeight:"505px",overflowY:"scroll"}}>
        <Grid container spacing={4} justifyContent="center">
          {metrics.map((metric, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={6} className="flex flex-col mt-2 items-center">
              <div className="bg-gray-200 rounded-full p-4 flex items-center justify-center mb-2">
                {metric.icon}
              </div>
              <div className="flex flex-col px-4">
                <Typography variant="body1" className="text-center font-medium mb-1">
                  {metric.label}
                </Typography>
                <Typography variant="body1"   className="text-center text-3xl font-bold text-green-600" 
  style={{ fontFamily: 'Poppins, Inter, sans-serif', letterSpacing: '0.5px' }}>
                  {metric.value}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Card>
      </Grid>
        <Grid item xs={12} md={4}>
        <Card className=" mb-1">
        <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={10}>
 
            <Typography variant="body1" >Edit Passport</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={() => setModal(true)} aria-label="edit">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" marginTop={2}>
          <Grid item xs={10}>
            <Typography variant="body1">Download PDF</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={downloadPDF} aria-label="download">
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
          </Card>
          <Card className="p-14 mt-4">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value={`https://onescan.digital/productDetails/${productData?.productId}`} style={{height:"100px"}}/>
            </div>
            <div className="flex justify-center ">
            <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px] text-nowrap"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 235,
               height: 35,
             }}
             onClick={downloadQRCode}
            >Download QRCode Button</Button>
            
            </div>
            <div className="flex justify-center ">
            <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            
            </div>
          </Card>
        </Grid>
      </Grid>
   
      </Container>
</div>

<UpdateImpactModal fetchSupplierChainData={fetchSupplierChainData} impactEntity={impactEntity} open={modal} onHide={() => setModal(false)} />
    </>
  );
};

export default ImpactCard;



     {/* Questions Section */}
      {/* <div className="mt-3">
        <Typography variant="h6" component="div" className="mb-4">
          A sustainable product is:
        </Typography>
        <Grid container spacing={2}>
          {questions.map((question, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <FormGroup className="flex flex-col">
                <Typography variant="body1" className="mb-2 text-nowrap">
                  • {question}
                </Typography>
                <div className="flex space-x-2 items-center">
                  <Typography variant="body1">Yes</Typography>
                  <IconButton aria-label="upload file" component="label">
                    <input hidden type="file" />
                    <CloudUpload className="text-gray-500" />
                  </IconButton>
                  <IconButton onClick={() => toggleModal(index)} aria-label="hide visibility">
                    <VisibilityOff className="text-gray-500" />
                  </IconButton>
                </div>
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </div> */}

      {/* Modal */}
      {/* <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader className="fw-bold" toggle={toggleModal}>
          {selectedQuestion !== null ? `Question ${selectedQuestion + 1}` : 'Modal Title'}
        </ModalHeader>
        <ModalBody>
          <div className="flex justify-center">
            <img src="/icon3.png" alt="label" className="w-14 h-14" />
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-center">
          <Button
            color="primary"
            style={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '12',
              background: '#2D059C',
              borderRadius: '20px',
              padding: '10px',
              width: '200px',
            }}
            onClick={toggleModal}
          >
            OK
          </Button>
          <Button
            style={{
              borderRadius: '20px',
              padding: '10px',
              width: '200px',
            }}
            variant="outlined"
            color="secondary"
            className="mx-2"
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}