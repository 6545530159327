import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
  // const { authToken } = useAuth(); // Assuming you have authToken in your AuthContext
  const token = Cookies.get('authToken') ;

  if (!token) {
    // If there's no authToken, redirect to the login page
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
