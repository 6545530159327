import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import QRCode from 'react-qr-code'; 

import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { Tabs, Tab } from 'react-bootstrap';

import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Chart from './Productpages/Chart';
import ImpactCard from './Productpages/ImpactCard';
import * as htmlToImage from 'html-to-image';

const scrollableContainerStyle = {
  height: "80vh",  // Takes 80% of the viewport height
  maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};
const scrollTable = {
  overflowY: "auto",
  fontFamily: "Inter",
  padding: "16px", // Optional: Add some padding if needed
  boxSizing: "border-box", // Ensure padding is included in height calculation
};
// In case you are using flexbox for your layout
const containerWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh", // Full height of the viewport
};

  const sdgs = [
    { number: 3, label: 'Good Health and Well-being', color: '#4c9f38', icon: '/icon3.png' },
    { number: 8, label: 'Decent Work and Economic Growth', color: '#a21942', icon: '/icon8.png' },
    { number: 9, label: 'Industry, Innovation, and Infrastructure', color: '#fd6925', icon: '/icon9.png' },
    { number: 11, label: 'Sustainable Cities and Communities', color: '#fd9d24', icon: '/icon11.png' },
    { number: 12, label: 'Responsible Consumption and Production', color: '#bf8b2e', icon: '/icon12.png' },
    { number: 13, label: 'Climate Action', color: '#3f7e44', icon: '/icon13.png' },
    { number: 14, label: 'Life Below Water', color: '#0a97d9', icon: '/icon14.png' },
    { number: 16, label: 'Peace and Justice Strong Institutions', color: '#00689d', icon: '/icon16.png' },
  ];
const Product = ({productData}) => {
  const qrRef = useRef();
  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };
    const [key, setKey] = useState('biogenics');

  
  return (
    <div style={containerWrapperStyle}>

    <Container maxWidth="lg"   className="scrollable-container " style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card className="p-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img src={productData?.image} alt="Wooden Table"  className="w-full" />
              </Grid>
              <Grid item xs={12} sm={8}>
                <CardContent>
                <Grid container spacing={2}>
                  <Typography variant="h5" className="fw-bold">{productData?.name}</Typography>
            <Button  className="h-[35px] w-[125px] mx-3 "
                    style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: '#4F46E5',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#4F46E5' },
                      width: 125,
                      height: 35,
                    }}>End product</Button>
            </Grid >
                  <Typography variant="body1" className="mb-4 mt-4">
                  {productData?.description}
                  </Typography>
          
                </CardContent>
              </Grid>
            </Grid>
            <Grid container spacing={1} className='mt-1'>
                    <Grid item xs={4}><Typography variant="body2">Defined use period (years)</Typography></Grid>
                    <Grid item xs={4}><Typography variant="body2">Dominant material group (s)</Typography></Grid>
                    <Grid item xs={4}><Typography variant="body2">Next use application</Typography></Grid>

                    <Grid className='mt-2' item xs={4}><Typography variant="body1">15</Typography></Grid>
                    <Grid className='mt-2' item xs={4}><Typography variant="body1">{productData?.materials}</Typography></Grid>
                    <Grid className='mt-2' item xs={4}><Typography variant="body1">Wooden table</Typography></Grid>

                    <Grid className='mt-4' item xs={3}><Typography variant="body2">End of use Treatment</Typography></Grid>
                    <Grid className='mt-4' item xs={3}><Typography variant="body2">Weight</Typography></Grid>
                    <Grid className='mt-4' item xs={3}><Typography variant="body2">Quantity</Typography></Grid>
                    <Grid className='mt-4' item xs={3}><Typography variant="body2">MSDS</Typography></Grid>

                    <Grid className='mt-2' item xs={3}><Typography variant="body1">Recycling</Typography></Grid>
                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.weight} {productData?.basicInfo?.weightUnit}</Typography></Grid>
                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.quantity} Items</Typography></Grid>
                    <Grid className='mt-2' item xs={3}><Typography variant="body1">{productData?.basicInfo?.msds}</Typography></Grid>
           
                  </Grid>
          </Card>
          <Card className="mt-3 p-2">
          <Grid container spacing={1}>
          <Grid className='mt-2' item xs={1.5}>
            <div style={{borderRadius:"100%", width:"65px", border:"2px"}} className="font-bold  border flex justify-center p-3">
            <Typography variant="h6" >{productData?.progressBar}%</Typography>
            </div>

            </Grid>
          <Grid className='mt-2' item xs={7.5}>
            <Typography variant="body1" className="font-bold mb-4">
                This passport is on the right track. Fulfill extra information to increase your passport score.</Typography>
           
            </Grid>
             <Grid className='' item xs={3}>
          <Grid container spacing={1}>

             <Typography variant="body1" className="mt-3">
             extra information (5/6)
                </Typography>
           
        
            <Button  className="h-[40px] w-[125px] mt-2"
                    style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: 'black',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: 'black' },
                      width: 125,
                      height: 40,
                    }}>Complete Step</Button>
            </Grid>
            </Grid>

            </Grid>
          </Card>
        
        </Grid>
        <Grid item xs={12} md={4}>
        <Card className=" mb-1">
        <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="body1">Edit Passport</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" marginTop={2}>
          <Grid item xs={10}>
            <Typography variant="body1">Download PDF</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="download">
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
          </Card>
          <Card className="p-14 mt-4">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value={`https://onescan.digital/productDetails/${productData?.productId}`} style={{height:"100px"}}/>
            </div>
            <div className="flex justify-center ">
            <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px]"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 235,
               height: 35,
             }}
             onClick={downloadQRCode}
            >Download QRCode Button</Button>
            
            </div>
            <div className="flex justify-center ">
            <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            
            </div>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={4} className="mt-2 " style={{marginBottom:"100px"}}>
            <Grid item xs={3}>
              <Card className="p-4">
              <div className="grid grid-cols-2   rounded">
      {sdgs.map(sdg => (
        <Button className='MainCard'>
       <img src={sdg.icon} alt="labl" className="w-14 h-14 " /></Button>
      ))}
    </div>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Card className="p-4">
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-4">
                  <Tab eventKey="biogenics" title="Biogenics">
              <Chart/>
                  </Tab>
                  <Tab eventKey="impact" title="Impact">
                    <div style={scrollTable} className="w-full h-56">
                 <ImpactCard/>
                    </div>
                  </Tab>
              
                </Tabs>
              </Card>
            </Grid>
          </Grid>
        
           
    </Container>

    </div>
  );
};

export default Product;
