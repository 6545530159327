import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Button, IconButton, Box, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';
import Rawmaterialmodal from './Rawmaterialmodal';
import Swal from 'sweetalert2';
const AllRawmaterial = ({tab}) => {

    const {GetRawMaterila,rawMaterielList}= useAuth();
    const [openmodal, setOpenmodal] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    console.log(rawMaterielList)
    useEffect(() => {
      if(tab == 'Raw_material'){
        GetRawMaterila();
      }
    }, [tab])

    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              });
              if (result.isConfirmed) {
            await DataService.deleteRawMaterialImpact(id);
            GetRawMaterila();
              }
        } catch (error) {
            console.error('Failed to delete Raw Material', error);
        }
    };
    
   
    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Brand Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <IconButton aria-label="edit"
                        onClick={() => { setSelectedRow(row); setOpenmodal(true); }}
                    >
                        <Edit style={{ color: 'green' }} />
                    </IconButton>
                    <IconButton aria-label="delete"
                        onClick={() => handleDelete(row.id)}
                    >
                        <Delete style={{ color: '#C81D20' }} />
                    </IconButton>
                </>
            ),
        },
    ];
  return (
    <>
    <div className="px-4">
                <Box
                    className="mb-2"
                    p={2}
                    borderRadius={2}
                    border="1px solid #e0e0e0"
                    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
                    width="100%"
                    height="fit-content"
                    bgcolor="white"
                >
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl">Raw Material Impact</h1>
                        <Button variant="contained" color="primary" 
                        onClick={() =>{setSelectedRow(null); setOpenmodal(true)}}
                        >
                            Create
                        </Button>
                    </div>
                </Box>
                <div className="bg-white p-6 shadow rounded-lg">
                    {/* <h2 className="text-xl mb-2">Row Material Directory</h2> */}
                    {/* <div className="flex mb-2">
                        <TextField label="Search or browse Row Material"
                            variant="outlined" fullWidth />
                    </div> */}
                    <div style={{ height: "200px", overflow: "auto" }}>
                        <DataTable
                            columns={columns}
                            data={rawMaterielList}
                            pagination
                            responsive
                            striped
                            highlightOnHover
                            pointerOnHover
                            noHeader
                        />
                    </div>
                </div>
            </div>
            <Rawmaterialmodal getAll={GetRawMaterila} isOpen={openmodal} toggle={() => setOpenmodal(!openmodal)} selectedRaw={selectedRow}/>
    </>
  )
}

export default AllRawmaterial