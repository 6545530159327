// EditProductModal.js
import React from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useAuth } from '../../contexts/AuthConext';

const EditProductModal = ({ modalOpen, setModalOpen, formValues, handleInputChange, handleSaveClick ,categoryList}) => {

  const { brands,wumList ,PLM,MSDS,FUPS,Productuselist,
    fetchBrands,fetchgetUomList,fetchgetPlmList,fetchMSDSList,fetchFUPSList,GetProductUserPeriod,
    fetchCategoryList,   unitMessure,
    fetchgetUnitOfmessureList
} = useAuth(); 
// Handle dropdown open event for UOM list
const handleUomDropdownOpen = () => {
  fetchgetUomList();
};
const handleUnitmessureDropdownOpen = () => {
  fetchgetUnitOfmessureList();
};
  return (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
      <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Edit Product</ModalHeader>
      <ModalBody>
        <TextField
          name="name"
          label="Product Name"
          value={formValues.name || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="description"
          label="Product Description"
          value={formValues.description || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        {/* <TextField
          name="category"
          label="Product Category"
          value={formValues.category || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        /> */}
       
   
       <FormControl fullWidth margin="normal">
  <InputLabel id="category-label">Product Category</InputLabel>
  <Select
    labelId="category-label"
    name="category"
    label="Product Category"
    value={formValues.category || ''}
    onChange={handleInputChange}
  >
    <MenuItem value="all">All Category</MenuItem>
    {categoryList.map((category, index) => (
         <MenuItem key={category.id} value={category.categoryName}>
         {category.categoryName}
       </MenuItem>
    ))}
  </Select>
</FormControl>
   

        <TextField
          name="sku"
          label="SKU"
          value={formValues.sku || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="brand"
          label="Brand"
          value={formValues.brand || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="manufacturingDate"
          label="Manufacturing Date"
          type="date"
          value={formValues.manufacturingDate || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name="expirationDate"
          label="Expiration Date"
          type="date"
          value={formValues.expirationDate || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* Add other fields as needed */}
        <TextField
          name="basicInfo.weight"
          label="Weight"
          value={formValues.basicInfo?.weight || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="basicInfo.weightUnit"
          label="Weight Unit"
          value={formValues.basicInfo?.weightUnit || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          select
          SelectProps={{ native: true }}
          onFocus={handleUomDropdownOpen} 
          >
          <option value=""></option>
          {wumList?.map((category) => (
<option key={category.id} value={category.id}>
{category.name}
</option>
))}
        </TextField>

        <TextField
          name="basicInfo.quantity"
          label="Quantity"
          value={formValues.basicInfo?.quantity || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="basicInfo.quantityUnit"
          label="Quantity Unit"
          value={formValues.basicInfo?.quantityUnit || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          select
                      SelectProps={{ native: true }}
                      onFocus={handleUnitmessureDropdownOpen} 

                    >
                      <option value=""></option>
                      {unitMessure?.map((category) => (
        <option key={category.id} value={category.measure}>
          {category.measure}
        </option>
          ))}

        </TextField>

        <TextField
          name="basicInfo.length"
          label="Length"
          value={formValues.basicInfo?.length || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="basicInfo.width"
          label="Width"
          value={formValues.basicInfo?.width || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="basicInfo.height"
          label="Height"
          value={formValues.basicInfo?.height || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        {/* Add other basicInfo fields if needed */}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveClick}>Save</Button>
        <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditProductModal;
