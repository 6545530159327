import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi';
import { useAuth } from '../../../contexts/AuthConext';

const CreateBrandModal = ({ isOpen, toggle, fetchBrands }) => {
    const [brandName, setBrandName] = useState('');
    const {saasId
    } = useAuth(); 

    const handleCreateBrand = async () => {
        try {
            const data = {saasId:saasId, name: brandName };
            await DataService.createBrand(data); // Call the API to create a new brand
            Swal.fire('Success', 'Brand Created Successfully', 'success');
            fetchBrands(); // Refresh the brand list after creation
            toggle(); // Close the modal
        } catch (error) {
            const errorMessage = error.response?.data?.message || ' Please try again later.';
    
            // Show error message in Swal
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: errorMessage,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="fw-bold">Create Brand</ModalHeader>
            <ModalBody>
                <TextField
                    label="Enter Brand Name"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    variant="outlined"
                    fullWidth
                />
            </ModalBody>
            <ModalFooter>
                <Button   style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
            style={{
                textTransform: 'none',
                color: '#fff',
                fontSize: '12px',
                background: '#2D059C',
                border: 'none',
                borderRadius: '20px',
                '&:hover': { background: '#2D059C' },
                width: 225,
                height: 45,
              }}
                    color="primary"
                    onClick={handleCreateBrand}
                >
                    Create Brand
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateBrandModal;
