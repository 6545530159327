import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Select,
  MenuItem,
  InputBase,
  Grid,
  Badge,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Menu as MenuIcon, Logout as LogoutIcon } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../contexts/AuthConext';

const Header = ({ toggleSidebar }) => {
  const { logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  

  return (
    <AppBar position="static" className='' style={{ backgroundColor: "#F6F8F9" }}>
      <Toolbar className="flex justify-between">
        <Grid md={5} container alignItems="center" spacing={2}>
          <Grid className="text-gray-500" item>
            <IconButton onClick={toggleSidebar} className="focus:outline-none" edge="start" color="inherit" aria-label="menu">
              <MenuIcon className='' />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography className="text-black" variant="h6">
            OneScan
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid md={5} style={{ height: "50px" }} container alignItems="center">
          <Grid item xs={4}>
            <Select
              variant="outlined"
              defaultValue="All category"
              className="w-full border-none "
              style={{ height: "50px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" }}
            >
              <MenuItem className='' value="All category">All category</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputBase
              placeholder="Search category"
              className="w-full border p-2 "
              style={{ borderLeft: "none", borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}
            />
          </Grid>
        </Grid> */}

        <Grid md={2} container alignItems="center" spacing={2}>
          {/* <Grid item>
            <Typography className="text-black" >EN </Typography>
          </Grid> */}
          <Grid item>
            <IconButton aria-label="notifications">
              <Badge
                color="error">
                <NotificationsIcon onClick={()=>navigate("/NotificationList")} className='' />
              </Badge>
            </IconButton>
          </Grid>
          {/* <Grid item>
            <IconButton
              aria-label="account"
              onClick={handleMenuOpen}
            >
              <AccountCircleIcon className='' />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={()=>navigate("/Profile")}>
                <ListItemText primary="Username" />
              </MenuItem>
              <MenuItem onClick={()=>{logout();
                 navigate("/login");
              }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </Grid> */}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
