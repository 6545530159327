import React, { useEffect, useState } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, Checkbox, FormControl, FormLabel, Box, Typography, Grid, LinearProgress, Select, MenuItem } from '@mui/material';
import {
  InputAdornment,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';
import { useAuth } from '../contexts/AuthConext';
import PassportSummary from './PassportSummary/PassportSummary';

const EndProductStepEith = ({handleNext}) => {
  const {Endprogress,step2progress,step3progress,step4progress, step5progress, savestep6Progress,saasId
  } = useAuth(); 

  const navigate = useNavigate()
  const scrollableContainerStyle = {
    height: "80vh",  // Takes 80% of the viewport height
    maxHeight: "calc(100vh - 100px)", // Calculate the remaining space considering other elements
    overflowY: "auto",
    fontFamily: "Inter",
    padding: "16px", // Optional: Add some padding if needed
    boxSizing: "border-box", // Ensure padding is included in height calculation
  };
  
  // In case you are using flexbox for your layout
  const containerWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
  };
 
       const [products, setProducts] = useState([
         { name: "Product Name", quantity: 5 },
         { name: "Product Name", quantity: 10 },
         { name: "Product Name", quantity: 14 },
       ]);
     
       const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState("");


  const [retailerData, setRetailerData] = useState({
    saasId:saasId,
    retailerId: "",
    name: "",
    address: {
      flatNo: "",
      cityArea: "",
      country: "",
      state: "",
      district: "",
      pincode: "",
    },
    contactInfo: {
      phone: "",
      phone2: "",
      email: "",
      email1: "",
    },
  });

 // Required fields list
 const requiredFields = [
  { key: 'name' },
  { key: 'address.flatNo' },
  { key: 'address.cityArea' },
  { key: 'address.state' },
  { key: 'address.district' },
  { key: 'address.pincode' },
  { key: 'contactInfo.phone' },
  { key: 'contactInfo.phone2' },
  { key: 'contactInfo.email' },
  { key: 'contactInfo.email1' },
];

// Helper function to get nested values
const getNestedValue = (obj, key) => {
  return key.split('.').reduce((o, k) => (o && o[k] !== undefined ? o[k] : ""), obj);
};

// Calculate progress based on filled fields
const calculateProgress = () => {
  const totalFields = requiredFields.length;
  const filledFields = requiredFields.filter(({ key }) => {
    const fieldValue = getNestedValue(retailerData, key);
    return fieldValue !== ""; // Check if the field is not empty
  }).length;

  return (filledFields / totalFields) * 100;
};

const progress = calculateProgress();

useEffect(() => {
  const progress = calculateProgress();
  savestep6Progress(progress); // Save progress in context whenever it changes
}, [retailerData]);




const handleSearchChange = (event) => {  
  const manufacturerName = event.target.value;   
  setSearchTerm(manufacturerName); // Update the search term with the selected name  

  // Find the selected manufacturer from the data array  
  const selectedManufacturer = searchData.find((m) => m.name === manufacturerName);  

  if (selectedManufacturer) {  
    // Update retailer data state with the selected manufacturer's info  
    setRetailerData({  
      retailerId: selectedManufacturer.retailerId,  
      name: selectedManufacturer.name,  
      address: {  
        flatNo: selectedManufacturer.address.flatNo,  
        cityArea: selectedManufacturer.address.cityArea,  
        state: selectedManufacturer.address.state,  
        district: selectedManufacturer.address.district,  
        country: selectedManufacturer.address.country,  
        pincode: selectedManufacturer.address.pincode,  
      },  
      contactInfo: {  
        phone: selectedManufacturer.contactInfo.phone,  
        phone2: selectedManufacturer.contactInfo.phone2,  
        email: selectedManufacturer.contactInfo.email,  
        email1: selectedManufacturer.contactInfo.email1,  
      },  
    });  
  }  
};


const handleInputChange = (e) => {
  const { name, value } = e.target;
  setRetailerData({ ...retailerData, [name]: value });
};


  const fetchRetailerData = async () => {
    try {
      const response = await DataService.getRetailerList(saasId);
      setSearchData(response.data.data);

 
      
    } catch (error) {
      console.error("Error fetching Retailer data:", error);
    }
  };

  
  const handlePostAndNavigate = async () => {
    try {
      const productId = localStorage.getItem('productId');
      const { retailerId } = retailerData;
      // Make the POST API call

      if (!productId || !retailerId) {
        Swal.fire({
          title: 'Error',
          text: 'Product ID or Retailer ID is missing',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const response = await DataService.linkRetailProduct(retailerId, productId);

      // After successful API call, navigate to the /Product route
      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Product linked to Retailer successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          handleNext()
          localStorage.setItem("Reatailr_id",retailerId)
        });
      }
    } catch (error) {
      console.error("API call failed", error);
    }
  };




    // progress bar
const stepsProgress = [
  { name: 'Basic Information', stepNumber: 1, totalSteps: 6, progress: Endprogress },
  { name: 'Materials', stepNumber: 2, totalSteps: 6, progress: step2progress },
  { name: 'Impact', stepNumber: 3, totalSteps: 6, progress: step3progress }, // Example percentage for Materials
  { name: 'UnifiedSupplierManufacturer', stepNumber: 4, totalSteps: 6, progress: step4progress }, // Example percentage for Materials
  { name: 'Retail Entity', stepNumber: 5, totalSteps: 6, progress: progress }, // Example percentage for Materials

];
   return (
    <>
      <div style={containerWrapperStyle}>
     <Grid container spacing={2} className="scrollable-container p-3" style={scrollableContainerStyle}>
         <Grid container alignItems="center" spacing={2} item xs={12} md={7}>
         <Box sx={{ p: 2 }}>
         <Typography style={{ color: "GrayText" }} variant="h6" component="h6" mb={2} gutterBottom>Step 5/6</Typography>
       <Typography className='fw-bold' variant="h6">Retail Entity</Typography>
       <FormLabel component="legend">Retail Entity by filling in basic information.</FormLabel>
       <Box className="p-3 bg-white rounded-lg shadow-md">

       <FormControl fullWidth variant="outlined" className="mb-4">
       <TextField
    label="Search Retailer's name"
    variant="outlined"
    onFocus={fetchRetailerData}

    value={searchTerm}
    onChange={handleSearchChange}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <FaSearch />
        </InputAdornment>
      ),
    }}
    select
  >

        {searchData && searchData.map((manufacturer) => (
          <MenuItem key={manufacturer?.id} value={manufacturer?.name  } >

{manufacturer?.name  } 
               </MenuItem>
  
        ))}

      </TextField>
      </FormControl>

       <Typography variant="h6" className="mb-3">
       Retail ID  (  Unique identifier for each supplier )
       </Typography>
       <TextField
         fullWidth
         label="Retail ID  (  Unique identifier for each supplier )"
         variant="outlined"
         className="mb-4"
         value={retailerData.retailerId}
         onChange={handleInputChange}

         disabled
       />
     
      
       <Grid container spacing={2}>
         <Grid item xs={6}>
           <TextField disabled fullWidth label="Address"
           value={retailerData?.address?.flatNo} onChange={(e) => 
            setRetailerData({ ...retailerData, address: { ...retailerData.address, flatNo: e.target.value } })} 

           variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.address?.cityArea} onChange={(e) => 
                        setRetailerData({ ...retailerData, address: { ...retailerData?.address, cityArea: e.target.value } })} 
           fullWidth label="Area, Street, Sector, Village" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.address?.district} onChange={(e) => 
                        setRetailerData({ ...retailerData, address: { ...retailerData?.address, district: e.target.value } })} 
           fullWidth label="District" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData.address.state} onChange={(e) => 
                        setRetailerData({ ...retailerData, address: { ...retailerData?.address, state: e.target.value } })} 
           fullWidth label="State" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.address.country} onChange={(e) => 
                        setRetailerData({ ...retailerData, address: { ...retailerData?.address, country: e.target.value } })} 
           fullWidth label="Country" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.address.pincode} onChange={(e) => 
                        setRetailerData({ ...retailerData, address: { ...retailerData?.address, pincode: e.target.value } })} 
           fullWidth label="Pincode" variant="outlined" className="mb-4" />
         </Grid>
       </Grid>
       <Typography variant="h6" className="mb-3">
       Contact Info ( Contact details for the retailer )
       </Typography>
       <Grid container spacing={2}>
         <Grid item xs={6}>
           <TextField  disabled
           value={retailerData?.contactInfo?.phone} onChange={(e) => setRetailerData({ ...retailerData, contactInfo: { ...retailerData?.contactInfo, phone: e.target.value } })}
           fullWidth label="Mobile No. (Primary)" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.contactInfo?.phone2} onChange={(e) => setRetailerData({ ...retailerData, contactInfo: { ...retailerData?.contactInfo, phone2: e.target.value } })}
           fullWidth label="Mobile No. (Secondary)" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.contactInfo?.email} onChange={(e) => setRetailerData({ ...retailerData, contactInfo: { ...retailerData?.contactInfo, email: e.target.value } })}
           fullWidth label="Email (Primary)" variant="outlined" className="mb-4" />
         </Grid>
         <Grid item xs={6}>
           <TextField disabled
                      value={retailerData?.contactInfo?.email1} onChange={(e) => setRetailerData({ ...retailerData, contactInfo: { ...retailerData?.contactInfo, email1: e.target.value } })}
           fullWidth label="Email (Secondary)" variant="outlined" className="mb-4" />
         </Grid>
       </Grid>

     </Box>

 
     <div className='flex justify-center mt-3 mb-5'>
       <Button   onClick={handlePostAndNavigate}   style={{ background: "#4F46E5",  padding: "15px", width: "400px" }} variant="contained" color="primary" fullWidth>
       Step 7: Logistics and distribution
       </Button>    </div>
           </Box>
         </Grid>
         <Grid style={{ margin: "3px" }} className='mx-3' md={4} spacing={2} >
         <PassportSummary title="Passport Summary" stepsProgress={stepsProgress} />


           </Grid>
  
       </Grid>

       </div>
       </>
   );
 }

export default EndProductStepEith