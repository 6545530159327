import { useState } from "react"
import { Card, CardContent, CardActions, CardHeader, Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Slider from "@mui/material/Slider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { classNames } from 'classnames';

function ImpactCalculator() {
    

  // calculation

  const [productionVolume, setProductionVolume] = useState(1000)
  const [energyUsage, setEnergyUsage] = useState("")
  const [waterUsage, setWaterUsage] = useState("")
  const [rawMaterials, setRawMaterials] = useState("")
  const [transportDistance, setTransportDistance] = useState("")
  const [recycledContent, setRecycledContent] = useState(0)
  const [results, setResults] = useState(null)
  const [error, setError] = useState("")
  const [tabValue, setTabValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  const calculateImpact = () => {
    setError("")

    // Input validation
    if (!energyUsage || !waterUsage || !rawMaterials || !transportDistance) {
      setError("Please fill in all fields.")
      return
    }

    const energy = parseFloat(energyUsage)
    const water = parseFloat(waterUsage)
    const materials = parseFloat(rawMaterials)
    const transport = parseFloat(transportDistance)

    if (isNaN(energy) || isNaN(water) || isNaN(materials) || isNaN(transport)) {
      setError("Please enter valid numbers for all fields.")
      return
    }

    // Calculate impacts per unit
    const carbonDioxide = (energy * 0.5 + transport * 0.1) / productionVolume
    const energyImpact = energy / productionVolume
    const waterImpact = water / productionVolume
    const materialImpact = materials * (1 - recycledContent / 100) / productionVolume

    setResults({
      carbonDioxide: carbonDioxide.toFixed(2),
      energyImpact: energyImpact.toFixed(2),
      waterImpact: waterImpact.toFixed(2),
      materialImpact: materialImpact.toFixed(2)
    })
  }
  return (
    <Box classNames='mt-2' >
    <Card   p={2}
    borderRadius={2}
    border="1px solid #e0e0e0"
    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
    width="100%"
    height="fit-content"
    bgcolor="white">
      <CardHeader
        title="Product Sustainability Impact Calculator"
        subheader="Calculate the environmental impact per unit of your product"
      />
      <CardContent>
        <Tabs value={tabValue} onChange={handleChangeTab} variant="fullWidth">
          <Tab label="Inputs" />
          <Tab label="Results" />
        </Tabs>
        {tabValue === 0 && (
          <Box sx={{ mt: 3 }}>
            <Box sx={{ mb: 2 }}>
              <Typography>Production Volume (units)</Typography>
              <TextField
                type="number"
                fullWidth
                value={productionVolume}
                onChange={(e) => setProductionVolume(parseInt(e.target.value) || 0)}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>Total Energy Usage (kWh)</Typography>
              <TextField
                fullWidth
                placeholder="e.g., 10000"
                value={energyUsage}
                onChange={(e) => setEnergyUsage(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>Total Water Usage (L)</Typography>
              <TextField
                fullWidth
                placeholder="e.g., 5000"
                value={waterUsage}
                onChange={(e) => setWaterUsage(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>Total Raw Materials (kg)</Typography>
              <TextField
                fullWidth
                placeholder="e.g., 1000"
                value={rawMaterials}
                onChange={(e) => setRawMaterials(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>Transport Distance (km)</Typography>
              <TextField
                fullWidth
                placeholder="e.g., 500"
                value={transportDistance}
                onChange={(e) => setTransportDistance(e.target.value)}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography>Recycled Content (%)</Typography>
              <Slider
                value={recycledContent}
                onChange={(e, newValue) => setRecycledContent(newValue)}
                min={0}
                max={100}
                step={1}
                valueLabelDisplay="auto"
              />
              <Typography align="right">{recycledContent}%</Typography>
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 3 }}>
            {results ? (
              <Box>
                <Typography variant="h6">Carbon Dioxide: {results.carbonDioxide} kg per unit</Typography>
                <Typography variant="h6">Energy Impact: {results.energyImpact} kWh per unit</Typography>
                <Typography variant="h6">Water Usage: {results.waterImpact} L per unit</Typography>
                <Typography variant="h6">Raw Material: {results.materialImpact} kg per unit</Typography>
              </Box>
            ) : (
              <Typography align="center">Enter data and calculate to see results</Typography>
            )}
          </Box>
        )}
      </CardContent>
      <CardActions>
        <Button variant="contained" fullWidth onClick={calculateImpact}>
          Calculate Impact
        </Button>
      </CardActions>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </Card>
  </Box>
  )
}

export default ImpactCalculator