import React from 'react';
import { Avatar, Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Home, Settings, ExitToApp, Inventory2, Factory, LocalShipping, PersonAdd } from '@mui/icons-material'; // Add relevant icons here
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth } from '../contexts/AuthConext';
import profile from './../assets/Profilepg.png';
import { BASEURL } from '../services/http-common';
import StorageIcon from '@mui/icons-material/Storage';
const Sidebar = ({ className, handleReset }) => {
  const { logout,authData } = useAuth();
  const { name, email, mobilephone, lastName, gender, country,id } = authData || {};

  const navigate = useNavigate();
  const handleResethome = () => {
    handleReset();
    navigate("/");
  };

  return (
    <Box className={`flex flex-col p-4 [background:#383a3a] text-white ${className}`} style={{ height: "100%" }}>
      <div>
        <div className="flex items-center mb-8" >
        <Avatar className='cursor-pointer' onClick={()=>navigate("/Profile")} src={`${BASEURL.ENDPOINT_URL}auth/get-profile-image/${id}`} alt="User Photo" sx={{ width: 56, height: 56, marginRight: 2 }} />
        <div>
        <Typography variant="h6" component="div">
                  {name || 'User Name'}
                </Typography>
                {/* <Typography variant="body2" color="text-white">
                  {email || 'user@example.com'}
                </Typography> */}
        </div>
        </div>
        <nav>
          <List>
            <ListItem button onClick={() => handleResethome()}>
              <ListItemIcon>
                <Home className='text-white' />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={() => navigate("/Product")}>
              <ListItemIcon>
                <Inventory2 className='text-white' /> {/* Product icon */}
              </ListItemIcon>
              <ListItemText primary="Product" />
            </ListItem>
            <ListItem button onClick={() => navigate("/AddManufacture")}>
              <ListItemIcon>
                <Factory className='text-white' /> {/* Product icon */}
              </ListItemIcon>
              <ListItemText primary="Manufacturer" />
            </ListItem>
        
            <ListItem button onClick={() => navigate("/AddSupplier")}>
              <ListItemIcon>
                <LocalShipping className='text-white' /> {/* Product icon */}
              </ListItemIcon>
              <ListItemText primary="Supplier" />
            </ListItem>
  
            <ListItem button onClick={() => navigate("/AddRetailer")}>
              <ListItemIcon>
                <PersonAdd className='text-white' /> {/* Product icon */}
              </ListItemIcon>
              <ListItemText primary="Retailer" />
            </ListItem>
         

            <ListItem button onClick={() => navigate("/AllUser")}>
              <ListItemIcon>
              <PersonIcon className='text-white' />{/* Product icon */}
              </ListItemIcon>
              <ListItemText primary="All User" />
            </ListItem>



            <ListItem button onClick={() => navigate("/Datamanagement")}>
              <ListItemIcon>
                <StorageIcon  className='text-white' /> {/* Product icon */}
              </ListItemIcon>
              <ListItemText primary="Master Data " />
            </ListItem>
     
         
          </List>
        </nav>
      </div>
      <div className="mt-auto" style={{ marginBottom: "200px" }}>
        <nav>
          <List>
            <ListItem button>
              <ListItemIcon>
                <Settings className='text-white' />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button  className='cursor-pointer' onClick={()=>{logout();
                 navigate("/login");
              }}>
              <ListItemIcon>
                <ExitToApp className='text-white' />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </nav>
      </div>
    </Box>
  );
};

export default Sidebar;
