import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, Avatar, Container } from '@mui/material';
import NavigationTabs from './NavigationTabs';
import { BASEURL } from '../services/http-common';
import Header from './Header';
import { useParams } from 'react-router-dom';
import ProductNavbar from './ProductNavbar';
function AllProductInfo() {
    const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [productData, setProductData] = useState(null);
  const [manufacturerInfo, setManufacturerInfo] = useState(null);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [retailerInfo, setRetailerInfo] = useState([]);
  const [transportDetails, setTransportDetails] = useState(null);
  const [warehouseDetails, setWarehouseDetails] = useState(null); 
  const [materials, setMaterials] = useState([]); 
  useEffect(() => {
    // API call to fetch product details by productId (hardcoded as '1' for now)
    axios
      .get(`${BASEURL.ENDPOINT_URL}supplierchain/getproductdetails-by-productId/${productId}`)
      .then((response) => {
        const data = response.data.data;
        setProductData(data.product);
        setManufacturerInfo(data.manufacturer);
        setSupplierInfo(data.supplier);
        setRetailerInfo(data.retailer);
        setTransportDetails(data.transportDetails);
        setWarehouseDetails(data.warehouseDetails);
        setMaterials(data.product.materials);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
    <ProductNavbar />

    <Container maxWidth="sm" sx={{ py: 2 ,  pt: 4,    height: 'calc(100vh - 64px)', // Adjust based on navbar height
          overflowY: 'auto',}}>

      {/* Header */}
      <Header      productData={productData} />

      <Paper 
        elevation={3} 
        sx={{
          py: 3,
          px: 2,
          mt: 2,
          borderRadius: 2,
          boxShadow: '0px -4px 34px rgba(23, 20, 57, 0.04)',
          backgroundColor: 'background.paper',
          overflow: 'hidden',
        }}
      >
        {/* Header section */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
          <Box sx={{ width: 34, height: 6, bgcolor: 'primary.main', borderRadius: '30px' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
            <Typography variant="h6" fontWeight="bold">
              {productData?.name || 'Loading...'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {manufacturerInfo?.name || 'Loading...'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'purple.100', p: 0.5, borderRadius: 1 }}>
          <Avatar 
              src={productData?.image || 'default-image-url'} 
              alt="Product Image" 
              sx={{ width: 15, height: 15, mr: 0.5 }} 
            />
         
          </Box>
        </Box>

        {/* Navigation Tabs */}
        <NavigationTabs 
             productData={productData}
             manufacturerInfo={manufacturerInfo}
             supplierInfo={supplierInfo}
             retailerInfo={retailerInfo}
             transportDetails={transportDetails}
             warehouseDetails={warehouseDetails}
             materials={materials}
        />
      </Paper>
    </Container>
    </>
  );
}

export default AllProductInfo;
