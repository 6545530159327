import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import DataService from "../services/requestApi";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [brands, setBrands] = useState([]);
  const [wumList, setWumList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchedCountries, setSearchedCountries] = useState([]);
  const [H2Olist , setH2OList] = useState([]);
  const [rawMaterielList, setRawMaterialList] = useState([]);
  const [Productuselist, setProductUseList] = useState([]);

  const [permission, setPermission] = useState([]);
  const isAuthenticated = Cookies.get("authToken");
  const [isLoding, setIsLoading] = useState(true);
  console.log("isAuthenticated", isAuthenticated);
  const [role, setRole] = useState();
 
  
  const Getpermission = async () => {
    try {
      const response = await DataService.me();
      if(response.data.status){
       setPermission(response.data.data.permission);
      setRole(response.data.data.userRole)
      setIsLoading(false);
      console.log('fetch permission', response.data.data.permission);
    }else{
      logout()
    }

    } catch (error) {
      setPermission(null);
      setIsLoading(false);
      logout()
     

      console.error('Failed to fetch permission', error);
    }
  };



  useEffect(() => {
    if(isAuthenticated){
      Getpermission(); // This will populate the permissions

    }else{
      setPermission(false)
      setIsLoading(false);

    }
}, [isAuthenticated]);







  const [authData, setAuthData] = useState(() => {
    const storedAuthData = JSON.parse(localStorage.getItem("authData"));
    if (storedAuthData) {
      return storedAuthData;
    } else {
      return { token: null, user: null };
    }
  });
  const { saasId } = authData;
  


 
  const saveUserData = (userData) => {
    setAuthData(userData);
    localStorage.setItem("authData", JSON.stringify(userData));
  };
  const login = (token) => {
    // setAuthData(data);
    // localStorage.setItem("authData", JSON.stringify(data));
    Cookies.set("authToken", token, { expires: 7 });
  };

  const logout = () => {
    console.log("Logged Out");
    localStorage.clear();
    Cookies.remove("authToken");
  };



  const [energyimpact , setenergyimpact] = useState([]);

  const fetchgetsetenergyimpactList = async () => {
    try {
      const response = await DataService.getenergyimpactList(saasId);
      setenergyimpact(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };


  const [Citrate , setCitrate] = useState([]);

  const fetchCitrateList = async () => {
    try {
      const response = await DataService.getCitrateList(saasId);
      setCitrate(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };


  const [MSDS , setMSDS] = useState([]);

  const fetchMSDSList = async () => {
    try {
      const response = await DataService.getMSDSList(saasId);
      setMSDS(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };


  // AllFUPS
  const [FUPS , setFUPS] = useState([]);

  const fetchFUPSList = async () => {
    try {
      const response = await DataService.getFUPSList(saasId);
      setFUPS(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };


    // WarhouseOwnner
    const [WarhouseOwnner , setWarhouseOwnner] = useState([]);

    const fetchWarhouseOwnnerList = async () => {
      try {
        const response = await DataService.getWarhouseOwnnerList(saasId);
        setWarhouseOwnner(response.data.data);
      } catch (error) {
        console.error('Failed to fetch Brand', error);
      }
    };


  // AllWarehouseName
  const [WarehouseName , setWarehouseName] = useState([]);

  const fetchsetWarehouseNameList = async () => {
    try {
      const response = await DataService.getWarhouseNameList(saasId);
      setWarehouseName(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };

    // Function to fetch brands from API and store them in state
    const fetchBrands = async () => {
      try {
        const response = await DataService.getBrandList(saasId);
        setBrands(response.data.data);
      } catch (error) {
        console.error('Failed to fetch brands', error);
      }
    };



    const fetchgetUomList = async () => {
      try {
        const response = await DataService.getWumList(saasId);
        setWumList(response.data.data);
      } catch (error) {
        console.error('Failed to fetch Brand', error);
      }
    };

    
  // Function to fetch all countries
  const fetchCountries = async () => {
    try {
      const response = await DataService.getCountries(); // Replace with your API call
      setCountries(response.data.data);
    } catch (error) {
      console.error("Failed to fetch countries", error);
    }
  };

  // Function to search countries by keyword
  const searchCountries = async (keyword) => {
    try {
      const response = await DataService.searchCountriesByKeyword(keyword); // Replace with your API call
      setSearchedCountries(response.data.data);
    } catch (error) {
      console.error("Failed to search countries", error);
    }
  };


  const [PLM, setPLM] = useState([]);

  const fetchgetPlmList = async () => {
    try {
      const response = await DataService.getPLMList(saasId);
      setPLM(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };

  const [Sroi , setSroi] = useState([]);

  const fetchgetSroiList = async () => {
    try {
      const response = await DataService.getSroiList(saasId);
      setSroi(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };

  const getH2OList = async () => {
  try {
    const response = await DataService.getH2OList(saasId);
    setH2OList(response.data.data);
  } catch (error) {
    console.log(error);
  }
  }
   
  const GetRawMaterila = async () => {
    try {
      const response = await DataService.getRawMaterialList(saasId);
      setRawMaterialList(response.data.data);
    } catch (error) {
      console.log(error)
    }
  }

  const GetProductUserPeriod = async () => {
    try {
      const response = await DataService.getProductUserPeriodList(saasId);
      console.log(response.data.data); // Replace with your API call to fetch product user period data. This data will be stored in state.  // In this example, I am just logging the response data. You will need to replace this with your actual API call.  // Also, you will need to handle any errors that may occur during this API call.  // For example, you can use try-catch blocks to catch any errors and display them to the user.  // This is just an example, and you may need to modify it based on your specific requirements and API.  // You can also store the fetched product user period data in state and use it in your components.  // For example, you can use it in your ProductForm component to populate the user period dropdown.  // Also, you can use it in your ProductTable component to display the user period column.  // You
      setProductUseList(response.data.data);
    } catch (error) {
     console.log("Failed to fetch Product User Period", error);   
    }
  }

  //  trnsport route
  const [transport, setTransport] = useState([]);
  const getAllTransport = async () => {
    try {
      const response = await DataService.GetTransportRoute(saasId);
      setTransport(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };


  //fetchgetserTransportmethodList

  const [Transportmethod, serTransportmethod] = useState([]);

  const fetchgetserTransportmethodList = async () => {
    try {
      const response = await DataService.GetTransportmethod(saasId);
      serTransportmethod(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };

  // category
  const [categoryList, setCategoryList] = useState([]);

  const fetchCategoryList = async () => {
    try {
      const response = await DataService.getCategoryList(saasId);
      if (response.data.status) {
        setCategoryList(response.data.data); // Storing the 'data' array in the state
      } else {
        console.error('Failed to fetch category list:', response.data.message);
      }
    } catch (error) {
      console.error('Failed to fetch category list:', error);
    }
  };



  // unit of messure
  const [unitMessure, setunitMessure] = useState([]);

  const fetchgetUnitOfmessureList = async () => {
    try {
      const response = await DataService.getUomList(saasId);
      setunitMessure(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };


  // Dominant
  const [dominant, setDominant] = useState([]);

  const fetchDominantList = async () => {
    try {
      const response = await DataService.getdominantList(saasId);
      setDominant(response.data.data);
    } catch (error) {
      console.error('Failed to fetch Brand', error);
    }
  };



  // step 1 progress manage
  const [Endprogress, setEndprogress] = useState(0);

  // Function to save progress in EndProductStepOne
  const saveProgress = (progressValue) => {
    setEndprogress(progressValue);
  };

  // Function to retrieve progress in EndProductStepThree
  const getProgress = () => Endprogress;

  // step 2  progress bar
  const [step2progress, setstep2progress] = useState(0);

  // Function to save progress in EndProductStepOne
  const savestep2Progress = (progressValue) => {
    setstep2progress(progressValue);
  };


  //step 3 progress bar
  const [step3progress, setstep3progress] = useState(0);

  // Function to save progress in EndProductStepOne
  const savestep3Progress = (progressValue) => {
    setstep3progress(progressValue);
  };

    //step 4 progress bar
    const [step4progress, setstep4progress] = useState(0);

    // Function to save progress in EndProductStepOne
    const savestep4Progress = (progressValue) => {
      setstep4progress(progressValue);
    };

    //step 5 progress bar
    const [step5progress, setstep5progress] = useState(0);

    // Function to save progress in EndProductStepOne
    const savestep5Progress = (progressValue) => {
      setstep5progress(progressValue);
    };

        //step 6 progress bar
        const [step6progress, setstep6progress] = useState(0);

        // Function to save progress in EndProductStepOne
        const savestep6Progress = (progressValue) => {
          setstep6progress(progressValue);
        };

           //step 7 progress bar
           const [step7progress, setstep7progress] = useState(0);

           // Function to save progress in EndProductStepOne
           const savestep7Progress = (progressValue) => {
             setstep7progress(progressValue);
           };
    useEffect(() => {
      if (isAuthenticated) {
        fetchBrands(); // Fetch brands when the user is authenticated
        fetchgetUomList()
        fetchCountries(); 
    
      }
    }, [isAuthenticated]);


  // Function to calculate total progress
  const calculateTotalProgress = () => {
    const totalSteps = 7; // Total number of steps
    const stepsProgress = [
      Endprogress,
      step2progress,
      step3progress,
      step4progress,
      step5progress,
      step6progress,
      step7progress,
    ];
    const totalProgress = stepsProgress.reduce((acc, curr) => acc + curr, 0);
    return totalProgress / totalSteps; // Average progress
  };

  // State for total progress
  const [totalProgress, setTotalProgress] = useState(0);

  // Update total progress when any step progress changes
  useEffect(() => {
    setTotalProgress(calculateTotalProgress());
  }, [Endprogress, step2progress, step3progress, step4progress, step5progress, step6progress, step7progress]);

  
  return (
    <AuthContext.Provider
      value={{
        authData,
        saasId,
        isAuthenticated,
            // Total progress
            totalProgress,
        saveUserData,
        login,
        logout,
        brands, // Expose brands state
        fetchBrands, // Expose fetchBrands function
        dominant, 
        fetchDominantList,
        Transportmethod,
        fetchgetserTransportmethodList,

        WarhouseOwnner,
        fetchWarhouseOwnnerList,

        WarehouseName,
        fetchsetWarehouseNameList,

        wumList,

        unitMessure,
        fetchgetUnitOfmessureList,

        fetchgetUomList,
        countries, // Expose countries
        searchedCountries, // Expose searched countries
        fetchCountries, // Expose fetch countries function
        searchCountries, // Expose search countries function

        PLM,
        fetchgetPlmList,

        Sroi,
        fetchgetSroiList,

        energyimpact,
        fetchgetsetenergyimpactList,

        Citrate,
        fetchCitrateList,

        MSDS,
        fetchMSDSList,
        
        FUPS,
        fetchFUPSList,
        H2Olist, // Expose H2OList state
        getH2OList,
        rawMaterielList,
        GetRawMaterila,
        Productuselist,
        GetProductUserPeriod, // Expose GetProductUserPeriod function

        transport,
        getAllTransport,

        categoryList,
        fetchCategoryList,

        //step1 progres 
        saveProgress,
        getProgress,
        Endprogress,

        //step2 progres 
        step2progress,
        savestep2Progress,
            //step2 progres 
            step3progress,
            savestep3Progress,

            //step4
            step4progress,
            savestep4Progress,
            // step5
            step5progress,
             savestep5Progress,
                 // step6
            step6progress,
            savestep6Progress,
                // step6
                step7progress,
                savestep7Progress,


                // permission state
                permission,
                Getpermission,
                isLoding,
                role
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
