// ChangePasswordModal.js
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';
import DataService from '../../services/requestApi';

const ChangePasswordModal = ({ isOpen, toggle }) => {
  const [mobile, setMobile] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        title: 'Error!',
        text: 'Passwords do not match.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    try {
      const response = await DataService.changePassword({
        mobile,
        newPassword,
      });

      if (response.data.status) {
        Swal.fire({
          title: 'Success!',
          text: 'Password changed successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        toggle(); // Close modal on success
      } else {
        Swal.fire({
          title: 'Error!',
          text: response.data.message || 'Failed to change password.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while changing the password.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className='fw-bold' toggle={toggle}>Change Password</ModalHeader>
      <ModalBody>
        <TextField
          label="Mobile Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <TextField
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button   style={{
                      borderRadius: '20px',
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="outlined"
                    color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button    style={{
                    textTransform: 'none',
                    color: '#fff',
                    fontSize: '12',
                    background: '#2D059C',
                    border: 'none',
                    borderRadius: '20px',
                    '&:hover': { background: '#2D059C' },
                    width: 225,
                    height: 45,
                  }} onClick={handleChangePassword}>
          Change Password
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePasswordModal;
