import { Button, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BsArrowLeft } from 'react-icons/bs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import DataService from '../../../services/requestApi'
const UpdateBrandModal = ({onHide,open,id, getBrand,selectedUom}) => {
    const [brandName, setBrandName] = React.useState('');


    useEffect(() => {
      if (selectedUom) {
        setBrandName(selectedUom);
      } else {
        setBrandName('');
      }
    }, [selectedUom]);


    const updateBrand=async ()=>{
        try{
            const data ={
                 "name": brandName
            }
            await DataService.updateBrand(id,data);
            Swal.fire('Brand Updated Successfully');
            await getBrand(); // To get updated data after update operation.
            onHide();
        }catch(error){
            Swal.fire('Error', error.message, 'error');
        }
    }

  return (
    <div>
     <Modal isOpen={open} toggle={onHide}>
      <ModalHeader toggle={onHide} className="fw-bold">Update Brand</ModalHeader>
      <ModalBody>
        <TextField
          label="Enter Brand Name"
          value={brandName}
          onChange={(e)=>{setBrandName(e.target.value)}}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={onHide}
        //   disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={updateBrand}
        //   disabled={loading}
        >
            Update Brand
          {/* {loading ? 'Adding...' : 'Add Category'} */}
        </Button>
      </ModalFooter>
    </Modal>
</div>
  )
}

export default UpdateBrandModal