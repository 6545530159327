import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';
import Brand from './BrandMaster/Brand';
import Uom from './UomMaster/Uom';
import WeightUnitMaster from './WeightUnitMaster/WeightUnitMaster';
import PLM from './PLM/PLM';
import Alltransport from '../Supplychainmater/Transportroute/Alltransport';
import AllSROI from '../ImpactData/SROI/AllSROI';
import AllCo2 from '../ImpactData/CO2/AllCo2';
import AllRawmaterial from '../ImpactData/Rawmaterial/AllRawmaterial';
import Allenergyimpact from '../ImpactData/EnergyImpact/Allenergyimpact';
import AllH2OImpact from '../ImpactData/H2OImpact/AllH2OImpact';
import AllMsds from '../Supplychainmater/MSDS/AllMsds';
import AllFUPS from '../ImpactData/FUPS/AllFUPS';
import AllProductUsePeriod from '../Supplychainmater/ProductPeriod/AllProductUsePeriod';
import AllWarhouseOwner from './Warhouse/AllWarhouseOwner';
import AllWarehouseName from './WarehouseName/AllWarehouseName';
import GetTransPortMethod from '../Supplychainmater/TransPortmethod/GetTransPortMethod';
import AllDominant from '../Supplychainmater/Dominant/AllDominant';
import { useAuth } from '../../contexts/AuthConext';



const Datamanagement = () => {
  const { getAllTransport ,fetchBrands,fetchgetSroiList} = useAuth();


  const [currentPage, setCurrentPage] = useState('1');
  const [Activetab, setActivetab] = useState('');

  // Handle page change for pagination
  const handlePageChange = (page) => {
    console.log("this pagination page", page);
    setCurrentPage(page);
  };

  return (
    <>
      <div className='d-flex justify-content-between'>
        {/* <Link to={'/'}> <IoArrowBack className='mt-2 fs-3'/> </Link>  */}
      </div>
      <Tabs
        defaultActiveKey="Master_Data"
        id="uncontrolled-tab-example"
        className="mb-3 fw-bold"
        fill
        style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
        onSelect={(key) => {
          setActivetab(key);
          // Trigger the API call only when this specific tab is selected
          if (key === "Product_Lifecycle_Data") {
            getAllTransport();
          }
          if (key === "Master_Data") {
            fetchBrands();
          }
          if (key === "Impact_Management") {
            fetchgetSroiList();
          }
        }}
      >
        <Tab eventKey="Master_Data" title="Master Data">
          <Tabs
            defaultActiveKey="Brand"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key);
              // Handle tab changes within Product Management
            }}
          >
            <Tab eventKey="Brand" title="Brand">
              <Brand tab={Activetab}/>
            </Tab>
            <Tab eventKey="WeightUnitMaster" title="Unit of Measure">
                <WeightUnitMaster tab={Activetab} />
            </Tab>
            <Tab eventKey="Unit_Of_Measure" title="Quantity Unit">
            <Uom tab={Activetab} />

            </Tab>
            <Tab eventKey="PLM" title="PLM">
            <PLM tab={Activetab} />
               </Tab>
               <Tab eventKey="WarehouseOwner" title="Warehouse Owner">
            <AllWarhouseOwner tab={Activetab} />
               </Tab>

               <Tab eventKey="WarehouseName" title="Warehouse Name">
            <AllWarehouseName tab={Activetab}/>
               </Tab>

          </Tabs>
        </Tab>
        <Tab eventKey="Impact_Management" title="Sustainability Impact Data">
        <Tabs
            defaultActiveKey="SROI"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key)
              // Handle tab changes within Product Management
            }}
          >
            <Tab eventKey="SROI" title="SROI Impact">
           <AllSROI tab={Activetab}/>
            </Tab>
            <Tab eventKey="CO2" title="CO₂ Impact">
            <AllCo2 tab={Activetab}/>
            </Tab>
            <Tab eventKey="Raw_material" title="Raw Material Impact">
            <AllRawmaterial tab={Activetab}/>
            </Tab>
            <Tab eventKey="Enery_impact" title="Energy Impact">
            <Allenergyimpact tab={Activetab}/>
            </Tab>
            <Tab eventKey="H20" title="H₂O Impact">
             <AllH2OImpact tab={Activetab}/>
            </Tab>
            <Tab  eventKey="FUPS" title="FUO">
             <AllFUPS tab={Activetab}/>
            </Tab>
          </Tabs>
        </Tab>
        <Tab eventKey="Product_Lifecycle_Data" title="Product Lifecycle Data"
        >
        <Tabs
            defaultActiveKey="TransportRoute"
            id="uncontrolled-tab-example"
            className="mb-3 fw-bold"
            fill
            style={{background: '#FFF', boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"}}
            onSelect={(key) => {
              setActivetab(key);
              // Trigger the API call only when this specific tab is selected
       
            }}
          >
            <Tab eventKey="TransportRoute" title="Transport Route">
             <Alltransport tab={Activetab} />
            </Tab>
            <Tab eventKey="TransportMethod" title="Transport Method">
              <GetTransPortMethod tab={Activetab} />

            </Tab>
            <Tab eventKey="AllMsds" title="MSDS">
              <AllMsds tab={Activetab}/>
            </Tab>
            <Tab eventKey="Product_use" title="Product Usage Period">
              <AllProductUsePeriod tab={Activetab} />
            </Tab>

            <Tab eventKey="Dominant" title="Dominant">
              <AllDominant tab={Activetab} />
            </Tab>
          </Tabs>
        </Tab>
 

        
      </Tabs>
    </>
  )
}

export default Datamanagement;
