import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DataService from '../services/requestApi';
import { useAuth } from '../contexts/AuthConext';
import Swal from 'sweetalert2';
import ChangePasswordModal from '../components/Modal/ChangePasswordModal';
const Login = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const [error, setError] = useState('');
  const { login, saveUserData  } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmit = async (data) => {
    try {
      const response = await DataService.Login(data);
      const redirectUrl = sessionStorage.getItem("redirectAfterLogin");

      if (response.data.status) {
        const token = response.data.data.token;
        const userData = {
          id: response.data.data.id,
          saasId: response.data.data.saasId,
          name: response.data.data.name,
          mobilephone: response.data.data.mobilephone,
          email: response.data.data.email,
          // token: response.data.data.token,
        };
        if (token) {
          login(token);
          saveUserData(userData);
          navigate("/");
          window.location.reload();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: "User not found. Email or Password incorrect",
            confirmButtonColor: '#2D059C',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: response.data.message || "Invalid login credentials",
          confirmButtonColor: '#2D059C',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Error',
        text: "User not found. Phone or Password incorrect",
        confirmButtonColor: '#2D059C',
      });
    }
  };

  return (
    <>
    <Box className="flex flex-col md:flex-row h-screen bg-background">
      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
          style={{
            backgroundImage: 'url(/regiterleft.png)', // Update with your image path
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        </Grid>
        <Grid className='p-5 mt-5 flex justify-center h-[550px]' item xs={12} md={5}>
          <Box className="max-w-md w-full bg-card p-8 rounded-lg shadow-lg border border-accent">
            <div className='flex justify-center mb-1'>
              <Typography variant="h4" className="font-bold text-black">Log in</Typography>
            </div>
            <div className='flex justify-center mb-2'>
              <Typography component="label" variant="body1" className="block text-sm font-medium text-zinc-700" htmlFor="full-name">
                To access your account
              </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
              <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="phone">
              Email
              </Typography>
              <TextField
                {...register('email', { required: true })}
                type="email"
                id="email"
                placeholder="example@email.com"
                variant="outlined"
                fullWidth
                required
                error={!!errors.email}
                helperText={errors.email ? 'Email is required' : ''}
              />
              <Typography component="label" variant="body2" className="block text-sm font-medium text-zinc-700" htmlFor="password">
                Password
              </Typography>
              <TextField
                {...register('password', { required: true })}
                type="password"
                id="password"
                placeholder="qwel123@"
                variant="outlined"
                fullWidth
                required
                error={!!errors.password}
                helperText={errors.password ? 'Password is required' : ''}
              />
              <p onClick={toggleModal} className="text-primary cursor-pointer text-sm mt-2 block text-right hover:underline">I forget my password</p>
              <div className='flex justify-center mt-3'>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="text-primary-foreground hover:bg-primary/80 w-full py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                  style={{
                    textTransform: 'none',
                    color: '#fff',
                    fontSize: '12',
                    background: '#2D059C',
                    border: 'none',
                    borderRadius: '20px',
                    '&:hover': { background: '#2D059C' },
                    width: 225,
                    height: 45,
                  }}
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </div>
            </form>
            {/* <Typography variant="body2" className="mt-4 text-muted-foreground text-center">
              Don’t have an account? 
              <a href="/register" className="text-accent hover:underline">Create an account</a>
            </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </Box>

    <ChangePasswordModal  isOpen={isModalOpen} toggle={toggleModal}  />
    </>
  );
};

export default Login;
