import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Paper, Avatar, Container } from '@mui/material';
import NavigationTabs from './NavigationTabs';
import { BASEURL } from '../services/http-common';
import Header from './Header';
import { useParams } from 'react-router-dom';
import ProductNavbar from './ProductNavbar';


import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material' // Importing MUI components




const ExpandableSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex w-full justify-between items-center text-left"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="font-semibold">{title}</span>
        {isExpanded ? <AiOutlineUp className="h-5 w-5" /> : <AiOutlineDown className="h-5 w-5" />}
      </button>
      {isExpanded && <div className="mt-2">{children}</div>}
    </div>
  )
}

const SustainabilityMetric = ({ icon, label, value }) => (
  <div className="flex flex-col items-center">
    <span className="text-2xl mb-1">{icon}</span>
    <span className="text-sm text-gray-600">{label}</span>
    <span className="font-semibold">{value}</span>
  </div>
)






function AllProductInfo() {
    const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [productData, setProductData] = useState(null);
  const [manufacturerInfo, setManufacturerInfo] = useState(null);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [retailerInfo, setRetailerInfo] = useState([]);
  const [transportDetails, setTransportDetails] = useState(null);
  const [warehouseDetails, setWarehouseDetails] = useState(null); 
  const [materials, setMaterials] = useState([]); 
  const [impactEntity, setImpactEntity] = useState({});

  useEffect(() => {
    // API call to fetch product details by productId (hardcoded as '1' for now)
    axios
      .get(`${BASEURL.ENDPOINT_URL}supplierchain/getproductdetails-by-productId/${productId}`)
      .then((response) => {
        const data = response.data.data;
        setProductData(data.product);
        setManufacturerInfo(data.unifiedProductSupplierManufacturer);
        setSupplierInfo(data.supplier);
        setRetailerInfo(data.retailer);
        setTransportDetails(data.transportDetails);
        setWarehouseDetails(data.warehouseDetails);
        setMaterials(data.product.materials);
        setImpactEntity(data.impactEntity)
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
    <ProductNavbar />

    <Container maxWidth="sm" sx={{ py: 2 ,  pt: 4,    height: 'calc(100vh - 64px)', // Adjust based on navbar height
          overflowY: 'auto',}}>

      {/* Header */}
      {/* <Header      productData={productData} /> */}

      {/* <Paper 
        elevation={3} 
        sx={{
          py: 3,
          px: 2,
          mt: 2,
          borderRadius: 2,
          boxShadow: '0px -4px 34px rgba(23, 20, 57, 0.04)',
          backgroundColor: 'background.paper',
          overflow: 'hidden',
        }}
      >

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
          <Box sx={{ width: 34, height: 6, bgcolor: 'primary.main', borderRadius: '30px' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
            <Typography variant="h6" fontWeight="bold">
              {productData?.name || 'Loading...'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {manufacturerInfo?.name || 'Loading...'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'purple.100', p: 0.5, borderRadius: 1 }}>
          <Avatar 
              src={productData?.image || 'default-image-url'} 
              alt="Product Image" 
              sx={{ width: 15, height: 15, mr: 0.5 }} 
            />
         
          </Box>
        </Box>


        <NavigationTabs 
             productData={productData}
             manufacturerInfo={manufacturerInfo}
             supplierInfo={supplierInfo}
             retailerInfo={retailerInfo}
             transportDetails={transportDetails}
             warehouseDetails={warehouseDetails}
             materials={materials}
        />
      </Paper> */}


<div className="max-w-3xl mx-auto mb-24">
      <img
      src={productData?.image || 'default-image-url'} 
        alt="Wooden desk with three drawers"
        className=" object-cover mb-4 rounded-lg"
      />

      <Card>
        <CardHeader
          title={<Typography variant="h5">{productData?.name || 'N/A'}</Typography>} // Using MUI's Typography for the title
        />
        <CardContent>
          <ExpandableSection title="PRODUCT DETAILS">
            <ul className="list-disc pl-5">
              <li>Dimensions: 150cm (length) x {productData?.name || 'N/A'}cm (width) x {productData?.basicInfo?.width || 'N/A'}cm (height)

              {productData?.basicInfo?.height || 'N/A'}
              </li>
              <li>Weight: {productData?.basicInfo?.weight || 'N/A'} kg</li>
            </ul>
          </ExpandableSection>

          <ExpandableSection title="COLLECTION">
            <p>Part of the {productData?.brand || 'N/A'}  Collection featuring minimalist design.</p>
          </ExpandableSection>

          <ExpandableSection title="DESCRIPTION">
            <p>{productData?.description || 'N/A'}</p>
          </ExpandableSection>

          <ExpandableSection title="RAW MATERIALS">
            <p>FSC-certified solid oak, non-toxic adhesives, and water-based varnish.</p>
          </ExpandableSection>
{/* 
          <ExpandableSection title="MANUFACTURING">
            <p>Information about manufacturing process would go here {manufacturerInfo?.supplierLocation || 'N/A'}.</p>
          </ExpandableSection> */}

          <ExpandableSection title="SUSTAINABILITY">
  <div className="grid grid-cols-2 gap-4 mt-2">
    <SustainabilityMetric icon="🌿" label="Carbon Footprint" value={ ` ${impactEntity?.carbonFootprint?.totalCarbonFootprint}kg CO2e`} />
    <SustainabilityMetric icon="💧" label="Water Usage" value={`${impactEntity?.waterUsage?.totalWaterUsage} L` } />
    <SustainabilityMetric icon="♻️" label="Recyclability" value={`${impactEntity?.recyclability?.totalRecyclability}%`} />
    <SustainabilityMetric icon="📦" label="Sustainable Materials" value={`${impactEntity?.sustainableMaterials?.totalSustainable}%`}/>
    <SustainabilityMetric icon="⚡" label="Energy Efficiency" value={`${impactEntity?.energyEfficiency?.totalEnergyEfficiency}%`} />
    <SustainabilityMetric icon="🎯" label="Ethical Production" value={`${impactEntity?.ethicalProduction?.totalEthicalProduction}%`}/>
    <SustainabilityMetric icon="⚠️" label="Chemical Safety" value={`${impactEntity?.chemicalSafety?.totalChemicalSafety}%`} />
    <SustainabilityMetric icon="🚚" label="Transportation Impact" value={ `${impactEntity?.transportationImpact?.totalTranspoEmissions} kg CO2`} />
    <SustainabilityMetric icon="✅" label="Certifications" value={`${impactEntity?.certifications?.totalCertificationScore}%`}/>
    <SustainabilityMetric icon="⏳" label="Durability" value={ `${impactEntity?.durability?.totalDurabilityScore}%`} />
  </div>
</ExpandableSection>


          <ExpandableSection title="WARRANTY">
            <p>{manufacturerInfo?.warranty || 'N/A'}-year warranty on materials and workmanship.</p>
          </ExpandableSection>

          <ExpandableSection title="TRACEABILITY">
            <p> {productData?.name || 'N/A'} origin traceability available on {manufacturerInfo?.supplierLocation || 'N/A'}.</p>
          </ExpandableSection>
        </CardContent>
      </Card>

      {/* <div className="mt-4">
        <Button className="w-full" variant="contained">Add to Cart</Button>
      </div> */}
    </div>
    </Container>
    </>
  );
}

export default AllProductInfo;
