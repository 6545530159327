import React, { useState } from 'react';
import { Card, Grid } from '@mui/material';
import { Typography, Button, FormGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import { CloudUpload, VisibilityOff } from '@mui/icons-material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TreeIcon from '@mui/icons-material/Nature'; // Use appropriate icons
import EnergyIcon from '@mui/icons-material/FlashOn'; // Use appropriate icons
import WaterIcon from '@mui/icons-material/Opacity'; // Use appropriate icons
const questions = [
  "Uses less energy.",
  "Lasts longer.",
  "Can be easily repaired.",
  "Parts can be easily disassembled and put to further use.",
  "Can be easily recycled.",
  "Contains more recycled content.",
  "Has a lower carbon and environmental footprint over its lifecycle.",
];
const ImpactCard = () => {

  const [modal, setModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const toggleModal = (index) => {
    setSelectedQuestion(index);
    setModal(!modal);
  };
  return (
  <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={4} className="text-center">
          <Typography variant="body1" className="font-bold">Co2 impact</Typography>
          <TreeIcon style={{ fontSize: 50, margin: '10px 0' }} />
          <Typography variant="h6">12.69 kilogram</Typography>
        </Grid>
        <Grid item xs={4} className="text-center">
          <Typography variant="body1" className="font-bold">Energy impact</Typography>
          <EnergyIcon style={{ fontSize: 50, margin: '10px 0' }} />
          <Typography variant="h6">59.22 KWH</Typography>
        </Grid>
        <Grid item xs={4} className="text-center">
          <Typography variant="body1" className="font-bold">h2o impact</Typography>
          <WaterIcon style={{ fontSize: 50, margin: '10px 0' }} />
          <Typography variant="h6">901.94 Litre</Typography>
        </Grid>
    
      </Grid>
      <div className='mt-3'>
      <Typography variant="h6" component="div" className="mb-4">
        A sustainable product is:
      </Typography>
      <div className="grid grid-cols-2 gap-4">
        {questions.map((question, index) => (
          <FormGroup key={index} className="flex flex-col">
            <Typography variant="body1" className="mb-2 text-nowrap">
              • {question}
            </Typography>
            <div className="flex space-x-2">
            <Typography variant="body1" className="mt-2">
            Yes
            </Typography>
         
              <IconButton aria-label="upload file" component="label">
                <input hidden type="file" />
                <CloudUpload className="text-gray-500" />
              </IconButton>
              <IconButton    onClick={() => toggleModal(index)} aria-label="hide visibility">
                <VisibilityOff className="text-gray-500" />
              </IconButton>
            </div>
          </FormGroup>
        ))}
      </div>
    </div>

       {/* Modal */}
       <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader className='fw-bold' toggle={toggleModal}>
          {selectedQuestion !== null ? `Question ${selectedQuestion + 1}` : 'Modal Title'}
        </ModalHeader>
        <ModalBody>
          {/* {selectedQuestion !== null ? questions[selectedQuestion] : 'No question selected.'} */}
          <div className='flex justify-center'>
          <img src="/icon3.png" alt="labl" className="w-14 h-14 " />
          </div>
        </ModalBody>
        <ModalFooter className='flex justify-center'>
          <Button color="primary"  style={{
                      textTransform: 'none',
                      color: '#fff',
                      fontSize: '12',
                      background: '#2D059C',
                      border: 'none',
                      borderRadius: '20px',
                      '&:hover': { background: '#2D059C' },
                      background: "#4F46E5",
                      padding: "10px",
                      width: "200px"
                    }} onClick={toggleModal}>OK</Button>
          <Button   style={{
                      borderRadius: '20px',
                      padding: "10px",
                      width: "200px"
                    }}
                    variant="outlined"
                    color="secondary" className='mx-2' onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      </>

  );
};

export default ImpactCard;
