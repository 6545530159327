import React, { useRef, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import QRCode from 'react-qr-code'; 
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';


import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

import * as htmlToImage from 'html-to-image';


const scrollableContainerStyle = {
    maxHeight: '350px',
    overflowY: 'auto',
    fontFamily: "Inter"
  };



const RetailEntity = ({retailerData}) => {
  const qrRef = useRef();
  const downloadQRCode = () => {
    if (qrRef.current) {
      htmlToImage.toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qrcode.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Failed to download QR code:', error);
        });
    }
  };

  const [products, setProducts] = useState([
    { name: "Product Name", quantity: 5 },
    { name: "Product Name", quantity: 10 },
    { name: "Product Name", quantity: 14 },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleEditProduct = (index) => {
    // Implement your edit logic here.
    console.log("Edit product at index:", index);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <Container maxWidth="lg"   className="scrollable-container " style={scrollableContainerStyle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
        <Card className="p-10">
      
          <Grid container spacing={2}>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
              Retailer ID
              </Typography>
              <Typography variant="body1">
               {retailerData?.retailerId}
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
              Retailer's name
              </Typography>
              <Typography variant="body1">
              {retailerData?.name}
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography className='p-2' variant="body2" color="textSecondary">
              Retailer mobile no.
              </Typography>
              <Typography className='p-2' variant="body1">
                (+91) {retailerData?.contactInfo?.phone} (Primary)
              </Typography>
              <Typography className='p-2' variant="body1">
                (+91) {retailerData?.contactInfo?.phone2} (Secondary)
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12} sm={6}>
              <Typography className='p-2' variant="body2" color="textSecondary">
              Retailer E-mail
              </Typography>
              <Typography className='p-2' variant="body1">
              {retailerData?.contactInfo?.email} (Primary)
              </Typography>
              <Typography className='p-2' variant="body1">
              {retailerData?.contactInfo?.phone2} (Secondary)
              </Typography>
            </Grid>
            <Grid className='p-4' item xs={12}>
              <Typography variant="body2" color="textSecondary">
              Retailer Address
              </Typography>
              <Typography variant="body1">
              {retailerData?.address?.flatNo},{retailerData?.address?.cityArea},{retailerData?.address?.district},{retailerData?.address?.state},{retailerData?.address?.country}
              </Typography>
            </Grid>
          </Grid>
       
      </Card>
        </Grid>
        <Grid item xs={12} md={4}>
        <Card className=" mb-1">
        <CardContent>
        <Grid  container alignItems="center">
          <Grid  item xs={10}>
            <Typography variant="body1">Edit Passport</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="edit">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" marginTop={2}>
          <Grid item xs={10}>
            <Typography variant="body1">Download PDF</Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="download">
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
          </Card>
          <Card className="p-3 mt-2">
            <div className="flex justify-center p-4" ref={qrRef}>
              <QRCode value="https://example.com" style={{height:"100px"}}/>
            </div>
            <div className="flex justify-center ">
            <Button variant="contained" color="primary" 
             className="h-[35px] w-[235px]"
             style={{
               textTransform: 'none',
               color: '#fff',
               fontSize: '12px',
               background: '#ef613d',
               border: 'none',
               borderRadius: '20px',
               '&:hover': { background: '#ef613d' },
               width: 235,
               height: 35,
             }}
             onClick={downloadQRCode}
            >Download QRCode Button</Button>
            
            </div>
            <div className="flex justify-center ">
            <Link className="mt-3 mb-2 text-decoration-auto">Copy public passport link</Link>
            
            </div>
          </Card>
        </Grid>
      </Grid>
      <div className="p-3 mt-3" style={{marginBottom:"50px"}}>
      {/* <Table className='mt-4' size="small" mt={2}>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell align="center">No. of products</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProducts.map((product, index) => (
            <TableRow key={index}>
              <TableCell>{product.name}</TableCell>
              <TableCell className='text-green-500' align="center">{product.quantity}</TableCell>
              <TableCell align="center">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteProduct(index)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEditProduct(index)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
        </div>
    </Container>
  );
};

export default RetailEntity;
