import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Modal, ModalHeader, ModalBody,  } from "reactstrap";
import { TextField } from "@mui/material"; // Import MUI TextField
import DataService from '../services/requestApi';
import Swal from 'sweetalert2';
const UpdateUserModal = ({ isOpen, toggle, selectedUser,GetAllUser }) => {
  const [formData, setFormData] = useState({
    username: "",
    mobilephone: "",
    email: "",
    role:""
  });

  useEffect(() => {
    if (selectedUser) {
      setFormData({
        username: selectedUser.username || '',
        mobilephone: selectedUser.mobilephone || '',
        email: selectedUser.email || '',
          role: selectedUser.role || ''
      });
    }
  }, [selectedUser]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await DataService.updatUserById(selectedUser.id, formData);
      console.log("User updated successfully:", response);
      // Handle success (e.g., close modal, show success message)
      Swal.fire({
        title: "User Updated Successfully",
        icon: "success",
        timer: 2000
      });
      GetAllUser()
      toggle();
    } catch (error) {
        Swal.fire({
            title: "Error Creating User",
            icon: "error",
            text: error.message
          });
      console.error("Error updating user:", error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update User</ModalHeader>
      <ModalBody>
        {/* MUI TextField for Username */}
        <TextField
          label="Username"
          name="username"
          fullWidth
          margin="normal"
          variant="outlined"
          value={formData.username}
          onChange={handleInputChange}
        />
        {/* MUI TextField for Mobile Phone */}
        <TextField
          label="Mobile Phone"
          name="mobilephone"
          fullWidth
          margin="normal"
          variant="outlined"
          value={formData.mobilephone}
          onChange={handleInputChange}
        />
        {/* MUI TextField for Email */}
        <TextField
          label="Email"
          name="email"
          type="email"
          fullWidth
          margin="normal"
          variant="outlined"
          value={formData.email}
          onChange={handleInputChange}
        />
              {/* Role Select Menu */}
              <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select
            label="Role"
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            variant="outlined"
          >
            <MenuItem value="USER">USER</MenuItem>

            {/* <MenuItem value="Administrator">Administrator</MenuItem> */}
            <MenuItem value="Supplier">Supplier</MenuItem>
            <MenuItem value="Retailer">Retailer</MenuItem>
            <MenuItem value="Customer">Customer</MenuItem>
            <MenuItem value="Ops Team">Ops Team</MenuItem>
            <MenuItem value="Ops Manager">Ops Manager</MenuItem>
          </Select>
        </FormControl>
            <Box display="flex" justifyContent="flex-end"  mt={2} >
  <Button color="primary" onClick={handleSubmit} variant="contained" sx={{ mr: 1 }}>
    Update User
  </Button>
  <Button color="secondary" onClick={toggle} variant="outlined">
    Cancel
  </Button>
</Box>
      </ModalBody>
  
    </Modal>
  );
};

export default UpdateUserModal;
