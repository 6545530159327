import React, { useState } from 'react';
import { Switch, FormControlLabel, Box, Typography, Button, Card, CardContent, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PermissionManagement = () => {
  const navigate = useNavigate();
  
  const pages = [
    { name: 'CustomStepper', path: '/CustomStepper' },
    { name: 'EndProductStepThreeSelect', path: '/EndProductStepThreeSelect' },
    { name: 'EndProductStepFour', path: '/EndProductStepFour' },
    { name: 'EndProductStepFive', path: '/EndProductStepFive' },
    { name: 'Product', path: '/Product' },
    { name: 'ProductTabs', path: '/ProductTabs' },
    { name: 'AddSupplier', path: '/AddSupplier' },
    { name: 'AddManufacture', path: '/AddManufacture' },
    { name: 'AddRetailer', path: '/AddRetailer' },
    { name: 'Datamanagement', path: '/Datamanagement' },
    { name: 'Profile', path: '/Profile' },
    { name: 'NotificationList', path: '/NotificationList' }
  ];

  // State to manage switch status for each page
  const [switchStates, setSwitchStates] = useState(
    pages.reduce((acc, page) => ({ ...acc, [page.path]: false }), {})
  );

  const handleSwitchChange = (path) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [path]: !prevState[path]
    }));
  };

  return (
    <Box p={3}>
      <Typography variant="h4" mb={2}>Permission Management</Typography>
      
      {/* Scrollable container */}
      <Box sx={{ maxHeight: '500px', overflowY: 'auto', paddingRight: '10px' }}>
        <Grid container spacing={2}>
          {pages.map((page) => (
            <Grid item xs={12} sm={6} md={4} key={page.path}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{page.name}</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={switchStates[page.path]}
                        onChange={() => handleSwitchChange(page.path)}
                        color="primary"
                      />
                    }
                    label="Allow to Access"
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
          Go Back to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default PermissionManagement;
