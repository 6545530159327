import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, TextField } from '@mui/material';
import Swal from 'sweetalert2'; // Import SweetAlert2
import DataService from '../../services/requestApi';

const AddCategoryModal = ({ isOpen, toggle, fetchCategoryList }) => {
  const [newCategory, setNewCategory] = useState('');


  const handleCategoryInputChange = (e) => {
    setNewCategory(e.target.value);
  };

  const handleCategorySave = async () => {



    try {
      const response = await DataService.addCategory({ categoryName: newCategory });

      if (response.data.status) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Category added successfully!',
        });
        fetchCategoryList();
        setNewCategory('');
        toggle();
      }
    } catch (error) {
      console.error('Failed to add category:', error);
   
    } 
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="fw-bold">Add Category</ModalHeader>
      <ModalBody>
        <TextField
          label="Category Name"
          value={newCategory}
          onChange={handleCategoryInputChange}
          variant="outlined"
          fullWidth
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={{
            borderRadius: '20px',
            padding: '10px',
            width: '200px',
          }}
          variant="outlined"
          color="secondary"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          style={{
            textTransform: 'none',
            color: '#fff',
            fontSize: '12px',
            background: '#2D059C',
            border: 'none',
            borderRadius: '20px',
            '&:hover': { background: '#2D059C' },
            width: 225,
            height: 45,
          }}
          color="primary"
          onClick={handleCategorySave}
        >
      Add Category
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCategoryModal;
